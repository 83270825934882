import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { IoAddCircleOutline } from "react-icons/io5";

import MainLayout from "Component/Layout/MainLayout";
import CustomSearch from "Component/CustomSearch";
import Pagination from "Component/Pagination/Pagination";
import { createSelectionStore } from "Component/ActionMenuWrapper";
import StaffDeskTopView from "./View/StaffDesktopView";
import StaffMobileView from "./View/StaffMobileView";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import SelectedMenu from "./Manage/SelectedMenu";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";
import { useGetStaffQuery } from "Network/academics/staffAcademics";
import ConfirmDelete from "Component/ConfirmDelete";
import useDeleteStaff from "./hooks/useDeleteStaff";
import useStaffActionStore from "./hooks/useStaffActionStore";
import AssignRole from "./Manage/AssignRole";
import AssignClass from "./Manage/AssignClass";
import AssignSubject from "./Manage/AssignSubject";

import { academicFlattenRoutes } from "Routes/academicRoutes";

const Staff = () => {
  const useSelectApplicantStore = createSelectionStore();
  const params = useParams();
  const navigate = useNavigate();

  const { handleDeleteStaff, deleteStaffState } = useDeleteStaff();

  const { page, search, handleSearch, setPage } = usePaginationAndSearch();

  const staffState = useGetStaffQuery({ page, search });

  const {
    openConfirmDelete,
    setOpenConfirmDelete,
    selectedStaff,
    setOpenAssignRoleModal,
    openAssignRoleModal,
    openAssignClassModal,
    setOpenAssignClassModal,
    openAssignSubjectModal,
    setOpenAssignSubjectModal,
  } = useStaffActionStore();

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Staff"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Add Staff"
            handleSubmit={() =>
              navigate(
                `/${params.shortName}/${academicFlattenRoutes.createStaff}`,
              )
            }
          />
        </div>
      </BodyHeaderLayout>
      <div className="flex justify-between sm:flex-col mb-[20px] bg-white p-3">
        <CustomSearch
          className="w-[40%] sm:w-[100%] text-[14px]  "
          placeholderText="Search by name or ID"
          handleChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <StaffDeskTopView
        useSelectApplicantStore={useSelectApplicantStore}
        staffState={staffState}
      />
      <StaffMobileView
        useSelectApplicantStore={useSelectApplicantStore}
        staffState={staffState}
      />

      <div className="flex w-full justify-end mt-2">
        <Pagination
          onPageChange={setPage}
          pageTotal={staffState?.data?.totalPages}
          initialPage={staffState?.data?.page}
        />
      </div>

      {openConfirmDelete && (
        <ConfirmDelete
          openConfirmDelete={openConfirmDelete}
          setOpenConfirmDelete={setOpenConfirmDelete}
          title="Staff"
          deleteName={selectedStaff?.staffNumber}
          handleDelete={handleDeleteStaff}
          isLoading={deleteStaffState.isLoading}
        />
      )}

      {openAssignRoleModal && (
        <AssignRole
          open={openAssignRoleModal}
          setOpen={setOpenAssignRoleModal}
        />
      )}

      {openAssignClassModal && (
        <AssignClass
          open={openAssignClassModal}
          setOpen={setOpenAssignClassModal}
        />
      )}

      {openAssignSubjectModal && (
        <AssignSubject
          open={openAssignSubjectModal}
          setOpen={setOpenAssignSubjectModal}
        />
      )}

      <SelectedMenu useSelectApplicantStore={useSelectApplicantStore} />
    </MainLayout>
  );
};

export default Staff;
