import { useParams } from "react-router";

import MainLayout from "Component/Layout/MainLayout";
import SummaryCard from "./SummaryCard";
import DetailedInfo from "./DetailedInfo";
import BackButton from "Component/BackButton";
import { academicFlattenRoutes } from "Routes/academicRoutes";
import { useGetSingleStaffQuery } from "Network/academics/staffAcademics";

import { useGetUserQuery } from "Network/userManagement/users";

const StaffDetails = () => {
  const params = useParams();

  const staffState = useGetSingleStaffQuery(params.staffId);
  const userState = useGetUserQuery(params.userId);
  return (
    <MainLayout>
      <div className="mt-3">
        <BackButton
          navigateTo={`/${params.shortName}/${academicFlattenRoutes.staff}`}
        />
        <div className="flex gap-14 sm:gap-3 sm:flex-col h-fit mt-3">
          <SummaryCard staffState={staffState} />

          <DetailedInfo staffState={staffState} userState={userState} />
        </div>
      </div>
    </MainLayout>
  );
};

export default StaffDetails;
