import { create } from "zustand";

const initialState = {
  confirmRemoveAll: false,
  confirmRemoveSingle: false,
  openModal: false,
  selectedCurriculum: null,
  selectedStaffSingleSubject: null,
  selectedStaffAllSubjects: null,
  selectedStaffSingleClass: null,
  selectedStaffAllClasses: null,
};

// TODO: Add reset all state

const useAssignmentStore = create((set) => ({
  ...initialState,
  setConfirmRemoveAll: (value) => set({ confirmRemoveAll: value }),
  setConfirmRemoveSingle: (value) => set({ confirmRemoveSingle: value }),

  setOpenModal: (value) => set({ openModal: value }),

  setSelectedCurriculum: (value) => set({ selectedCurriculum: value }),
  setSelectedStaffSingleSubject: (value) =>
    set({ selectedStaffSingleSubject: value }),

  setSelectedStaffAllSubjects: (value) =>
    set({ selectedStaffAllSubjects: value }),

  setSelectedStaffSingleClass: (value) =>
    set({ selectedStaffSingleClass: value }),
  setSelectedStaffAllClasses: (value) =>
    set({ selectedStaffAllClasses: value }),
}));

export default useAssignmentStore;
