import { useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router";

import Header from "../Header/Header";
import Footer from "../Footer";
import Sidenav from "../sidenav";
import ToggleSidebar from "Component/sidenav/component/ToggleSidebar";
import NoFeaturesAvailablePage from "Component/NoFeaturesAvailablePage";
import { LoadingPage } from "./PreLayout";

import useReturnSchool from "../../hooks/Auth/useReturnSchool";
import useSidebarStore from "Component/sidenav/hooks/useSidebarStore";
import useToggleActiveAppStore from "hooks/useToggleActiveAppStore";
import usePermissionStore from "hooks/Auth/usePermissionStore";

import {
  useGetSchoolProfileQuery,
  useGetMyProfileQuery,
} from "Network/userManagement/profile";

import { isFeatureEnabled } from "Utils";

import { schoolFeaturesEnum } from "Utils/constants";

const MainLayout = ({ children, isLoading, title }) => {
  useReturnSchool();

  const schoolState = useGetSchoolProfileQuery();
  const myProfileState = useGetMyProfileQuery();
  const { miniSidebar } = useSidebarStore();
  const { activeApp, setActiveApp } = useToggleActiveAppStore();
  const { setUserPermissions } = usePermissionStore();

  const isLoadingData = schoolState.isLoading || myProfileState.isLoading;

  const enabledFeatures = schoolState?.data?.enabledFeatures || [];
  const isAdmissionEnabled = isFeatureEnabled(
    enabledFeatures,
    schoolFeaturesEnum.ADMISSION,
  );
  const isAcademicsEnabled = isFeatureEnabled(
    enabledFeatures,
    schoolFeaturesEnum.ACADEMICS,
  );

  // TODO: Uncomment this when features is finalised

  // const isBothEnabled = isAdmissionEnabled && isAcademicsEnabled;
  // const isBothDisabled = !isAcademicsEnabled && !isAdmissionEnabled;

  const isBothEnabled = true;
  const isBothDisabled = false;

  useEffect(() => {
    const roles = myProfileState?.data?.roles;
    if (roles?.length) {
      const flattenedPermissions = roles?.flatMap((role) => {
        // Check if this is the org_admin role
        if (role?.name === "org_admin") {
          // For org_admin, include both the role itself and its permissions
          return [role, ...(role?.permissions || [])];
        }
        // For other roles, just include the permissions
        return role?.permissions || [];
      });
      setUserPermissions(flattenedPermissions);
    }
  }, [myProfileState.data, setUserPermissions]);

  useEffect(() => {
    if (isAdmissionEnabled && !isBothEnabled) {
      setActiveApp(true);
    } else if (isAcademicsEnabled && !isBothEnabled) {
      setActiveApp(false);
    }
  }, [isAdmissionEnabled, isAcademicsEnabled, isBothEnabled, setActiveApp]);

  if (isLoadingData) {
    return <LoadingPage />;
  }

  return (
    <div className="flex flex-col h-[100vh] bg-main-bg sm:bg-[#F5F5F5]">
      <Header
        schoolLogo={schoolState.data?.logo}
        userInfo={myProfileState?.data}
        activeApp={activeApp}
        isBothFeatureEnabled={isBothEnabled}
        isBothFeatureDisabled={isBothDisabled}
      />
      <div className="flex flex-grow">
        <Sidenav />
        <div
          className={`sm:bg-[#F5F5F5] pr-[20px] flex flex-col gap-1 ${
            miniSidebar ? "pl-[95px]" : "pl-[265px]"
          } sm:px-3 sm:py-1 pb-5 pt-[80px] sm:pt-[60px] bg-main-bg w-full`}
        >
          {isLoading ? (
            <div className="flex justify-center mt-3">
              <CircularProgress />
            </div>
          ) : (
            <div className="h-full">
              <ToggleSidebar />
              {title && (
                <div className="mt-[20px] bg-white shadow-sm font-medium px-4 py-3">
                  <h2>{title}</h2>
                </div>
              )}
              <HasFeatures
                isAcademicsEnabled={isAcademicsEnabled}
                isAdmissionEnabled={isAdmissionEnabled}
                isBothEnabled={isBothEnabled}
                isBothDisabled={isBothDisabled}
              >
                {children}
              </HasFeatures>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const HasFeatures = ({
  children,
  isAcademicsEnabled,
  isAdmissionEnabled,
  isBothEnabled,
  isBothDisabled,
}) => {
  const { pathname } = useLocation();
  const appName = pathname.split("/")[2];

  if (
    (isAdmissionEnabled &&
      !isBothEnabled &&
      appName !== schoolFeaturesEnum.ADMISSION) ||
    (isAcademicsEnabled &&
      !isBothEnabled &&
      appName !== schoolFeaturesEnum.ACADEMICS) ||
    isBothDisabled
  ) {
    return <NoFeaturesAvailablePage />;
  }

  return <div className="h-full">{children}</div>;
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
};

MainLayout.defaultProps = {
  isLoading: false,
  title: "",
};

HasFeatures.propTypes = {
  children: PropTypes.node.isRequired,
  isAcademicsEnabled: PropTypes.bool.isRequired,
  isAdmissionEnabled: PropTypes.bool.isRequired,
  isBothEnabled: PropTypes.bool.isRequired,
  isBothDisabled: PropTypes.bool.isRequired,
};

export default MainLayout;
