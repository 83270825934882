import Button from "Component/Forms/Button";
import { ActionMenuWrapper } from "Component/ActionMenuWrapper";
import useBulkSelection from "../hooks/useBulkSelection";

const SelectedMenu = ({ useSelectApplicantStore }) => {
  const { selectedItems, resetSelection } = useSelectApplicantStore();

  const {
    handleMoveApplicantsToAcademicsByApplicantIds,
    moveApplicantsToAcademicsState,
  } = useBulkSelection();

  const handleCloseMenu = () => {
    resetSelection();
  };
  return (
    <ActionMenuWrapper
      animate={selectedItems?.length}
      selectedCount={selectedItems?.length}
      handleCloseMenu={
        !moveApplicantsToAcademicsState.isLoading && handleCloseMenu
      }
    >
      <Button
        title="Admit Student"
        variant="outline"
        className="text-sm sm:text-xs sm:rounded-sm rounded-md font-light min-w-[150px]"
        disabled
      />

      <Button
        handleSubmit={() =>
          handleMoveApplicantsToAcademicsByApplicantIds(selectedItems)
        }
        isLoading={moveApplicantsToAcademicsState.isLoading}
        title="Move to Academics"
        variant="outline"
        className="text-sm sm:text-xs sm:rounded-sm rounded-md font-light min-w-[150px]"
      />

      <Button
        title="Approve Payment"
        variant="outline"
        className="text-sm sm:text-xs sm:rounded-sm rounded-md font-light min-w-[150px]"
        disabled
      />
    </ActionMenuWrapper>
  );
};

export default SelectedMenu;
