import PreLayout from "Component/Layout/PreLayout";
import Button from "Component/Forms/Button";
import Input from "Component/Forms/Input";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import useLogin from "hooks/Auth/useLogin";

const Login = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { handleLogin, loading, initialValues, validationSchema } = useLogin();

  const handleSubmit = (values) => {
    handleLogin(values);
  };

  return (
    <PreLayout title="School Login" className="max-w-[30vw] sm:max-w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
            <div className="flex flex-col gap-4 w-full">
              <Input
                withTitle
                title="Email"
                placeholder="Enter email"
                name="email"
              />

              <Input
                withTitle
                title="Password"
                placeholder="Enter password"
                name="password"
                type="password"
              />

              <div className="flex gap-2 items-center text-[14px] text-primary">
                <input type="checkbox" />
                <p>Remember me</p>
              </div>

              <Button
                isLoading={loading}
                handleSubmit={props.handleSubmit}
                title="Login"
                className=""
              />

              <p
                onClick={() => navigate(`/${params.shortName}/forget-password`)}
                className="text-center text-[14px] text-primary font-semibold cursor-pointer"
              >
                forgot password?
              </p>
            </div>
          </div>
        )}
      </Formik>
    </PreLayout>
  );
};

export default Login;
