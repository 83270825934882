import { MdDelete } from "react-icons/md";

import AccordionDetailsCard from "Component/Cards/AccordionDetailsCard";
import Button from "Component/Forms/Button";
import useAssignmentStore from "Screens/AcademicScreens/SubjectsManagement/hooks/useAssignmentStore";

const ClassCard = ({ data }) => {
  const {
    setConfirmRemoveAll,
    setConfirmRemoveSingle,
    setSelectedStaffSingleClass,
    setSelectedStaffAllClasses,
  } = useAssignmentStore();
  return (
    <AccordionDetailsCard
      title={`${data?.firstName} ${data?.middleName} ${data?.lastName}`}
      subTitle={data?.staffNumber}
      countName={"Total Classes"}
      count={data?.classes?.length}
    >
      <div>
        {data?.classes?.length > 0 ? (
          <div
            className=" flex flex-col gap-2 max-h-[300px] overflow-y-auto ml-2"
            style={{ scrollbarWidth: "thin" }}
          >
            {data?.classes?.map((cl, index) => (
              <div key={index} className="flex justify-between items-center ">
                <p>{cl?.name}</p>

                <Button
                  Icon={<MdDelete />}
                  variant="icon"
                  className="text-red-500"
                  handleSubmit={() => {
                    setConfirmRemoveSingle(true);
                    setSelectedStaffSingleClass(cl);
                    setSelectedStaffAllClasses(data);
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-xs">No Classes</div>
        )}

        {data?.classes?.length > 0 && (
          <div className="flex gap-2 w-full justify-end border-t-1 mt-3">
            <Button
              Icon={<MdDelete />}
              variant="icon"
              className="text-red-500"
              handleSubmit={() => {
                setConfirmRemoveAll(true);
                setSelectedStaffAllClasses(data);
              }}
            />
          </div>
        )}
      </div>
    </AccordionDetailsCard>
  );
};

export default ClassCard;
