import { IoEyeOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiSpreadsheet, BiToggleLeft } from "react-icons/bi";
import { PiToggleRightFill } from "react-icons/pi";

import { PopOver, PopOverList } from "Component/PopOver";
import { ActionTriggerIcon } from "Component/Table";
import { useNavigate, useParams } from "react-router-dom";
import { academicFlattenRoutes } from "Routes/academicRoutes";

import useStudentActionStore from "../hooks/useStudentActionStore";

const Actions = ({ data }) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    setOpenConfirmDelete,
    setSelectedStudent,
    setOpenReportModal,
    setOpenUpdateStatusModal,
  } = useStudentActionStore();

  return (
    <PopOver
      className="w-fit text-[12px]"
      header={<ActionTriggerIcon />}
      content={
        <PopOverList
          className="px-2"
          items={[
            {
              name: "View",
              icon: <IoEyeOutline size={16} />,
              onClick: () => {
                navigate(
                  `/${params.shortName}/${academicFlattenRoutes.students}/${data._id}`,
                );
              },
            },

            {
              name: "Report",
              icon: <BiSpreadsheet size={16} />,
              onClick: () => {
                setSelectedStudent(data);
                setOpenReportModal(true);
              },
            },

            {
              name: data?.status === "active" ? "Suspend" : "Activate",
              icon:
                data?.status === "active" ? (
                  <BiToggleLeft size={16} />
                ) : (
                  <PiToggleRightFill size={16} />
                ),
              onClick: () => {
                setSelectedStudent(data);
                setOpenUpdateStatusModal(true);
              },
            },

            {
              name: "Edit",
              icon: <FiEdit size={16} />,
              // onClick: () => {
              //   {
              //   }
              // },
            },

            {
              name: "Delete",
              icon: <RiDeleteBinLine size={16} />,
              onClick: () => {
                setSelectedStudent(data);
                setOpenConfirmDelete(true);
              },
              style: "text-red-600",
            },
          ]}
        />
      }
    />
  );
};

export default Actions;
