import { useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import CreateAssessment from "./CreateAssessment";
import {
  useGetAssessmentsQuery,
  useDeleteAssessmentMutation,
} from "Network/academics/gradeManagement/assessmentComponentAcademics";
import cn from "Utils/cn";
import ConfirmDelete from "Component/ConfirmDelete";

const AssessmentComponents = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  const [deleteAssessment, deleteAssessmentState] =
    useDeleteAssessmentMutation();

  const assessmentState = useGetAssessmentsQuery();

  const handleDelete = () => {
    deleteAssessment(selectedAssessment?._id)
      .unwrap()
      .then((_) => {
        setOpenDeleteModal(false);
        toast.success("Assessment deleted");
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return (
    <MainLayout>
      <BodyHeaderLayout title="Assessment Components">
        <Button
          Icon={IoAddCircleOutline}
          withIcon
          className="w-fit text-sm h-[41px]"
          title="Create Assessment"
          handleSubmit={() => setOpenCreateModal(true)}
        />
      </BodyHeaderLayout>
      {!assessmentState.isFetching ? (
        assessmentState?.data?.length > 0 ? (
          <div className="grid grid-cols-4 sm:grid-cols-1 mt-20 gap-10">
            {assessmentState?.data?.map((assessment, index) => (
              <AssessmentTable
                data={assessment}
                key={index}
                handleDelete={() => {
                  setSelectedAssessment(assessment);
                  setOpenDeleteModal(true);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="flex justify-center mt-20">No Assessments found</div>
        )
      ) : (
        <div className="flex justify-center mt-20">
          <CircularProgress />
        </div>
      )}
      {openCreateModal && (
        <CreateAssessment open={openCreateModal} setOpen={setOpenCreateModal} />
      )}

      {openDeleteModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteModal}
          setOpenConfirmDelete={setOpenDeleteModal}
          title="Assessment"
          deleteName={selectedAssessment?.name}
          isLoading={deleteAssessmentState.isLoading}
          handleDelete={handleDelete}
        />
      )}
    </MainLayout>
  );
};

export default AssessmentComponents;

const AssessmentTable = ({ data, key, handleDelete }) => {
  return (
    <div key={key} className="flex flex-col border-1 text-sm">
      <div className="flex justify-between items-center border-b-1 p-2 bg-[#C8E3C1]">
        <p className="font-medium">{data?.name}</p>
        <Button
          variant="icon"
          handleSubmit={handleDelete}
          Icon={<MdDeleteForever size={20} className="text-red-500" />}
        />
      </div>

      {data?.assessments?.map((assessment, index) => (
        <div
          key={index}
          className={cn(
            "flex justify-between items-center  p-2",
            data.assessments.length - 1 !== index && "border-b-1",
          )}
        >
          <p className="w-[70%]">{assessment?.name}</p>

          <p className="w-[10%]">{assessment?.maxValue}</p>
        </div>
      ))}
    </div>
  );
};
