import cn from "Utils/cn";
import { cva } from "class-variance-authority";

const strokeVariants = cva("w-full border-[#DDDDDD]", {
  variants: {
    variant: {
      default: "border-t-1",
      2: "border-t-2",
    },
  },

  defaultVariants: {
    variant: "default",
  },
});

const Divider = ({ title, className, stroke }) => {
  return (
    <div className="w-full flex gap-2 items-center">
      <p className="text-nowrap font-medium">{title}</p>
      <div className={strokeVariants({ variant: stroke })} />
    </div>
  );
};

export default Divider;
