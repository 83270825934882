import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import useAssignClass from "../hooks/useAssignClass";
import Button from "Component/Forms/Button";
import Select from "Component/Forms/Select";
import { transformClassOption } from "Utils/transform";

const AssignClass = ({ open, setOpen }) => {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    selectedClasses,
    setSelectedClasses,
    assignClassState,
    classState,
  } = useAssignClass();

  return (
    <CustomModal
      open={open}
      handleClose={() => !assignClassState.isLoading && setOpen(false)}
      className={"min-w-[30%]"}
      title="Assign Class to Class Teacher"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-3 w-full mt-2">
            <Select
              withLabel
              label="Classes"
              data={transformClassOption(classState.data?.data)}
              value={selectedClasses}
              selectMultiple
              className={`${props.errors?.classIds && "border-[red] "}`}
              isLoading={classState.isLoading}
              onChangeOption={(x) => {
                props.setFieldValue(
                  "classIds",
                  x.map((eachClass) => eachClass?.value),
                );
                setSelectedClasses(x);
              }}
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[30%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Assign Class"
                handleSubmit={props.handleSubmit}
                isLoading={assignClassState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AssignClass;
