import Button from "Component/Forms/Button";
import { ActionMenuWrapper } from "Component/ActionMenuWrapper";

const SelectedMenu = ({ useSelectApplicantStore }) => {
  const { selectedItems, resetSelection } = useSelectApplicantStore();

  const handleCloseMenu = () => {
    resetSelection();
  };
  return (
    <ActionMenuWrapper
      animate={selectedItems?.length}
      selectedCount={selectedItems?.length}
      handleCloseMenu={handleCloseMenu}
    >
      <Button
        title="Allocate Subjects"
        variant="outline"
        className="text-sm sm:text-xs sm:rounded-sm rounded-md font-light"
        disabled
      />

      <Button
        title="Activate/Deactivate"
        variant="outline"
        className="text-sm sm:text-xs sm:rounded-sm rounded-md font-light"
      />

      <Button
        title="Transfer"
        variant="outline"
        className="text-sm sm:text-xs sm:rounded-sm rounded-md font-light"
        disabled
      />

      <Button
        title="Password Reset"
        variant="outline"
        className="text-sm sm:text-xs sm:rounded-sm rounded-md font-light"
        disabled
      />
    </ActionMenuWrapper>
  );
};

export default SelectedMenu;
