import { IoAddCircleOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import CustomSearch from "Component/CustomSearch";
import Pagination from "Component/Pagination/Pagination";
import ClassCard from "./ClassCard";
import ConfirmDelete from "Component/ConfirmDelete";
import useAssignmentStore from "../hooks/useAssignmentStore";
import AssignSubjectToClass from "./AssignSubjectToClass";
import Divider from "Component/Divider";
import useAssignSubjectToClass from "../hooks/useAssignSubjectToClass";

const ClassSubjectAssignment = () => {
  const {
    confirmRemoveAll,
    confirmRemoveSingle,
    setConfirmRemoveAll,
    setConfirmRemoveSingle,
    setOpenModal,
    openModal,
  } = useAssignmentStore();

  const {
    curriculumState,
    handleDelete,
    deleteCurriculumState,
    handleSearch,
    setPage,
  } = useAssignSubjectToClass();
  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Class Subject Assignment"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Assign subjects"
            handleSubmit={() => setOpenModal(true)}
          />
        </div>
      </BodyHeaderLayout>

      <div className="flex justify-between items-center sm:items-start sm:gap-3 sm:flex-col mb-[20px] bg-white p-3">
        <CustomSearch
          className="w-[40%] sm:w-[100%] text-[14px]  "
          placeholderText="Search by name"
          handleChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <div className="relative pb-10 pt-5">
        {!curriculumState.isFetching ? (
          curriculumState?.data?.data?.length > 0 ? (
            <div
              className="max-h-[70vh] overflow-y-auto flex flex-col gap-10"
              style={{ scrollbarWidth: "none" }}
            >
              <SectionClasses
                title={"All Section"}
                data={curriculumState?.data?.data}
              />
            </div>
          ) : (
            <div className="flex justify-center">No data</div>
          )
        ) : (
          <div className="w-full flex justify-center mt-10">
            <CircularProgress />
          </div>
        )}

        {!curriculumState.isLoading && (
          <div className="absolute bottom-0 right-2">
            <Pagination
              onPageChange={setPage}
              pageTotal={curriculumState?.data?.totalPages}
              initialPage={curriculumState?.data?.currentPage}
            />
          </div>
        )}
      </div>

      {confirmRemoveAll && (
        <ConfirmDelete
          openConfirmDelete={confirmRemoveAll}
          setOpenConfirmDelete={setConfirmRemoveAll}
          title={"All Subjects From Class"}
          deleteName={deleteCurriculumState?._id}
          isLoading={deleteCurriculumState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      {confirmRemoveSingle && (
        <ConfirmDelete
          openConfirmDelete={confirmRemoveSingle}
          setOpenConfirmDelete={setConfirmRemoveSingle}
          title={"Single Subject From Class"}
          deleteName={"English"}
          isLoading={false}
          handleDelete={() => {}}
        />
      )}

      {openModal && (
        <AssignSubjectToClass open={openModal} setOpen={setOpenModal} />
      )}
    </MainLayout>
  );
};

export default ClassSubjectAssignment;

const SectionClasses = ({ title, data }) => {
  return (
    <div className="flex flex-col gap-3">
      <Divider title={title} stroke={2} />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-5 ">
        {data.map((cl, index) => (
          <div key={index}>
            <ClassCard data={cl} />
          </div>
        ))}
      </div>
    </div>
  );
};
