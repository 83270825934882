import { MdDelete } from "react-icons/md";

import Button from "Component/Forms/Button";

const Roles = ({ data }) => {
  return (
    <div>
      {data?.roles?.length > 0 ? (
        <div className="flex flex-col gap-3">
          {data?.roles?.map((role, index) => (
            <div className="text-sm flex gap-3 items-center">
              <p>{index + 1}.</p>
              <p>{role?.name}</p>

              <Button
                Icon={<MdDelete />}
                variant="icon"
                className="text-red-500"
                disabled
                handleSubmit={() => {}}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-sm flex justify-center">No Roles</div>
      )}
    </div>
  );
};

export default Roles;
