import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import { useNavigate } from "react-router";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import Select from "Component/Forms/Select";
import Input from "Component/Forms/Input";
import UploadImage from "Screens/AdmissionScreen/Applicants/Manage/CreateApplicant/UploadImage";
import { genderList } from "Utils/constants";
import { academicFlattenRoutes } from "Routes/academicRoutes";
import useCreateStudents from "../../hooks/useCreateStudents";
import { transformSelectOption, transformClassOption } from "Utils/transform";

const CreateStudent = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    selectedGender,
    setSelectedGender,
    selectedClass,
    setSelectedClass,
    selectedSection,
    setSelectedSection,
    selectedTerm,
    setSelectedTerm,
    selectedSession,
    setSelectedSession,

    handleSubmit,

    initialValues,
    validationSchema,

    sectionState,
    classesState,
    termState,
    sessionState,
    createStudentState,
  } = useCreateStudents();

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Create new student"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoIosEye}
            withIcon
            className="w-fit  text-[12px] h-[41px] "
            title="View Students"
            // prettier-ignore
            handleSubmit={() =>
              navigate(`/${params.shortName}/${academicFlattenRoutes.students}`)
            }
          />
        </div>
      </BodyHeaderLayout>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="bg-white">
            <UploadImage
              onFileChanged={(e) => {
                props.setFieldValue("photo", e);
              }}
            />

            <div className="flex  flex-col gap-6 px-6 py-6 pt-0 ">
              <div className="grid grid-cols-3 sm:flex sm:flex-col sm:gap-3 gap-4">
                <Input
                  withTitle
                  title="First Name"
                  placeholder="Enter first name"
                  name="firstName"
                />
                <Input
                  withTitle
                  title="Middle Name"
                  placeholder="Enter first name"
                  name="middleName"
                />
                <Input
                  withTitle
                  title="Last Name"
                  placeholder="Enter first name"
                  name="lastName"
                />
                <Input
                  withTitle
                  title="Date of Birth"
                  name="dob"
                  type="date"
                  inputStyle="py-2"
                />

                <Select
                  withLabel
                  label="Gender"
                  data={genderList}
                  value={selectedGender}
                  className={`${props.errors?.gender && "border-[red] "}`}
                  onChangeOption={(x) => {
                    props.setFieldValue("gender", x?.value);
                    setSelectedGender(x);
                  }}
                />

                <Input
                  withTitle
                  title="Address"
                  placeholder="Enter Address"
                  name="address"
                />

                <Select
                  withLabel
                  label="Section"
                  data={transformSelectOption(sectionState?.data)}
                  isLoading={sectionState.isLoading}
                  value={selectedSection}
                  className={`${props.errors?.enrollmentSectionId && "border-[red] "}`}
                  onChangeOption={(x) => {
                    props.setFieldValue("enrollmentSectionId", x?.value);
                    setSelectedSection(x);
                  }}
                />

                <Select
                  withLabel
                  label="Class"
                  data={transformClassOption(classesState?.data?.data)}
                  isLoading={classesState.isLoading}
                  value={selectedClass}
                  className={`${props.errors?.enrollmentClassId && "border-[red] "}`}
                  onChangeOption={(x) => {
                    props.setFieldValue("enrollmentClassId", x?.value);
                    setSelectedClass(x);
                  }}
                />

                <Select
                  withLabel
                  label="Enrollment Term"
                  data={transformSelectOption(termState?.data)}
                  isLoading={termState.isLoading}
                  value={selectedTerm}
                  className={`${props.errors?.enrollmentTermId && "border-[red] "}`}
                  onChangeOption={(x) => {
                    props.setFieldValue("enrollmentTermId", x?.value);
                    setSelectedTerm(x);
                  }}
                />
              </div>

              <Select
                withLabel
                label="Enrollment Session"
                data={transformSelectOption(sessionState?.data)}
                isLoading={sessionState.isLoading}
                value={selectedSession}
                className={`${props.errors?.enrollmentSessionId && "border-[red] "}`}
                onChangeOption={(x) => {
                  props.setFieldValue("enrollmentSessionId", x?.value);
                  setSelectedSession(x);
                }}
              />

              <div className="flex flex-col gap-1">
                <h3 className="font-bold sm:text-[14px] sm:font-semibold">
                  Medical Information
                </h3>
                <Input
                  withTitle
                  title="Does the child have any medical challenges (state if any)"
                  placeholder="Enter medical challenges"
                  name="medicalInfo"
                />
              </div>

              <div className="flex flex-col gap-1">
                <h3 className="font-bold sm:text-[14px] sm:font-semibold">
                  Guardian/Parent Information
                </h3>
                <div className="grid grid-cols-2 sm:flex sm:flex-col sm:gap-3 gap-4">
                  <Input
                    withTitle
                    title="Full Name"
                    placeholder="Enter Full Name"
                    name="parentOrGuardianInfo.fullName"
                  />
                  <Input
                    withTitle
                    title="Phone Number"
                    placeholder="000-0000-0000"
                    name="parentOrGuardianInfo.phone"
                  />
                  <Input
                    withTitle
                    title="Relationship"
                    placeholder="Example: Father, Mother, Sibling etc"
                    name="parentOrGuardianInfo.relationShip"
                  />
                  <Input
                    withTitle
                    title="Email"
                    placeholder="Enter Email"
                    name="parentOrGuardianInfo.email"
                  />
                </div>
              </div>

              <Button
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Create Student"
                isLoading={createStudentState.isLoading}
                handleSubmit={props.handleSubmit}
              />
            </div>
          </div>
        )}
      </Formik>
    </MainLayout>
  );
};

export default CreateStudent;
