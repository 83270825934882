import usePermissionStore from "hooks/Auth/usePermissionStore";
import MainLayout from "./MainLayout";

const ProtectedRoute = ({ element, requiredPermissions = [] }) => {
  const { hasAnyPermission } = usePermissionStore();

  // If there are no required permissions, just render the element
  if (requiredPermissions.length === 0) {
    return element;
  }

  // Check if the user has any of the required permissions
  if (hasAnyPermission(requiredPermissions)) {
    return element;
  }

  // User doesn't have required permissions
  return <NoPermissionPage />;
};

export default ProtectedRoute;

const NoPermissionPage = () => {
  return (
    <MainLayout>
      <div className="flex flex-col items-center justify-center h-full">
        <h2 className="text-2xl text-center font-semibold text-red-600 mb-4">
          Access Denied
        </h2>
        <p className="text-gray-600 text-center">
          You don't have permission to view this page. Please contact your
          administrator if you believe this is an error.
        </p>
      </div>
    </MainLayout>
  );
};
