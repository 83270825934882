import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "categories";

const categoryAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        url: `/${endpoint}/organisation/${getSchoolId()}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_CATEGORIES,
                id,
              })),
              { type: tags.ACADEMICS_CATEGORIES, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_CATEGORIES, id: "LIST" }],
    }),

    createCategory: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_CATEGORIES, id: "LIST" }],
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_CATEGORIES, id: "LIST" }],
    }),

    updateCategory: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PUT",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_CATEGORIES, id: "LIST" }],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} = categoryAcademics;
