import { IoAddCircleOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import CustomSearch from "Component/CustomSearch";
import OneFieldUpdate from "Screens/AdmissionScreen/Settings/component/OneFieldUpdate";
import ConfirmDelete from "Component/ConfirmDelete";
import CreateSubject from "./CreateSubject";
import SimpleCard from "Component/Cards/SimpleCard";

import useSubject from "../hooks/useSubject";

const Subjects = () => {
  const {
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    openCreateModal,
    setOpenCreateModal,

    subjectState,
    selectedSubject,
    setSelectedSubject,

    handleDelete,
    deleteSubjectState,

    handleUpdate,
    updateSubjectState,
  } = useSubject();

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Subjects"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Add Subjects"
            handleSubmit={() => setOpenCreateModal(true)}
          />
        </div>
      </BodyHeaderLayout>

      <div className="flex justify-between items-center sm:items-start sm:gap-3 sm:flex-col mb-[20px] bg-white p-3">
        <CustomSearch
          className="w-[40%] sm:w-[100%] text-[14px]  "
          placeholderText="Search by name"
          //   handleChange={(e) => handleSearch(e.target.value)}
        />

        <p className="sm:text-xs">
          Total Subjects:{" "}
          <span className="font-semibold">{subjectState?.data?.length}</span>
        </p>
      </div>

      <div className="relative pb-10">
        {!subjectState?.isFetching ? (
          subjectState?.data?.length > 0 ? (
            <div
              className="grid grid-cols-4 sm:grid-cols-1 gap-5 max-h-[70vh] overflow-y-auto"
              style={{ scrollbarWidth: "none" }}
            >
              {subjectState?.data.map((subject, index) => (
                <SimpleCard
                  title={subject?.name}
                  key={index}
                  onClickDelete={() => {
                    setOpenDeleteModal(true);
                    setSelectedSubject(subject);
                  }}
                  onClickEdit={() => {
                    setOpenEditModal(true);
                    setSelectedSubject(subject);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center">No Data</div>
          )
        ) : (
          <div className="flex justify-center mt-10">
            <CircularProgress />
          </div>
        )}
      </div>

      {openEditModal && (
        <OneFieldUpdate
          openUpdateModal={openEditModal}
          setOpenUpdateModal={setOpenEditModal}
          title="Subject"
          label="Subject Name"
          value={selectedSubject?.name}
          handleUpdate={(e) => handleUpdate(e)}
          isLoading={updateSubjectState.isLoading}
        />
      )}

      {openDeleteModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteModal}
          setOpenConfirmDelete={setOpenDeleteModal}
          deleteName={selectedSubject?.name}
          title={"Subject"}
          isLoading={deleteSubjectState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      <CreateSubject open={openCreateModal} setOpen={setOpenCreateModal} />
    </MainLayout>
  );
};

export default Subjects;
