import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "user-roles";

const userRole = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoles: builder.query({
      query: () => ({
        url: `/${endpoint}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.USER_ROLES,
                id,
              })),
              { type: tags.USER_ROLES, id: "LIST" },
            ]
          : [{ type: tags.USER_ROLES, id: "LIST" }],
    }),

    assignRoleToUser: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          // organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.USER_ROLES, id: "LIST" }],
    }),

    deleteRoleFromUser: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.USER_ROLES, id: "LIST" }],
    }),

    updateUserRole: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PUT",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.USER_ROLES, id: "LIST" }],
    }),
  }),
});

export const {
  useGetUserRolesQuery,
  useAssignRoleToUserMutation,
  useDeleteRoleFromUserMutation,
  useUpdateUserRoleMutation,
} = userRole;
