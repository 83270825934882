import { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import { useCreateSectionMutation } from "Network/academics/sectionAcademics";
import { useGetGradingScalesQuery } from "Network/academics/gradeManagement/gradeScaleAcademics";
import { useGetAssessmentsQuery } from "Network/academics/gradeManagement/assessmentComponentAcademics";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Section name is required"),
  gradingScaleId: Yup.string().required("Grading scale is required"),
  assessmentStructureId: Yup.string().required(
    "Assessment structure is required",
  ),
});

const useCreateSection = ({ setOpen }) => {
  const [selectedGradingScale, setSelectedGradingScale] = useState({
    label: "Select Grading Scale",
    value: null,
  });
  const [selectedAssessment, setSelectedAssessment] = useState({
    label: "Select Assessment",
    value: null,
  });
  const [createSection, createSectionState] = useCreateSectionMutation();
  const gradingScaleState = useGetGradingScalesQuery();
  const assessmentState = useGetAssessmentsQuery();

  const initialValues = {
    name: "",
    gradingScaleId: "",
    assessmentStructureId: "",
  };

  const handleSubmit = (values) => {
    createSection(values)
      .unwrap()
      .then((_) => {
        toastify.success("Section created");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return {
    validationSchema,
    initialValues,
    handleSubmit,

    createSectionState,
    gradingScaleState,
    assessmentState,

    selectedAssessment,
    setSelectedAssessment,
    selectedGradingScale,
    setSelectedGradingScale,
  };
};

export default useCreateSection;
