import { toast as toastify } from "react-toastify";
import { toast } from "react-hot-toast";

import useStudentActionStore from "./useStudentActionStore";
import { useUpdateStudentStatusMutation } from "Network/academics/studentAcademics";

const useUpdateStudentStatus = () => {
  const { setOpenUpdateStatusModal, selectedStudent } = useStudentActionStore();
  const [updateStudentStatus, updateStudentStatusState] =
    useUpdateStudentStatusMutation();

  const handleUpdateStudentStatus = () => {
    const studentIds = [selectedStudent._id];
    const status = selectedStudent.status === "active" ? "suspended" : "active";

    updateStudentStatus({ studentIds, status })
      .unwrap()
      .then((_) => {
        toastify.success("Student Status Updated Successfully");
        setOpenUpdateStatusModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleUpdateStudentStatus,
    updateStudentStatusState,
  };
};

export default useUpdateStudentStatus;
