import MainLayout from "Component/Layout/MainLayout";
import DashboardCount from "Component/DashboardCount";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import { useGetAdmissionDashboardStatisticsQuery } from "Network/admission/dashboard";

const Dashboard = () => {
  return (
    <MainLayout>
      <div className=" ">
        <BodyHeaderLayout title={"Admission dashboard"} />

        <div className="flex flex-col md:flex-row gap-6 flex-wrap ">
          <DashboardCount
            title="Total Applicants"
            count={0}
            bgColor="bg-[blue]"
            textColor="text-blue-500"
          />

          <DashboardCount
            title="Approved Applicants"
            count={0}
            bgColor="bg-[green]"
            textColor="text-green-500"
          />

          <DashboardCount
            title="Pending Applicants"
            count={0}
            bgColor="bg-[orange]"
            textColor="text-orange-500"
          />

          <DashboardCount
            title="Rejected Applicants"
            count={0}
            bgColor="bg-[red]"
            textColor="text-red-500"
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Dashboard;
