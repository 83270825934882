import { useState } from "react";
import { toast } from "react-toastify";

import {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useEditCategoryMutation,
} from "Network/admission/classes/category";

const useCategory = () => {
  const categories = useGetCategoriesQuery();
  const [creatCategory, createCategoryState] = useCreateCategoryMutation();
  const [deleteCategory, deleteCategoryState] = useDeleteCategoryMutation();
  const [editCategory, editCategoryState] = useEditCategoryMutation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = (values, actions) => {
    creatCategory(values)
      .unwrap()
      .then(() => actions.resetForm())
      .catch(() => toast.error("Something went wrong"));
  };

  const handleDelete = () => {
    deleteCategory({ id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Category deleted successfully");
        setConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  const handleUpdate = (value) => {
    editCategory({ data: { name: value }, id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Category Edited successfully");
        setOpenUpdateModal(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  return {
    categories,
    createCategoryState,
    deleteCategoryState,
    editCategoryState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
  };
};

export default useCategory;
