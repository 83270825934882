import { useState } from "react";

import cn from "Utils/cn";

const InputField = ({
  currentField,
  assessment,
  handleSaveScore,
  maxValue,
  score,
  isFetching,
}) => {
  const [focusedField, setFocusedField] = useState(null);

  return (
    <input
      type="number"
      className={cn(
        "focus:outline-none w-20 px-2 py-1 border border-gray-500 rounded placeholder:text-gray-800",
        isFetching &&
          focusedField?._id === assessment?._id &&
          "border-green-400",
        currentField?._id === assessment?._id && "border-yellow-500",
      )}
      min="0"
      max={maxValue}
      value={
        focusedField?._id === assessment?._id
          ? focusedField?.value
          : assessment?.value
      }
      placeholder={assessment?.value}
      onChange={(e) => {
        setFocusedField({
          ...assessment,
          value: e.target.value,
        });
      }}
      onBlur={(e) => {
        handleSaveScore(e, maxValue, assessment, score);
      }}
    />
  );
};

export default InputField;
