import { Tabs } from "@radix-ui/themes";
import Skeleton from "react-loading-skeleton";

import Bio from "./Bio";
import Subject from "./Subject";

const DetailedInfo = ({ studentState }) => {
  if (studentState.isFetching) {
    return (
      <div className="w-full h-[400px]">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }
  return (
    <div className="bg-white rounded-md w-full h-fit">
      <Tabs.Root defaultValue="bio">
        <Tabs.List className="flex gap-24 text-xs">
          <Tabs.Trigger value="bio">
            <p className="text-[16px] sm:text-[13px] font-semibold">Bio</p>
          </Tabs.Trigger>
          <Tabs.Trigger value="subject">
            <p className="text-[16px] sm:text-[13px] font-semibold">Subject</p>
          </Tabs.Trigger>
        </Tabs.List>

        <div className="p-4 h-full">
          <Tabs.Content value="bio">
            <Bio data={studentState.data} />
          </Tabs.Content>

          <Tabs.Content value="subject">
            <Subject data={studentState.data} />
          </Tabs.Content>
        </div>
      </Tabs.Root>
    </div>
  );
};

export default DetailedInfo;
