import { useState } from "react";
import { MdAdd } from "react-icons/md";

import Button from "Component/Forms/Button";
import AssignSubjectToStudent from "./AssignSubjectToStudent";

const Subject = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex flex-col gap-1">
        <p className="font-semibold text-[16px] sm:text-[13px]">All Subjects</p>

        <div className="border-y-1 flex flex-wrap gap-3 py-5">
          {data?.subjects.map((subject, index) => (
            <p key={index} className=" border-1 rounded-full p-1 sm:text-xs">
              {subject?.name}
            </p>
          ))}
        </div>
      </div>

      <div className="flex justify-between">
        <Button
          Icon={<MdAdd size={24} />}
          variant="icon"
          handleSubmit={() => setOpen(true)}
        />

        <p className="font-semibold self-end sm:text-[13px]">
          Total Subjects: {data?.subjects?.length}
        </p>
      </div>

      {open && (
        <AssignSubjectToStudent
          open={open}
          setOpen={setOpen}
          assignedSubjects={data?.subjects}
          studentId={data?._id}
        />
      )}
    </div>
  );
};

export default Subject;
