import { IoAddCircleOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import CustomSearch from "Component/CustomSearch";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import ClassCard from "./ClassCard";
import Divider from "Component/Divider";
import ConfirmDelete from "Component/ConfirmDelete";
import useClassStore from "../hooks/useClassStore";
import CreateClass from "./CreateClass";
import OneFieldUpdate from "Screens/AdmissionScreen/Settings/component/OneFieldUpdate";
import Pagination from "Component/Pagination/Pagination";
import {
  useGetClassesQuery,
  useDeleteClassMutation,
  useUpdateClassMutation,
} from "Network/academics/classAcademics";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";

const Classes = () => {
  const {
    openDeleteClassModal,
    setOpenDeleteClassModal,
    openCreateClassModal,
    setOpenCreateClassModal,
    selectedClass,

    openEditClassModal,
    setOpenEditClassModal,
  } = useClassStore();

  const { page, setPage, search, handleSearch } = usePaginationAndSearch();
  const classState = useGetClassesQuery({ page, search });
  const [deleteClass, deleteClassState] = useDeleteClassMutation();
  const [updateClass, updateClassState] = useUpdateClassMutation();

  const handleDeleteClass = () => {
    deleteClass(selectedClass?._id)
      .unwrap()
      .then((_) => {
        toast.success("Class deleted successfully");
        setOpenDeleteClassModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenDeleteClassModal(false);
      });
  };

  const handleUpdateClass = (value) => {
    const data = {
      name: value,
    };
    updateClass({
      id: selectedClass._id,
      data,
    })
      .unwrap()
      .then((_) => {
        toast.success("Class updated successfully");
        setOpenEditClassModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenEditClassModal(false);
      });
  };

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Class"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Create Class"
            handleSubmit={() => setOpenCreateClassModal(true)}
          />
        </div>
      </BodyHeaderLayout>

      <div className="flex justify-between items-center sm:items-start sm:gap-3 sm:flex-col mb-[20px] bg-white p-3">
        <CustomSearch
          className="w-[40%] sm:w-[100%] text-[14px]  "
          placeholderText="Search by name"
          handleChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      {!classState.isFetching ? (
        classState?.data?.data?.length > 0 ? (
          <div className="relative pb-10 pt-5">
            <div
              className="max-h-[70vh] overflow-y-auto flex flex-col gap-10"
              style={{ scrollbarWidth: "none" }}
            >
              <SectionClasses
                title={"All Section"}
                data={classState?.data?.data}
              />
            </div>
            <div className="absolute bottom-0 right-2">
              <Pagination
                onPageChange={setPage}
                pageTotal={classState?.data?.totalPages}
                initialPage={classState?.data?.currentPage}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center">No Data</div>
        )
      ) : (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )}

      {openDeleteClassModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteClassModal}
          setOpenConfirmDelete={setOpenDeleteClassModal}
          title={"Class"}
          deleteName={selectedClass?.name}
          isLoading={deleteClassState.isLoading}
          handleDelete={handleDeleteClass}
        />
      )}

      {openEditClassModal && (
        <OneFieldUpdate
          openUpdateModal={openEditClassModal}
          setOpenUpdateModal={setOpenEditClassModal}
          title="Class"
          label="Class Name"
          value={selectedClass?.name}
          handleUpdate={(e) => handleUpdateClass(e)}
          isLoading={updateClassState.isLoading}
        />
      )}

      {openCreateClassModal && (
        <CreateClass
          open={openCreateClassModal}
          setOpen={setOpenCreateClassModal}
        />
      )}
    </MainLayout>
  );
};

export default Classes;

const SectionClasses = ({ title, data }) => {
  return (
    <div className="flex flex-col gap-3">
      <Divider title={title} stroke={2} />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-5 ">
        {data?.map((cl, index) => (
          <ClassCard data={cl} key={index} />
        ))}
      </div>
    </div>
  );
};
