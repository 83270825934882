import { useParams } from "react-router";

import Logo from "../Logo";
import { useGetSchoolInfoQuery } from "Network/userManagement/profile";
import NotFoundPage from "Component/NotFoundPage";

const PreLayout = ({ title, children, className }) => {
  const params = useParams();
  const infoState = useGetSchoolInfoQuery(params?.shortName);

  if (infoState.isFetching) return <LoadingPage />;

  return (
    <div className=" min-h-[100vh] bg-main-bg sm:bg-white pt-16 sm:pt-10">
      {params?.shortName && infoState.isError ? (
        <NotFoundPage />
      ) : (
        !infoState.isLoading && (
          <div
            className={`w-[50vw] sm:w-[100vw] m-auto flex flex-col items-center ${className}`}
          >
            <div className="flex flex-col items-center gap-2">
              <Logo image={infoState.data?.logo} />
              <p className="text-[14px] font-bold text-primary text-center">
                {title}
              </p>
            </div>

            {children}
          </div>
        )
      )}
    </div>
  );
};

export default PreLayout;

export const LoadingPage = () => {
  return (
    <div className="h-[100vh] w-full flex items-center justify-center">
      <img
        className="animate-fade-in-out inset-0 object-cover"
        src={"/images/logo-loader.png"}
        alt="soft school"
      />
    </div>
  );
};
