import { useState } from "react";
import { MdCancel } from "react-icons/md";
import { motion } from "framer-motion";

import useSidebarStore from "./hooks/useSidebarStore";
import cn from "Utils/cn";
import useWindowScreen from "Utils/useWindowScreen";
import SessionControl from "Screens/AcademicScreens/SessionControl";
import IDManagement from "Screens/AcademicScreens/IDManagement";
import useToggleActiveAppStore from "hooks/useToggleActiveAppStore";
import { currentApp, PERMISSIONS_LISTS } from "Utils/constants";
import usePermissionStore from "hooks/Auth/usePermissionStore";

const SidenavRoot = ({ children }) => {
  const { miniSidebar, setMiniSidebar, toggleMiniSidebar } = useSidebarStore();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { activeApp } = useToggleActiveAppStore();
  const { hasAnyPermission } = usePermissionStore();

  const { windowWidth, isMobile } = useWindowScreen();
  const sidebarMiniWidthCollapse = !isMobile ? 80 : 0;
  const sidebarFullWidth = !isMobile ? 250 : windowWidth;

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidebar && !onMouseEnter) {
      setMiniSidebar(false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidebar(true);
      setOnMouseEnter(false);
    }
  };

  return (
    <div className="fixed h-[100%] z-20 mt-[63px] sm:mt-12 text-sm">
      <motion.div
        className={cn(
          "bg-white h-[calc(100%-63px)] sm:h-[calc(100%-12px)] pt-10 pb-4 overflow-y-auto border-t-1 z-20 border-primary flex flex-col justify-between",
          miniSidebar && "flex flex-col items-center",
        )}
        initial={{
          width: miniSidebar ? sidebarMiniWidthCollapse : sidebarFullWidth,
          x: miniSidebar ? -220 : 0,
        }}
        animate={{
          width: miniSidebar ? sidebarMiniWidthCollapse : sidebarFullWidth,
          x: 0,
        }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.4 }}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <MdCancel
          onClick={(e) => {
            toggleMiniSidebar();
          }}
          size={30}
          className="lg:hidden absolute top-3 right-[2px] cursor-pointer"
        />

        <div>{children}</div>

        <div className="flex flex-col gap-3">
          {hasAnyPermission([
            PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            PERMISSIONS_LISTS.ADMIN,
          ]) && (
            <IDManagement
              miniSidebar={miniSidebar}
              setMiniSidebar={setMiniSidebar}
              setOnMouseEnter={setOnMouseEnter}
              isMobile={isMobile}
            />
          )}

          {activeApp === currentApp.ACADEMICS && (
            <SessionControl
              miniSidebar={miniSidebar}
              setMiniSidebar={setMiniSidebar}
              setOnMouseEnter={setOnMouseEnter}
              isMobile={isMobile}
            />
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default SidenavRoot;
