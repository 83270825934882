import admissionApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const category = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        url: `/categories/schools/${getSchoolId()}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: tags.SCHOOL_CATEGORIES, id })),
              { type: tags.SCHOOL_CATEGORIES, id: "LIST" },
            ]
          : [{ type: tags.SCHOOL_CATEGORIES, id: "LIST" }],
    }),

    getCategory: builder.query({
      query: (id) => ({
        url: `/categories/${id}`,
      }),
      providesTags: (result, error, id) => [
        { type: tags.SCHOOL_CATEGORIES, id },
      ],
    }),

    createCategory: builder.mutation({
      query: (data) => ({
        url: "/categories",
        method: "POST",
        body: {
          ...data,
          schoolId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.SCHOOL_CATEGORIES, id: "LIST" }],
    }),

    editCategory: builder.mutation({
      query: ({ data, id }) => ({
        url: `/categories/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_CATEGORIES, id: arg._id },
      ],
    }),

    deleteCategory: builder.mutation({
      query: ({ id }) => ({
        url: `/categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_CATEGORIES, id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useEditCategoryMutation,
  useGetCategoryQuery,
} = category;
