import { IoEyeOutline, IoLibraryOutline } from "react-icons/io5";

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineAssignmentInd } from "react-icons/md";
import { FcCollaboration } from "react-icons/fc";

import { PopOver, PopOverList } from "Component/PopOver";
import { ActionTriggerIcon } from "Component/Table";
import { academicFlattenRoutes } from "Routes/academicRoutes";
import useStaffActionStore from "../hooks/useStaffActionStore";

const Actions = ({ data }) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    setOpenConfirmDelete,
    setSelectedStaff,
    setOpenAssignRoleModal,
    setOpenAssignClassModal,
    setOpenAssignSubjectModal,
  } = useStaffActionStore();

  return (
    <PopOver
      className="w-fit text-[12px]"
      header={<ActionTriggerIcon />}
      content={
        <PopOverList
          className="px-2"
          items={[
            {
              name: "View",
              icon: <IoEyeOutline size={16} />,
              onClick: () => {
                navigate(
                  `/${params.shortName}/${academicFlattenRoutes.staff}/${data._id}/${data.userId}`,
                );
              },
            },

            {
              name: "Assign Role",
              icon: <MdOutlineAssignmentInd size={16} />,
              onClick: () => {
                setSelectedStaff(data);
                setOpenAssignRoleModal(true);
              },
            },

            {
              name: "Assign Class",
              icon: <FcCollaboration size={16} />,
              onClick: () => {
                setSelectedStaff(data);
                setOpenAssignClassModal(true);
              },
            },

            {
              name: "Assign Subject",
              icon: <IoLibraryOutline size={16} />,
              onClick: () => {
                setSelectedStaff(data);
                setOpenAssignSubjectModal(true);
              },
            },

            {
              name: "Edit",
              icon: <FiEdit size={16} />,
              // onClick: () => {
              //   {
              //   }
              // },
            },

            {
              name: "Delete",
              icon: <RiDeleteBinLine size={16} />,
              onClick: () => {
                console.log(data);
                setSelectedStaff(data);
                setOpenConfirmDelete(true);
              },
              style: "text-red-600",
            },
          ]}
        />
      }
    />
  );
};

export default Actions;
