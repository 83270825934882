import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "id-initials";

const identification = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getIDInitials: builder.query({
      query: () => ({
        url: `/${endpoint}/organisation/${getSchoolId()}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ID_MANAGEMENT,
                id,
              })),
              { type: tags.ID_MANAGEMENT, id: "LIST" },
            ]
          : [{ type: tags.ID_MANAGEMENT, id: "LIST" }],
    }),

    createIDInitials: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ID_MANAGEMENT, id: "LIST" }],
    }),

    updateIDInitials: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PATCH",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ID_MANAGEMENT, id: "LIST" }],
    }),
  }),
});

export const {
  useGetIDInitialsQuery,
  useCreateIDInitialsMutation,
  useUpdateIDInitialsMutation,
} = identification;
