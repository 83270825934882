import admissionApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const dashboard = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdmissionDashboardStatistics: builder.query({
      query: () => ({
        url: `/dashboard/admissions/statistics/${getSchoolId()}`,
      }),
      providesTags: (result, error, id) => [
        { type: tags.SCHOOL_APPLICANTS, id },
      ],
    }),
  }),
});

export const { useGetAdmissionDashboardStatisticsQuery } = dashboard;
