import { toast } from "react-toastify";

import { useEditApplicationMutation } from "Network/admission/applications";

const usePublishApplication = () => {
  const [publish, publishApplicationsState] = useEditApplicationMutation();

  const handlePublish = ({ values, data, setOpen }) => {
    publish({ data: values, id: data._id })
      .unwrap()
      .then((_) => {
        toast.success("Application edited");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    publishApplicationsState,
    handlePublish,
  };
};

export default usePublishApplication;
