import cn from "Utils/cn";
import Button from "Component/Forms/Button";
import { MdOutlineEdit, MdDelete } from "react-icons/md";

const SimpleCard = ({
  key,
  title,
  className,
  showEditButton = true,
  children,
  onClickEdit = () => {},
  onClickDelete = () => {},
}) => {
  return (
    <div
      key={key}
      className={cn(
        "flex justify-between h-[69px] min-w-[250px] sm:w-full items-center bg-white px-3 rounded-md shadow-md",
        className,
      )}
    >
      {title && <p className="text-xs font-semibold">{title}</p>}
      {children}

      <div className="flex gap-2">
        {showEditButton && (
          <Button
            Icon={<MdOutlineEdit />}
            variant="icon"
            handleSubmit={onClickEdit}
          />
        )}

        <Button
          Icon={<MdDelete />}
          variant="icon"
          className="text-red-500"
          handleSubmit={onClickDelete}
        />
      </div>
    </div>
  );
};

export default SimpleCard;
