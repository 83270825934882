import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import Select from "Component/Forms/Select";
import Input from "Component/Forms/Input";
import { transformSelectOption } from "Utils/transform";
import Button from "Component/Forms/Button";
import useCreateClass from "../hooks/useCreateClass";

const CreateClass = ({ open, setOpen }) => {
  const {
    validationSchema,
    initialValues,
    handleSubmit,

    selectedSection,
    setSelectedSection,
    selectedCategory,
    setSelectedCategory,

    sectionState,
    categoryState,
    createClassState,
  } = useCreateClass();
  return (
    <CustomModal
      open={open}
      handleClose={() => !createClassState.isLoading && setOpen(false)}
      className={"min-w-[40%]"}
      title="Create Class"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-3 w-full mt-2">
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-1">
              <Input withTitle title="Class name" name="className" />
              <Select
                withLabel
                label="Section"
                data={transformSelectOption(sectionState.data)}
                value={selectedSection}
                className={`${props.errors?.sectionId && "border-[red] "}`}
                isLoading={sectionState.isLoading}
                onChangeOption={(x) => {
                  props.setFieldValue("sectionId", x?.value);
                  setSelectedSection(x);
                }}
              />
            </div>

            <Select
              withLabel
              label="Category"
              data={transformSelectOption(categoryState.data)}
              value={selectedCategory}
              className={`${props.errors?.categoryId && "border-[red] "}`}
              isLoading={categoryState.isLoading}
              onChangeOption={(x) => {
                props.setFieldValue("categoryId", x?.value);
                setSelectedCategory(x);
              }}
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Create Class"
                handleSubmit={props.handleSubmit}
                isLoading={createClassState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateClass;
