import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import { IoAddCircleOutline } from "react-icons/io5";
import CustomSearch from "Component/CustomSearch";
import ApplicantDeskTopView from "./View/ApplicantDeskTopView";
import ApplicantMobileView from "./View/ApplicantMobileView";
import { useGetApplicantsQuery } from "Network/admission/applicants";
import useGetApplicantsFilters from "./hooks/useGetApplicantsFilters";
import ConfirmDelete from "Component/ConfirmDelete";
import EditApplicant from "./Manage/EditApplicant";
import Pagination from "Component/Pagination/Pagination";
import useApplicantStore from "./hooks/useApplicantStore";
import useDeleteApplicant from "./hooks/useDeleteApplicant";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";
import SelectedMenu from "./Manage/SelectedMenu";
import BulkSelection from "./Manage/BulkSelection";
import IdentificationInitials from "./Manage/IdentificationInitials";
import { createSelectionStore } from "Component/ActionMenuWrapper";

const Applicant = () => {
  const useSelectApplicantStore = createSelectionStore();
  const params = useParams();
  const navigate = useNavigate();

  const {
    page,
    search,
    openConfirmDelete,
    setOpenConfirmDelete,
    openEdit,
    setOpenEdit,
    openBulkSelection,
    setOpenBulkSelection,
    openIdentification,
    setOpenIdentification,
    setPage,
    handleSearch,
  } = useGetApplicantsFilters();

  const applicantsState = useGetApplicantsQuery({ page, search });
  const { applicants } = useApplicantStore();

  const { handleDeleteApplicant, deleteApplicantState } = useDeleteApplicant({
    id: applicants?._id,
  });

  return (
    <MainLayout>
      <div className="relative h-full">
        <BodyHeaderLayout title={"Manage Applicants  "}>
          <div className="flex gap-3 sm:w-[100%]">
            <Button
              Icon={IoAddCircleOutline}
              withIcon
              className="w-fit text-sm h-[41px]"
              title="Add Applicant"
              handleSubmit={() =>
                navigate(
                  `/${params.shortName}/${admissionFlattenRoutes.createApplicant}`,
                )
              }
            />
          </div>
        </BodyHeaderLayout>
        <div className="flex justify-between sm:flex-col mb-[20px] bg-white p-3 ">
          <CustomSearch
            className="w-[40%] sm:w-[100%] text-[14px]  "
            placeholderText="Search by name, section, class or admission status"
            handleChange={(e) => handleSearch(e.target.value)}
          />

          <Button
            title="Bulk Selection"
            className="text-sm"
            handleSubmit={() => setOpenBulkSelection(true)}
          />
        </div>
        <ApplicantDeskTopView
          applicantsState={applicantsState}
          useSelectApplicantStore={useSelectApplicantStore}
        />
        <ApplicantMobileView
          applicantsState={applicantsState}
          useSelectApplicantStore={useSelectApplicantStore}
        />

        {!applicantsState.isFetching && applicantsState?.data?.length !== 0 && (
          <div className="flex w-full justify-end mt-2 absolute bottom-0 right-0 sm:bottom-4">
            <Pagination
              onPageChange={setPage}
              pageTotal={applicantsState?.data?.totalPages}
              initialPage={applicantsState?.data?.currentPage}
            />
          </div>
        )}

        <SelectedMenu useSelectApplicantStore={useSelectApplicantStore} />
      </div>

      {openConfirmDelete && (
        <ConfirmDelete
          openConfirmDelete={openConfirmDelete}
          setOpenConfirmDelete={setOpenConfirmDelete}
          title="Applicant"
          deleteName={applicants?.firstName}
          handleDelete={handleDeleteApplicant}
          isLoading={deleteApplicantState.isLoading}
        />
      )}

      {openEdit && <EditApplicant open={openEdit} setOpen={setOpenEdit} />}

      {openBulkSelection && (
        <BulkSelection
          open={openBulkSelection}
          setOpen={setOpenBulkSelection}
        />
      )}

      {openIdentification && (
        <IdentificationInitials
          open={openIdentification}
          setOpen={setOpenIdentification}
        />
      )}
    </MainLayout>
  );
};

export default Applicant;
