import { IoAddCircleOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import CustomSearch from "Component/CustomSearch";
import Pagination from "Component/Pagination/Pagination";
import ConfirmDelete from "Component/ConfirmDelete";
import useAssignmentStore from "Screens/AcademicScreens/SubjectsManagement/hooks/useAssignmentStore";
import AssignClassToTeacher from "./AssignClassToTeacher";
import ClassCard from "./ClassCard";
import { useGetStaffQuery } from "Network/academics/staffAcademics";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";
import useAssignClassToTeacher from "../hooks/useAssignClassToTeacher";

const TeacherClassAssignment = () => {
  const {
    confirmRemoveAll,
    confirmRemoveSingle,
    setConfirmRemoveAll,
    setConfirmRemoveSingle,
    setOpenModal,
    openModal,
    selectedStaffSingleClass,
    selectedStaffAllClasses,
  } = useAssignmentStore();

  const { page, search, handleSearch, setPage } = usePaginationAndSearch();

  const staffState = useGetStaffQuery({ page, search, limit: 30 });

  const { handleDeleteSingleClass, handleDeleteAllClasses, deleteClassState } =
    useAssignClassToTeacher();

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Teacher Subject Assignment"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Assign Class"
            handleSubmit={() => setOpenModal(true)}
          />
        </div>
      </BodyHeaderLayout>

      <div className="flex justify-between items-center sm:items-start sm:gap-3 sm:flex-col mb-[20px] bg-white p-3">
        <CustomSearch
          className="w-[40%] sm:w-[100%] text-[14px]  "
          placeholderText="Search by name or ID"
          handleChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      {!staffState.isFetching ? (
        staffState?.data?.staff?.length > 0 ? (
          <div className="relative pb-10 pt-5">
            <div
              className="max-h-[70vh] overflow-y-auto flex flex-col gap-10"
              style={{ scrollbarWidth: "none" }}
            >
              <div className="grid grid-cols-4 sm:grid-cols-1 gap-5 ">
                {staffState?.data?.staff.map((data, index) => (
                  <div key={index}>
                    <ClassCard data={data} />
                  </div>
                ))}
              </div>
            </div>

            <div className="absolute bottom-0 right-2">
              <Pagination
                onPageChange={setPage}
                pageTotal={staffState?.data?.totalPages}
                initialPage={staffState?.data?.currentPage}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center mt-10">No Data</div>
        )
      ) : (
        <div className="flex justify-center mt-10">
          <CircularProgress />
        </div>
      )}

      {confirmRemoveAll && (
        <ConfirmDelete
          openConfirmDelete={confirmRemoveAll}
          setOpenConfirmDelete={setConfirmRemoveAll}
          title={`All Class From ${selectedStaffAllClasses?.staffNumber}`}
          deleteName={selectedStaffAllClasses?.staffNumber}
          isLoading={deleteClassState.isLoading}
          handleDelete={handleDeleteAllClasses}
        />
      )}

      {confirmRemoveSingle && (
        <ConfirmDelete
          openConfirmDelete={confirmRemoveSingle}
          setOpenConfirmDelete={setConfirmRemoveSingle}
          title={`${selectedStaffSingleClass?.name} Class From ${selectedStaffAllClasses?.staffNumber}`}
          isLoading={deleteClassState.isLoading}
          handleDelete={handleDeleteSingleClass}
        />
      )}

      {openModal && (
        <AssignClassToTeacher open={openModal} setOpen={setOpenModal} />
      )}
    </MainLayout>
  );
};

export default TeacherClassAssignment;
