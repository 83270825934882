import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import useAssignmentStore from "Screens/AcademicScreens/SubjectsManagement/hooks/useAssignmentStore";
import { useUpdateStaffMutation } from "Network/academics/staffAcademics";

const useAssignClassToTeacher = () => {
  const {
    setConfirmRemoveSingle,
    setConfirmRemoveAll,
    selectedStaffSingleClass,
    selectedStaffAllClasses,
  } = useAssignmentStore();

  const [deleteClass, deleteClassState] = useUpdateStaffMutation();

  const handleDeleteSingleClass = () => {
    const classToDelete = selectedStaffSingleClass;
    const currentClasses = selectedStaffAllClasses?.classIds || [];

    const updatedClasses = currentClasses.filter(
      (classId) => classId !== classToDelete?._id,
    );

    const data = {
      classIds: updatedClasses,
    };

    deleteClass({ data, id: selectedStaffAllClasses._id })
      .unwrap()
      .then((_) => {
        toastify.success("Class removed");
        setConfirmRemoveSingle(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleDeleteAllClasses = () => {
    const data = { classIds: [] };

    deleteClass({ data, id: selectedStaffAllClasses._id })
      .unwrap()
      .then((_) => {
        toastify.success("Removed all classes");
        setConfirmRemoveAll(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleDeleteSingleClass,
    handleDeleteAllClasses,
    deleteClassState,
  };
};

export default useAssignClassToTeacher;
