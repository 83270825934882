/** eslint-disable no-unneeded-ternary  */

import { Checkbox } from "@radix-ui/themes";

import {
  TableWrapper,
  THead,
  TRow,
  TableLoader,
  TableNoData,
} from "Component/Table";
import Actions from "../Manage/Actions";
import Avatar from "Component/Avatar";
import StatusIdentifier from "Component/StatusIdentifier";

const StaffDeskTopView = ({ useSelectApplicantStore, staffState }) => {
  const { selectedItems, handleItemSelection, handleSelectAll, selectAll } =
    useSelectApplicantStore();

  return (
    <div>
      <TableWrapper>
        <table className="w-full">
          <THead className="w-full">
            <td className="flex justify-center py-5 min-w-[2%]">
              <Checkbox
                checked={selectAll}
                // onCheckedChange={(check) => handleSelectAll(check, applicants)}
              />
            </td>
            <td className="w-[2%]">S/N</td>

            <td className="w-[35%] px-2">Name</td>
            <td className="w-[30%] px-2">ID Number</td>
            <td className="w-[30%] px-2">Status</td>
            <td className="w-[2%] pr-2 ">Actions</td>
          </THead>
          {!staffState.isFetching ? (
            <tbody>
              {staffState?.data?.staff.length !== 0 ? (
                staffState?.data?.staff.map((data, index) => (
                  <TRow key={index}>
                    <td className="px-6 py-5">
                      <Checkbox
                      // checked={selectedItems.some(
                      //   (id) => id === applicant?._id,
                      // )}
                      // onCheckedChange={(check) =>
                      //   handleItemSelection(check, applicant)
                      // }
                      />
                    </td>

                    <td className="px-6 py-5">{index + 1}</td>
                    <td className="flex gap-2 items-center py-4 px-2">
                      <Avatar image={null} />
                      <div className="flex flex-col">
                        <span className="text-[12] capitalize">
                          {`${data?.firstName} ${data?.middleName || ""} ${data?.lastName}`}
                        </span>
                      </div>
                    </td>
                    <td className="px-2">{data?.staffNumber}</td>

                    <td className="px-2">
                      <StatusIdentifier
                        status={data?.isActive ? "active" : "suspended"}
                      />
                    </td>

                    <td>
                      <Actions data={data} />
                    </td>
                  </TRow>
                ))
              ) : (
                <TableNoData colSpan={9} />
              )}
            </tbody>
          ) : (
            <TableLoader colSpan={9} />
          )}
        </table>
      </TableWrapper>
    </div>
  );
};

export default StaffDeskTopView;
