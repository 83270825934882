import admissionApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const admissionEntryRequirement = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    getEntryRequirements: builder.query({
      query: () => ({
        url: `/entry-requirements/schools/${getSchoolId()}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ADMISSION_ENTRY_REQUIREMENT,
                id,
              })),
              { type: tags.ADMISSION_ENTRY_REQUIREMENT, id: "LIST" },
            ]
          : [{ type: tags.ADMISSION_ENTRY_REQUIREMENT, id: "LIST" }],
    }),

    getEntryRequirement: builder.query({
      query: (id) => ({
        url: `/entry-requirements/${id}`,
      }),
      providesTags: (result, error, id) => [
        { type: tags.ADMISSION_ENTRY_REQUIREMENT, id },
      ],
    }),

    createEntryRequirement: builder.mutation({
      query: (data) => ({
        url: `/entry-requirements`,
        method: "POST",
        body: { ...data, schoolId: getSchoolId() },
      }),
      invalidatesTags: [{ type: tags.ADMISSION_ENTRY_REQUIREMENT, id: "LIST" }],
    }),

    editEntryRequirement: builder.mutation({
      query: ({ data, id }) => ({
        url: `/entry-requirements/${id}`,
        method: "PUT",
        body: { ...data, schoolId: getSchoolId() },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.ADMISSION_ENTRY_REQUIREMENT, id: arg._id },
      ],
    }),

    deleteEntryRequirement: builder.mutation({
      query: (id) => ({
        url: `/entry-requirements/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.ADMISSION_ENTRY_REQUIREMENT, id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetEntryRequirementsQuery,
  useGetEntryRequirementQuery,
  useCreateEntryRequirementMutation,
  useDeleteEntryRequirementMutation,
  useEditEntryRequirementMutation,
} = admissionEntryRequirement;
