import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useAuthStore, {
  AuthModalPages,
} from "Screens/SchoolWebpages/hooks/useAuthStore";
import { useLoginMutation } from "Network/admission/auth/auth";
import { userRole } from "Utils/constants";

const Login = () => {
  const { setPage, setLoading, setOpen } = useAuthStore();
  const [loginUser, { isLoading }] = useLoginMutation();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = (values) => {
    const data = {
      ...values,
      role: userRole.PARENT,
    };
    setLoading(true);

    loginUser(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem("parent_token", res.accessToken);
        localStorage.setItem("parent_refresh_token", res.refreshToken);

        toastify.success("Login successfully");
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err.data.message);
        setLoading(false);
      });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="flex flex-col gap-4 w-full min-h-[30vh] mt-3">
          <Input
            withTitle
            title="Email"
            placeholder="Enter email"
            name="email"
          />

          <Input
            withTitle
            title="Password"
            placeholder="Enter password"
            name="password"
            type="password"
          />

          <div className="flex justify-between sm:gap-10">
            <Button
              disabled={isLoading}
              handleSubmit={() => setOpen(false)}
              title="Close"
              variant="outline"
              className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
            />

            <Button
              isLoading={isLoading}
              handleSubmit={props.handleSubmit}
              title="Login"
              className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
            />
          </div>

          <p
            className="text-center text-[14px] text-primary font-semibold cursor-pointer"
            onClick={() =>
              !isLoading && setPage(AuthModalPages.FORGET_PASSWORD)
            }
          >
            forgot password?
          </p>

          <p
            className="text-center text-[14px] font-semibold"
            onClick={() => !isLoading && setPage(AuthModalPages.SIGNUP)}
          >
            Dont have an account yet?{" "}
            <span className="text-primary cursor-pointer">Create account</span>
          </p>
        </div>
      )}
    </Formik>
  );
};

export default Login;
