import axios from "axios";

export const uploadFile = async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_FILE_SERVICE_URL}/files/upload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    },
  );
  return response;
};
