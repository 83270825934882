import Skeleton from "react-loading-skeleton";

import Avatar from "Component/Avatar";
import Button from "Component/Forms/Button";

const SummaryCard = ({ studentState }) => {
  if (studentState.isFetching) {
    return (
      <div className="w-[20%] h-[400px]">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2 bg-white p-4 items-center sm:text-[13px] rounded-md w-[20%] sm:w-full h-fit">
      <Avatar
        className="w-[200px] h-[200px] sm:w-[100px] sm:h-[100px]"
        image={studentState?.data?.photo}
      />
      <p className="font-bold text-center">
        {`${studentState?.data?.firstName} ${studentState?.data?.middleName || ""} ${studentState?.data?.lastName}`}
      </p>
      <p className="font-bold text-gray-500">
        {studentState?.data?.studentNumber}
      </p>
      <p>{studentState?.data?.section?.name}</p>
      <p>{studentState?.data?.class?.name}</p>
      <Button disabled title="Reset Password" />
    </div>
  );
};

export default SummaryCard;
