import { useState } from "react";

import CustomModal from "Component/Forms/CustomModal";
import Select from "Component/Forms/Select";
import Button from "Component/Forms/Button";

const BulkSelection = ({ open, setOpen }) => {
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const sectionList = [
    { value: "primary", label: "Primary" },
    { value: "jss", label: "JSS" },
  ];

  const classList = [
    { value: "primary1", label: "Primary 1" },
    { value: "jss1", label: "JSS 1" },
  ];
  return (
    <CustomModal
      open={open}
      handleClose={() => setOpen(false)}
      title="Bulk Selection"
      className="w-[35%] max-w-[70%] sm:w-[95%] sm:max-w-[95%] max-h-[90%] sm:max-h-[95%] overflow-y-auto"
    >
      <div className="mt-3 flex flex-col gap-5">
        <Select
          withLabel
          label="Section"
          selectMultiple
          data={sectionList}
          value={selectedSection}
          onChangeOption={(x) => {
            console.log(x);
            setSelectedSection(x);
          }}
        />

        <Select
          withLabel
          label="Classes"
          selectMultiple
          data={classList}
          value={selectedClasses}
          onChangeOption={(x) => {
            console.log(x);
            setSelectedClasses(x);
          }}
        />

        <Select
          withLabel
          label="Categories"
          selectMultiple
          data={classList}
          value={selectedClasses}
          onChangeOption={(x) => {
            console.log(x);
            setSelectedClasses(x);
          }}
        />

        <div className="flex flex-col gap-3 items-center justify-center bg-[#eaf0f6] text-md h-[100px] px-2">
          <p className="text-slate-700">Total Selected Students</p>
          <p className="font-semibold">1000</p>
        </div>

        <div className="border-1 rounded-md p-2">
          <p className="text-slate-500 text-sm">Choose an action to perform</p>

          <div className="flex flex-col items-start gap-4 mt-4">
            <RadioButton label="Allocate Subject" value="allocate-subject" />
            <RadioButton
              label="Activate/Deactivate"
              value="activate-deactivate"
            />
            <RadioButton label="Move to another class" value="move-to-class" />
            <RadioButton label="Reset Password" value="reset-password" />
          </div>
        </div>

        <Button
          handleSubmit={() => setOpen(false)}
          title="Cancel"
          className="w-fit text-[12px] py-2 px-4 font-medium"
          variant="outline"
        />
      </div>
    </CustomModal>
  );
};

export default BulkSelection;

const RadioButton = ({ label, value, onChange = () => {} }) => {
  return (
    <div className="flex gap-4 justify-center ">
      <input
        type="radio"
        name="action"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <p className="text-[13px] sm:text-xs">{label}</p>
    </div>
  );
};
