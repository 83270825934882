import { create } from "zustand";
import { genderList } from "Utils/constants";

const initialState = {
  selectedGender: genderList[0],
  selectedApplication: { value: null, label: "Select Application" },
  selectedClass: { value: null, label: "Select Class" },
};

const useCreateApplicantStore = create((set) => ({
  ...initialState, // Spread initial state to set default values

  setSelectedGender: (gender) => set({ selectedGender: gender }),

  setSelectedApplication: (application) =>
    set({ selectedApplication: application }),

  setSelectedClass: (classValue) => set({ selectedClass: classValue }),
  resetSelectedClass: () => set({ selectedClass: initialState.selectedClass }),

  resetState: () => set({ ...initialState }), // Reset to initial state
}));

export default useCreateApplicantStore;
