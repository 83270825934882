import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import useCreateRole from "./hooks/useCreateRole";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";

const CreateRole = ({ open, setOpen }) => {
  const { initialValues, validationSchema, handleSubmit, createRoleState } =
    useCreateRole();

  return (
    <CustomModal
      title="Create Role"
      open={open}
      handleClose={() => !createRoleState.isLoading && setOpen()}
      className="min-w-[30%]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-3 mt-5">
            <Input
              name="name"
              withTitle
              title="Role Name"
              placeholder="e.g. Admin"
            />

            <Input
              name="description"
              withTitle
              title="Description"
              placeholder="e.g. Management"
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[25%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Create Role"
                handleSubmit={props.handleSubmit}
                isLoading={createRoleState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateRole;
