import { configureStore } from "@reduxjs/toolkit";
import AppReducer from "./AppSlice";
import parentApi from "Network/parentNetwork/apiSlice";
import userMangementAPi from "Network/userManagement/apiSlice";
import admissionApi from "Network/admission/apiSlice";
import academicsApi from "Network/academics/apiSlice";

export const Store = configureStore({
  reducer: {
    [userMangementAPi.reducerPath]: userMangementAPi.reducer,
    [parentApi.reducerPath]: parentApi.reducer,
    [admissionApi.reducerPath]: admissionApi.reducer,
    [academicsApi.reducerPath]: academicsApi.reducer,
    appSlice: AppReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userMangementAPi.middleware,
      parentApi.middleware,
      admissionApi.middleware,
      academicsApi.middleware,
    ),
});
