import { create } from "zustand";
import { genderList } from "Utils/constants";

const initialState = {
  selectedGender: genderList[0],
  selectedQualification: { value: null, label: "Select Qualification" },
};

const useCreateStaffStore = create((set) => ({
  ...initialState, // Spread initial state to set default values

  setSelectedGender: (gender) => set({ selectedGender: gender }),

  setSelectedQualification: (qualification) =>
    set({ selectedQualification: qualification }),

  resetState: () => set({ ...initialState }), // Reset to initial state
}));

export default useCreateStaffStore;
