import { useState, useMemo, useEffect } from "react";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import { useGetSessionsQuery } from "Network/admission/configurations/session";
import { useGetApplicationsQuery } from "Network/admission/applications";
import {
  useApplicantsCountMutation,
  useMoveApplicantsToAcademicsMutation,
} from "Network/admission/applicants";

const useBulkSelection = () => {
  const sessions = useGetSessionsQuery();

  const [getApplicantsCount, applicantsCountState] =
    useApplicantsCountMutation();

  const [moveApplicantsToAcademics, moveApplicantsToAcademicsState] =
    useMoveApplicantsToAcademicsMutation();

  const [selectedSession, setSelectedSession] = useState({
    label: "Select Session",
    value: null,
  });

  const [selectedClass, setSelectedClass] = useState([]);

  const [selectedApplication, setSelectedApplication] = useState({
    label: "Select Application",
    value: null,
  });

  const applications = useGetApplicationsQuery(
    {
      page: undefined,
      published: true,
      sessionId: selectedSession?.value,
    },
    {
      skip: sessions.isLoading || selectedSession.value === null,
    },
  );

  const singleApplication = useMemo(() => {
    return (
      selectedApplication &&
      applications?.data?.data?.find(
        (application) => application?._id === selectedApplication.value,
      )
    );
  }, [selectedApplication, applications]);

  useEffect(() => {
    if (selectedClass.length > 0) {
      getApplicantsCount({
        applicationId: selectedApplication.value,
        sectionId: singleApplication?.section?._id,
        classIds: selectedClass.map((c) => c.value),
      });
    }
  }, [selectedClass]);

  useEffect(() => {
    setSelectedApplication({
      label: "Select Application",
      value: null,
    });
    applicantsCountState.reset();
  }, [selectedSession]);

  useEffect(() => {
    setSelectedClass([]);
    applicantsCountState.reset();
  }, [selectedApplication]);

  const handleMoveApplicantsToAcademics = () => {
    moveApplicantsToAcademics({
      applicationId: selectedApplication.value,
      classIds: selectedClass.map((c) => c.value),
    })
      .unwrap()
      .then(() => {
        toast.success("Applicants moved to academics successfully");
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleMoveApplicantsToAcademicsByApplicantIds = (applicantIds) => {
    moveApplicantsToAcademics({
      applicantIds,
    })
      .unwrap()
      .then(() => {
        toastify.success("Applicants moved to academics successfully");
      })
      .catch((err) => {
        if (err.status === 400) {
          toastify.error(err.data?.message);
        } else {
          toastify.error("Something went wrong");
        }
      });
  };

  return {
    sessions,
    selectedSession,
    setSelectedSession,

    applications,
    selectedApplication,
    setSelectedApplication,

    selectedClass,
    setSelectedClass,

    singleApplication,
    applicantsCountState,

    handleMoveApplicantsToAcademics,
    moveApplicantsToAcademicsState,
    handleMoveApplicantsToAcademicsByApplicantIds,
  };
};

export default useBulkSelection;
