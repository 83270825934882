import { useState } from "react";
import dayjs from "dayjs";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import MainLayout from "Component/Layout/MainLayout";
import {
  useGetSchoolFormsWithPaginationQuery,
  useDeleteFormMutation,
} from "Network/admission/configurations/formBuilder";
import Button from "Component/Forms/Button";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";
import ConfirmDelete from "Component/ConfirmDelete";
import Pagination from "Component/Pagination/Pagination";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";
import CustomSearch from "Component/CustomSearch";

const FormBuilderList = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { page, setPage, search, handleSearch } = usePaginationAndSearch();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const queryResult = useGetSchoolFormsWithPaginationQuery({ page, search });
  const [deleteForm, deleteFormState] = useDeleteFormMutation();

  const handleChangePage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleOpenConfirmDelete = (item) => {
    setSelectedItem(item);
    setOpenConfirmDelete(true);
  };

  const handleDelete = () => {
    deleteForm({ id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Form deleted successfully");
        setOpenConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };
  return (
    <MainLayout>
      <div className="flex flex-col gap-5 h-full">
        <Button
          className="w-fit text-[12px] h-[41px] self-end"
          title="View Forms"
          handleSubmit={() =>
            navigate(
              `/${params.shortName}/${admissionFlattenRoutes.formBuilder}`,
            )
          }
        />

        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-5">
            <CustomSearch
              className="w-[40%] sm:w-[100%] text-[14px] bg-white shadow-sm"
              placeholderText="Search by form name"
              handleChange={(e) => handleSearch(e.target.value)}
            />
            {!queryResult.isFetching ? (
              queryResult?.data?.data?.length > 0 ? (
                <div className="grid grid-cols-3 gap-5">
                  {queryResult?.data?.data?.map((data, index) => (
                    <Card
                      key={index}
                      data={data}
                      onDelete={() => handleOpenConfirmDelete(data)}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex justify-center w-full">No form found</div>
              )
            ) : (
              <CardLoader />
            )}
          </div>

          <div className="self-end mt-3">
            <Pagination
              onPageChange={handleChangePage}
              pageTotal={queryResult?.data?.totalPages}
              initialPage={queryResult?.data?.currentPage}
            />
          </div>
        </div>
      </div>

      <ConfirmDelete
        openConfirmDelete={openConfirmDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
        title="Form Builder"
        deleteName={selectedItem?.name}
        isLoading={deleteFormState.isLoading}
        handleDelete={handleDelete}
      />
    </MainLayout>
  );
};

export default FormBuilderList;

const Card = ({ data, onDelete = () => {} }) => {
  return (
    <div className="border-1 h-[200px] rounded-md flex flex-col justify-between p-2 bg-white">
      <p className="font-medium">{data?.name}</p>

      <div className="flex justify-between items-center">
        <p className="text-[12px]">
          <span className="font-medium">Created on:</span>{" "}
          <span>{dayjs(data?.createdAt).format("ddd MMM YYYY")}</span>
        </p>
        <MdOutlineDeleteOutline
          className=" text-2xl text-red-600 cursor-pointer"
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

const CardLoader = () => {
  return (
    <div className="grid grid-cols-3 gap-5">
      {Array.from({ length: 9 }).map((_, index) => (
        <Skeleton key={index} height={200} containerClassName="w-full" />
      ))}
    </div>
  );
};
