export const transformClassOption = (data) => {
  return (
    data?.map((item) => ({
      value: item?._id,
      label: item?.name,
      smallLabel: item?.category?.name
        ? item?.category?.name?.toLowerCase()
        : undefined,
    })) || []
  );
};

export const transformSelectOption = (data, key = "name") => {
  return (
    data?.map((item) => ({
      value: item?._id,
      label: item[key],
    })) || []
  );
};

export const transformStaffOption = (data) => {
  return (
    data?.map((item) => ({
      value: item?._id,
      label: item?.staffNumber,
      smallLabel: item?.firstName
        ? `${item?.firstName?.toLowerCase()} ${item?.lastName?.toLowerCase()}`
        : undefined,
    })) || []
  );
};
