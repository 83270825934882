import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";

import { useLoginMutation } from "Network/userManagement/auth";
import { academicFlattenRoutes } from "Routes/academicRoutes";
import useToggleActiveAppStore from "hooks/useToggleActiveAppStore";
import { useGetSchoolInfoQuery } from "Network/userManagement/profile";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  schoolShortName: Yup.string().required("Short name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const useLogin = () => {
  const navigate = useNavigate();

  const { shortName } = useParams();
  const { setActiveApp } = useToggleActiveAppStore();

  const [login, loginState] = useLoginMutation();

  const infoState = useGetSchoolInfoQuery(shortName);

  const initialValues = {
    email: "",
    password: "",
    schoolShortName: shortName,
  };

  const handleLogin = (values) => {
    const body = {
      identifier: values.email,
      password: values.password,
      refreshTokenExpiry: "7d",
      organisationId: infoState?.data?._id,
    };
    login(body)
      .unwrap()
      .then((res) => {
        localStorage.setItem("school_token", res.accessToken);
        localStorage.setItem("school_refresh_token", res.refreshToken);
        localStorage.setItem(
          "school_decode_token",
          JSON.stringify({
            id: res.user?._id,
            shortName,
            schoolId: infoState?.data?._id,
          }),
        );

        // TODO: check if user is verified
        // if (!res.result?.user?.emailVerified) {
        //   toast.error("Your account is not verified");
        //   navigate(`/${shortName}/resend-verify-email`);
        //   return;
        // }
        setActiveApp(false);
        navigate(`/${shortName}/${academicFlattenRoutes.dashboard}`);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };

  return {
    loading: loginState.isLoading,
    handleLogin,
    initialValues,
    validationSchema,
  };
};

export default useLogin;
