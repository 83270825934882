export const tags = {
  TEMPLATE_CONTROL_TAG: "templateControls",
  PAYMENT_CONTROL_TAG: "paymentControls",
  BANK_CONTROL_TAG: "bankControls",
  MY_PROFILE_CONTROL_TAG: "myprofile",
  SCHOOL_PROFILE_CONTROL_TAG: "schoolprofile",
  SCHOOL_SECTIONS: "schoolsections",
  SCHOOL_SESSIONS: "schoolsessions",
  SCHOOL_CATEGORIES: "schoolcategories",
  SCHOOL_CLASS: "schoolclass",
  SCHOOL_SUPPORTING_DOCUMENT: "schoolsupportingdocument",
  SCHOOL_APPLICATIONS: "schoolapplications",
  SCHOOL_APPLICANTS: "schoolapplicants",
  ADMISSION_ENTRY_REQUIREMENT: "admissionentryrequirement",
  ATTACHMENT: "attachment",
  FORM_BUILDER: "formbuilder",
  ID_MANAGEMENT: "idmanagement",
  USER_MANAGEMENT_ROLE: "usermanagementrole",
  USER_ROLES: "userroles",
  USERS: "users",
  ROLE_PERMISSIONS: "rolepermissions",

  ACADEMICS_TERMS: "academicterms",
  ACADEMICS_SESSIONS: "academicsessions",
  ACADEMICS_CURRENT_SESSION_TERM: "academicscurrentsessionterm",
  ACADEMICS_SECTIONS: "academicssections",
  ACADEMICS_CATEGORIES: "academicscategories",
  ACADEMICS_CLASS: "academicsclass",
  ACADEMICS_SUBJECTS: "academicsubjects",
  ACADEMICS_CURRICULUM: "academicscurriculum",
  ACADEMICS_STUDENT: "academicsstudent",
  ACADEMICS_GRADE_SCALE: "academicsgradescale",
  ACADEMICS_ASSESSMENT_COMPONENT: "academicsassessmentcomponent",
  ACADEMICS_SCORE: "academicscore",
  ACADEMICS_STAFF: "academicsstaff",
};

export const PERMISSIONS_LISTS = {
  ORGANISATION_ADMIN: "org_admin",
  ADMIN: "admin",
  CLASS_TEACHER: "class_teacher",
  SUBJECT_TEACHER: "subject_teacher",
};

export const userRole = {
  ADMIN: "super_admin",
  PARENT: "parent",
};

export const IMAGE_SIZE = 300 * 1024;

export const roles = {
  SUPER_ADMIN: "super_admin",
  PARENT: "parent",
};

export const currentApp = {
  ACADEMICS: "academic",
  ADMISSION: "admission",
};

export const admissionTemplateTabs = ["Template 1"];

export const countryList = [{ value: "nigeria", label: "Nigeria" }];
export const localGovernmentList = [
  { value: "yola north", label: "Yola North" },
];
export const stateList = [{ value: "adamawa", label: "Adamawa" }];

export const genderList = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const qualificationList = [
  { value: "diploma", label: "Diploma" },
  { value: "nce", label: "NCE" },
  { value: "hnd", label: "HND" },
  { value: "bachelors", label: "Bachelors" },
  { value: "masters", label: "Masters" },
];

export const termList = [
  { value: "1", label: "First Term" },
  { value: "2", label: "Second Term" },
  { value: "3", label: "Third Term" },
];

export const paymentOptions = [
  { value: "offline", label: "Offline" },
  { value: "online", label: "Online" },
];

export const CURRENCY_SYMBOL = "₦";

export const sessionList = [
  { value: "2023/2024", label: "2023/2024" },
  { value: "2024/2025", label: "2024/2025" },
  { value: "2025/2026", label: "2025/2026" },
];

export const statusConstant = {
  pending: "pending",
  admitted: "admitted",
  successful: "successful",
  declined: "declined",
  failed: "failed",
  published: "published",
  notPublish: "notPublish",
  active: "active",
  suspended: "suspended",
};

export const filesAllowed = ["jpeg", "png", "jpg"];

export const MOBILE_SCREEN_WIDTH = 639;

export const schoolFeaturesEnum = {
  ADMISSION: "admission",
  ACADEMICS: "academic",
};

export const IntialsConstants = {
  student: "Student",
  staff: "Staff",
  admin: "Admin",
};
