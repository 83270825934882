import { CircularProgress } from "@mui/material";
import cn from "../../Utils/cn";
import { cva } from "class-variance-authority";

const buttonVariants = cva(
  "flex gap-2 items-center justify-center hover:bg-opacity-[0.7] px-2 py-1 cursor-pointer rounded-sm",
  {
    variants: {
      variant: {
        default: "bg-primary text-white",
        outline:
          "text-[black] border-1 border-primary bg-transparent hover:opacity-[0.7]",
        text: "bg-transparent hover:bg-[#e8f4ff] text-primary ",
        icon: "bg-transparent hover:bg-[#e8f4ff] text-primary rounded-full p-2 cursor-pointer",
      },
    },

    defaultVariants: {
      variant: "default",
    },
  },
);

const Button = ({
  title = "",
  className = "",
  Icon,
  iconSize = 18,
  isLoading = false,
  handleSubmit = () => {},
  disabled = false,
  variant = "default",
}) => {
  return (
    <button
      disabled={isLoading || disabled}
      type="button"
      onClick={handleSubmit}
      className={cn(
        buttonVariants({ variant, className }),
        (isLoading || disabled) && "opacity-[0.6] cursor-not-allowed",
      )}
    >
      {!isLoading ? (
        <div className="flex items-center">
          {typeof Icon === "string" ? <Icon size={iconSize} /> : Icon}
          <div>{title}</div>
        </div>
      ) : (
        <CircularProgress size={24} />
      )}
    </button>
  );
};

export default Button;
