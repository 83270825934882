import { RxDashboard } from "react-icons/rx";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { FiUsers, FiSettings } from "react-icons/fi";

import Dashboard from "Screens/AdmissionScreen/Dashboard";
import Applicant from "Screens/AdmissionScreen/Applicants";
import Applications from "Screens/AdmissionScreen/Applications";
import ApplicantsPaymentVerification from "Screens/AdmissionScreen/ApplicantsPaymentVerification";
import CreateApplicant from "Screens/AdmissionScreen/Applicants/Manage/CreateApplicant";
import CreateApplications from "Screens/AdmissionScreen/Applications/Manage/CreateApplications";
import ApplicantDetails from "Screens/AdmissionScreen/Applicants/View/ApplicantDetails";
import AdmissionTemplate from "Screens/AdmissionScreen/Settings/AdmissionTemplate";
import SupportingDocument from "Screens/AdmissionScreen/Settings/SupportingDocument";
import AdmissionEntryRequirement from "Screens/AdmissionScreen/Settings/AdmissionEntryRequirement";
import FormBuilder from "Screens/AdmissionScreen/Settings/FormBuilder";
import FormBuilderList from "Screens/AdmissionScreen/Settings/FormBuilder/view/FormBuilderList";
import BankControl from "Screens/AdmissionScreen/Settings/BankControl";
import SessionControl from "Screens/AdmissionScreen/Settings/SessionControl";
import Section from "Screens/AdmissionScreen/Settings/Section";
import Category from "Screens/AdmissionScreen/Settings/Category";
import AttachmentDocument from "Screens/AdmissionScreen/Settings/AttachmentDocument";
import Classes from "Screens/AdmissionScreen/Classes";
import ClassList from "Screens/AdmissionScreen/Classes/View/ClassList";
import { Icon } from "Routes/utils/component";
import { currentApp } from "Utils/constants";

const route = currentApp.ADMISSION;

const admissionRoutesList = [
  {
    type: "collapse",
    name: "Dashboard",
    key: `dashboard`,
    route: `${route}/dashboard`,
    icon: <Icon Name={RxDashboard} />,
    noCollapse: true,
    sidenav: true,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Applicants",
    key: `applicants`,
    route: `${route}/applicants`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    sidenav: true,
    component: <Applicant />,
  },
  {
    type: "collapse",
    name: "Create Applicants",
    key: `create-applicant`,
    route: `${route}/applicants/create-applicant`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    component: <CreateApplicant />,
  },
  {
    type: "collapse",
    name: "Applicant Details",
    key: `applicants/:id`,
    route: `${route}/applicants/:id`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    component: <ApplicantDetails />,
  },
  {
    type: "collapse",
    name: "Applications",
    key: `applications`,
    route: `${route}/applications`,
    icon: <Icon Name={HiOutlineClipboardDocumentCheck} />,
    noCollapse: true,
    sidenav: true,
    component: <Applications />,
  },
  {
    type: "collapse",
    name: "Create Applications",
    key: `create-applications`,
    route: `${route}/applications/create-applications`,
    icon: <Icon Name={HiOutlineClipboardDocumentCheck} />,
    noCollapse: true,
    component: <CreateApplications />,
  },
  {
    type: "collapse",
    name: "Payment Verification",
    key: `payment-verification`,
    route: `${route}/payment-verification`,
    icon: <Icon Name={HiOutlineClipboardDocumentCheck} />,
    noCollapse: true,
    sidenav: true,
    component: <ApplicantsPaymentVerification />,
  },

  {
    type: "collapse",
    name: "Configurations",
    key: "configurations",
    icon: <Icon Name={FiSettings} />,
    sidenav: true,
    collapse: [
      {
        name: "Classes",
        key: "class",
        route: `${route}/configurations/class`,
        component: <Classes />,
      },
      {
        name: "Section",
        key: "section",
        route: `${route}/configurations/section`,
        component: <Section />,
      },
      {
        name: "Category",
        key: "category",
        route: `${route}/configurations/category`,
        component: <Category />,
      },

      {
        name: "Attachment Document",
        key: "attachment-document",
        route: `${route}/configurations/attachment-document`,
        component: <AttachmentDocument />,
      },

      {
        name: "Supporting Document",
        key: "supporting-document",
        route: `${route}/configurations/supporting-document`,
        component: <SupportingDocument />,
      },
      {
        name: "Entry Requirement",
        key: "entry-requirement",
        route: `${route}/configurations/entry-requirement`,
        component: <AdmissionEntryRequirement />,
      },

      {
        name: "Admission Template",
        key: "admissions-template",
        route: `${route}/configurations/admissions-template`,
        component: <AdmissionTemplate />,
      },

      {
        name: "Form Builder",
        key: "form-builder",
        route: `${route}/configurations/form-builder`,
        component: <FormBuilder />,
      },

      {
        name: "Session Configuration",
        key: "session",
        route: `${route}/configurations/session`,
        component: <SessionControl />,
      },

      {
        name: "Bank Control",
        key: "bank",
        route: `${route}/configurations/bank`,
        component: <BankControl />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Form Builder",
    key: "form-builder-list",
    route: `${route}/configurations/form-builder/form-list`,
    noCollapse: true,
    component: <FormBuilderList />,
  },

  {
    type: "collapse",
    name: "class-list",
    key: `classList`,
    route: `${route}/configurations/class-list`,
    noCollapse: true,
    component: <ClassList />,
  },
];

export default admissionRoutesList;
