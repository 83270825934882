import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import { useCreateAssessmentMutation } from "Network/academics/gradeManagement/assessmentComponentAcademics";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Format name is required"),
  assessments: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Assessment type is required"),
        maxValue: Yup.number()
          .required("Value is required")
          .min(0, "Score cannot be negative"),
      }),
    )
    .min(1, "At least one assessment is required"),
});

const useCreateAssessment = ({ setOpen }) => {
  const [createAssessment, createAssessmentState] =
    useCreateAssessmentMutation();
  const initialValues = {
    name: "",
    assessments: [
      {
        name: "",
        maxValue: "",
      },
    ],
  };
  const handleSubmit = (values, actions) => {
    createAssessment(values)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Assessment created");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return {
    initialValues,
    handleSubmit,
    validationSchema,

    createAssessmentState,
  };
};

export default useCreateAssessment;
