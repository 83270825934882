import academicsApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "assessment-structures";

const assessmentComponentAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssessments: builder.query({
      query: () => ({
        url: `/${endpoint}/organisation/${getSchoolId()}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_ASSESSMENT_COMPONENT,
                id,
              })),
              { type: tags.ACADEMICS_ASSESSMENT_COMPONENT, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_ASSESSMENT_COMPONENT, id: "LIST" }],
    }),

    getAssessment: builder.query({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
      }),
      providesTags: (result, error, id) => [
        { type: tags.ACADEMICS_ASSESSMENT_COMPONENT, id },
      ],
    }),

    createAssessment: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [
        { type: tags.ACADEMICS_ASSESSMENT_COMPONENT, id: "LIST" },
      ],
    }),

    deleteAssessment: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: tags.ACADEMICS_ASSESSMENT_COMPONENT, id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetAssessmentsQuery,
  useGetAssessmentQuery,
  useCreateAssessmentMutation,
  useDeleteAssessmentMutation,
} = assessmentComponentAcademics;
