import { getSchoolId, getUserId } from "Network/utils";
import userMangementAPi from "./apiSlice";
import { tags } from "Utils/constants";

const profile = userMangementAPi.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query({
      query: () => ({
        url: `/users/${getUserId()}`,
      }),
      providesTags: [tags.MY_PROFILE_CONTROL_TAG],
    }),

    getSchoolProfile: builder.query({
      query: () => ({
        url: `/organisations/${getSchoolId()}`,
      }),
      providesTags: [tags.SCHOOL_PROFILE_CONTROL_TAG],
    }),

    getSchoolInfo: builder.query({
      query: (shortName) => ({
        url: `/organisations/${shortName}/basic-info`,
      }),
      invalidatesTags: [tags.SCHOOL_PROFILE_CONTROL_TAG],
    }),

    editMyProfile: builder.mutation({
      query: (data) => ({
        url: `/users/${getUserId()}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [tags.MY_PROFILE_CONTROL_TAG],
    }),

    editSchoolProfile: builder.mutation({
      query: (data) => ({
        url: `/organisations/${getSchoolId()}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [tags.SCHOOL_PROFILE_CONTROL_TAG],
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useGetSchoolProfileQuery,
  useGetSchoolInfoQuery,
  useEditMyProfileMutation,
  useEditSchoolProfileMutation,
} = profile;
