import Header from "./Header";
import Footer from "Component/Footer";
import { useGetSchoolInfoQuery } from "Network/userManagement/profile";
import { LoadingPage } from "Component/Layout/PreLayout";
import { useParams } from "react-router-dom";
import NotFoundPage from "Component/NotFoundPage";

const Layout = ({ children }) => {
  const { shortName } = useParams();
  const schoolState = useGetSchoolInfoQuery(shortName);

  if (schoolState.isLoading) {
    return <LoadingPage />;
  }

  if (schoolState.error) return <NotFoundPage />;

  return (
    <div className="mx-auto sm:mx-0 sm:min-w-[100vw] min-w-[850px] max-w-[1500px] relative ">
      <Header schoolLogo={schoolState.data?.image} />
      <div className=" sm:mt-[50px] mt-[79px] min-h-[100vh]  flex flex-col justify-between bg-main-bg">
        <div>{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
