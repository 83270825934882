import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "staff";

const staffAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaff: builder.query({
      query: ({ page, search, limit }) => {
        const url = `/${endpoint}/organisation/${getSchoolId()}`;

        const params = { limit };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        return { url, params };
      },

      providesTags: (result) =>
        result
          ? [
              ...result.staff.map(({ id }) => ({
                type: tags.ACADEMICS_STAFF,
                id,
              })),
              { type: tags.ACADEMICS_STAFF, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_STAFF, id: "LIST" }],
    }),

    getSingleStaff: builder.query({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: tags.ACADEMICS_STAFF, id }],
    }),

    getStaffByUserId: builder.query({
      query: (userId) => ({
        url: `/${endpoint}/users/${userId}`,
      }),
      providesTags: (result, error, id) => [{ type: tags.ACADEMICS_STAFF, id }],
    }),

    getSingleUser: builder.query({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: tags.ACADEMICS_STAFF, id }],
    }),

    createStaff: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          user: {
            ...data.user,
            organisationId: getSchoolId(),
          },
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_STAFF, id: "LIST" }],
    }),

    deleteStaff: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_STAFF, id: "LIST" }],
    }),

    updateStaff: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PATCH",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: tags.ACADEMICS_STAFF, id: "LIST" },
        { type: tags.ACADEMICS_STAFF, id },
      ],
    }),

    updateStaffStatus: builder.mutation({
      query: ({ studentIds, status }) => ({
        url: `/${endpoint}/status`,
        method: "PATCH",
        body: {
          studentIds,
          status,
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: tags.ACADEMICS_STAFF, id: "LIST" },
        { type: tags.ACADEMICS_STAFF, id },
      ],
    }),
  }),
});

export const {
  useGetStaffQuery,
  useGetSingleUserQuery,
  useGetStaffByUserIdQuery,
  useGetSingleStaffQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
  useUpdateStaffStatusMutation,
} = staffAcademics;
