import { Formik } from "formik";
import { IoPrintSharp } from "react-icons/io5";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Select from "Component/Forms/Select";
import useGenerateClassReport from "../hooks/useGenerateClassReport";
import { transformSelectOption, transformClassOption } from "Utils/transform";
import Button from "Component/Forms/Button";

const ClassReport = () => {
  const {
    initialValues,
    validationSchema,

    selectedSession,
    setSelectedSession,
    selectedTerm,
    setSelectedTerm,
    selectedSection,
    setSelectedSection,
    selectedClass,
    setSelectedClass,
    selectedReportType,
    setSelectedReportType,

    sessionState,
    termState,
    sectionState,
    classState,

    handleSubmit,
  } = useGenerateClassReport();
  return (
    <MainLayout>
      <BodyHeaderLayout title={"Generate Report Sheet"} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-8 bg-white p-4 rounded-md">
            <div className="grid grid-cols-2 sm:flex sm:flex-col sm:gap-3 gap-5">
              <Select
                label="Session"
                data={transformSelectOption(sessionState?.data)}
                value={selectedSession}
                className={`${props.errors?.sessionId && "border-[red] "}`}
                isLoading={sessionState.isLoading}
                onChangeOption={(x) => {
                  props.setFieldValue("sessionId", x?.value);
                  setSelectedSession(x);
                }}
              />
              <Select
                withLabel
                label="Term"
                data={transformSelectOption(termState?.data)}
                value={selectedTerm}
                className={`${props.errors?.termId && "border-[red] "}`}
                isLoading={termState.isLoading}
                onChangeOption={(x) => {
                  props.setFieldValue("termId", x?.value);
                  setSelectedTerm(x);
                }}
              />
              <Select
                withLabel
                label="Section"
                data={transformSelectOption(sectionState?.data)}
                value={selectedSection}
                className={`${props.errors?.sectionId && "border-[red] "}`}
                isLoading={sectionState.isLoading}
                onChangeOption={(x) => {
                  props.setFieldValue("sectionId", x?.value);
                  setSelectedSection(x);
                }}
              />
              <Select
                withLabel
                label="Class"
                data={transformClassOption(classState?.data?.data)}
                value={selectedClass}
                className={`${props.errors?.classId && "border-[red] "}`}
                isLoading={classState.isLoading}
                onChangeOption={(x) => {
                  props.setFieldValue("classId", x?.value);
                  setSelectedClass(x);
                }}
              />
              <div className="col-span-2">
                <Select
                  withLabel
                  label="Report Type"
                  className="w-full"
                  data={[
                    { label: "Class Report", value: "class" },
                    { label: "Section Report", value: "section" },
                  ]}
                  disabled
                  value={selectedReportType}
                />
              </div>
            </div>

            <Button
              className="self-end"
              Icon={<IoPrintSharp size={24} />}
              variant="icon"
              handleSubmit={props.handleSubmit}
              //   isLoading={createClassState.isLoading}
            />
          </div>
        )}
      </Formik>
    </MainLayout>
  );
};

export default ClassReport;
