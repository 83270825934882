import admissionApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const formBuilder = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    createForm: builder.mutation({
      query: (data) => ({
        url: "/forms",
        method: "POST",
        body: {
          ...data,
          schoolId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.FORM_BUILDER, id: "LIST" }],
    }),

    deleteForm: builder.mutation({
      query: ({ id }) => ({
        url: `/forms/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.FORM_BUILDER, id: arg._id },
      ],
    }),

    getSchoolForms: builder.query({
      query: () => ({
        url: `/forms/schools/${getSchoolId()}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: tags.FORM_BUILDER,
                id,
              })),
              { type: tags.FORM_BUILDER, id: "LIST" },
            ]
          : [{ type: tags.FORM_BUILDER, id: "LIST" }],
    }),

    getSchoolFormsWithPagination: builder.query({
      query: ({ page, search, limit = 10 }) => {
        const url = `/forms/schools/${getSchoolId()}`;
        const params = { limit };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        return { url, params };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: tags.FORM_BUILDER,
                id,
              })),
              { type: tags.FORM_BUILDER, id: "LIST" },
            ]
          : [{ type: tags.FORM_BUILDER, id: "LIST" }],
    }),
  }),
});

export const {
  useCreateFormMutation,
  useGetSchoolFormsQuery,
  useGetSchoolFormsWithPaginationQuery,
  useDeleteFormMutation,
} = formBuilder;
