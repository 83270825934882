import { Formik } from "formik";
import { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";

import CustomModal from "Component/Forms/CustomModal";
import Select from "Component/Forms/Select";
import {
  transformSelectOption,
  transformClassOption,
  transformStaffOption,
} from "Utils/transform";
import useAssignSubjectToTeacher from "../hooks/useAssignSubjectToTeacher";
import Button from "Component/Forms/Button";
import useAssignSubject from "Screens/AcademicScreens/Staff/hooks/useAssignSubject";

const AssignSubjectToTeacher = ({ open, setOpen }) => {
  const [selectedTeacher, setSelectedTeacher] = useState({
    value: null,
    label: "Select Teacher",
  });
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    assignSubjectState,
    subjectState,
    classState,
    staffState,
    setSelectedStaff,
    classPairs,
    addClassPair,
    removeClassPair,
    updateSelectedClass,
    updateSelectedSubjects,
  } = useAssignSubject();
  return (
    <CustomModal
      open={open}
      handleClose={() => !assignSubjectState.isLoading && setOpen(false)}
      className={"min-w-[45%] max-h-[90vh] overflow-y-auto"}
      title="Assign Subjects to Subject Teacher"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-5 w-full mt-5">
            <Select
              withLabel
              label="Teacher"
              data={transformStaffOption(staffState?.data?.staff)}
              value={selectedTeacher}
              isLoading={staffState.isLoading}
              onChangeOption={(x) => {
                setSelectedTeacher(x);
                setSelectedStaff(
                  staffState?.data?.staff.find((each) => each._id === x.value),
                );
              }}
            />
            {classPairs.map((pair, idx) => (
              <div key={pair.index} className="border p-4 rounded-md mb-3">
                <div className="flex items-center justify-end mb-2">
                  {classPairs.length > 1 && (
                    <Button
                      variant="icon"
                      handleSubmit={() => {
                        removeClassPair(pair.index);
                        // Also update Formik values
                        const newSubjects = [...props.values.subjects];
                        newSubjects.splice(idx, 1);
                        props.setFieldValue("subjects", newSubjects);
                      }}
                      Icon={
                        <MdDeleteForever size={20} className="text-red-500" />
                      }
                    />
                  )}
                </div>

                <div className="flex flex-col gap-3">
                  <Select
                    withLabel
                    label="Class"
                    data={transformClassOption(classState?.data?.data)}
                    value={pair.selectedClass}
                    className={`${
                      props.errors?.subjects?.[idx]?.classId && "border-[red] "
                    }`}
                    isLoading={classState.isLoading}
                    onChangeOption={(x) => {
                      // Update local state
                      updateSelectedClass(pair.index, x);
                      // Update Formik values
                      const newSubjects = [...props.values.subjects];
                      if (!newSubjects[idx]) {
                        newSubjects[idx] = { classId: "", subjectIds: [] };
                      }
                      newSubjects[idx].classId = x?.value;
                      props.setFieldValue("subjects", newSubjects);
                    }}
                  />

                  <Select
                    withLabel
                    label="Subjects"
                    data={transformSelectOption(subjectState?.data)}
                    value={pair.selectedSubjects}
                    selectMultiple
                    className={`${
                      props.errors?.subjects?.[idx]?.subjectIds &&
                      "border-[red] "
                    }`}
                    isLoading={subjectState.isLoading}
                    onChangeOption={(x) => {
                      // Update local state
                      updateSelectedSubjects(pair.index, x);
                      // Update Formik values
                      const newSubjects = [...props.values.subjects];
                      if (!newSubjects[idx]) {
                        newSubjects[idx] = { classId: "", subjectIds: [] };
                      }
                      newSubjects[idx].subjectIds = x.map(
                        (eachSubject) => eachSubject?.value,
                      );
                      props.setFieldValue("subjects", newSubjects);
                    }}
                  />
                </div>
              </div>
            ))}

            <div className="w-full">
              <Button
                variant="icon"
                Icon={<IoIosAdd size={25} className="text-[#057877]" />}
                handleSubmit={() => {
                  addClassPair();
                  // Add an empty entry to Formik values
                  const newSubjects = [...props.values.subjects];
                  newSubjects.push({ classId: "", subjectIds: [] });
                  props.setFieldValue("subjects", newSubjects);
                }}
              />
            </div>

            <div className="flex justify-between mt-5 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px]"
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />
              <Button
                className="w-[30%] sm:w-[100%] sm:text-[14px] h-[41px]"
                title="Assign Subjects"
                disabled={selectedTeacher.value === null}
                handleSubmit={props.handleSubmit}
                isLoading={assignSubjectState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AssignSubjectToTeacher;
