import { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { useGetCategoriesQuery } from "Network/academics/categoryAcademics";
import { useGetSectionsQuery } from "Network/academics/sectionAcademics";
import { useCreateClassMutation } from "Network/academics/classAcademics";

const validationSchema = Yup.object().shape({
  className: Yup.string().required("Class name is required"),
  categoryId: Yup.string().required("Category is required"),
  sectionId: Yup.string().required("Section is required"),
});

const useCreateClass = () => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const sectionState = useGetSectionsQuery();
  const categoryState = useGetCategoriesQuery();
  const [createClass, createClassState] = useCreateClassMutation();

  const initialValues = {
    className: "",
    sectionId: "",
    categoryId: "",
  };

  const handleSubmit = (values, actions) => {
    const data = {
      name: values.className,
      sectionId: values.sectionId,
      categoryId: values.categoryId,
    };
    createClass(data)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        setSelectedSection(null);
        setSelectedCategory(null);
        toast.success("Class created");
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    validationSchema,
    initialValues,
    handleSubmit,

    selectedSection,
    setSelectedSection,
    selectedCategory,
    setSelectedCategory,

    sectionState,
    categoryState,
    createClassState,
  };
};

export default useCreateClass;
