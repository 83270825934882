import admissionApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const session = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    getSessions: builder.query({
      query: () => ({
        url: `/sessions/schools/${getSchoolId()}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: tags.SCHOOL_SESSIONS, id })),
              { type: tags.SCHOOL_SESSIONS, id: "LIST" },
            ]
          : [{ type: tags.SCHOOL_SESSIONS, id: "LIST" }],
    }),

    getSession: builder.query({
      query: (id) => ({
        url: `/sessions/${id}`,
      }),

      providesTags: (result, error, id) => [{ type: tags.SCHOOL_SESSIONS, id }],
    }),

    createSession: builder.mutation({
      query: (data) => ({
        url: "/sessions",
        method: "POST",
        body: {
          ...data,
          schoolId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.SCHOOL_SESSIONS, id: "LIST" }],
    }),

    editSession: builder.mutation({
      query: ({ data, id }) => ({
        url: `/sessions/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_SESSIONS, id: arg._id },
      ],
    }),

    deleteSession: builder.mutation({
      query: ({ id }) => ({
        url: `/sessions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_SESSIONS, id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetSessionsQuery,
  useGetSessionQuery,
  useCreateSessionMutation,
  useEditSessionMutation,
  useDeleteSessionMutation,
} = session;
