import { toast } from "react-toastify";

import { useDeleteStudentMutation } from "Network/academics/studentAcademics";
import useStudentActionStore from "./useStudentActionStore";

const useDeleteStudent = () => {
  const { setOpenConfirmDelete, selectedStudent } = useStudentActionStore();

  const [deleteStudent, deleteStudentState] = useDeleteStudentMutation();

  const handleDeleteStudent = () => {
    deleteStudent([selectedStudent._id])
      .unwrap()
      .then((_) => {
        toast.success("Student Deleted Successfully");
        setOpenConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleDeleteStudent,
    deleteStudentState,
  };
};

export default useDeleteStudent;
