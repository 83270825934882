import { useParams, useNavigate } from "react-router";

import Button from "./Forms/Button";

const NoFeaturesAvailablePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const buttonTitle = "Back to Login";

  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        You're not lost!
      </h1>
      <p className="text-lg text-gray-600">
        Contact service providers to grant you access
      </p>
      {buttonTitle && (
        <Button
          className="bg-blue-500 text-white px-6 py-2 mt-2 rounded hover:bg-blue-600 transition duration-300"
          title={buttonTitle}
          handleSubmit={() => {
            localStorage.clear();
            navigate(`/${params.shortName}/login`, { replace: true });
          }}
        />
      )}
    </div>
  );
};

export default NoFeaturesAvailablePage;
