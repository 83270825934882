import { useParams } from "react-router";

import MainLayout from "Component/Layout/MainLayout";
import SummaryCard from "./SummaryCard";
import DetailedInfo from "./DetailedInfo";
import BackButton from "Component/BackButton";
import { academicFlattenRoutes } from "Routes/academicRoutes";
import { useGetStudentQuery } from "Network/academics/studentAcademics";

const StudentDetails = () => {
  const params = useParams();

  const studentState = useGetStudentQuery(params.id);
  return (
    <MainLayout>
      <div className="mt-3">
        <BackButton
          navigateTo={`/${params.shortName}/${academicFlattenRoutes.students}`}
        />
        <div className="flex gap-14 sm:gap-3 sm:flex-col h-fit mt-3">
          <SummaryCard studentState={studentState} />

          <DetailedInfo studentState={studentState} />
        </div>
      </div>
    </MainLayout>
  );
};

export default StudentDetails;
