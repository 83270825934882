import { Checkbox } from "@radix-ui/themes";

import {
  MobileTableWrapper,
  MobileTRow,
  MobileTableLoader,
} from "Component/Table/Mobile";
import Actions from "../Manage/Actions";
import Avatar from "Component/Avatar";
import StatusIdentifier from "Component/StatusIdentifier";

const ApplicantMobileView = ({
  handleView = () => {},
  applicantsState,
  useSelectApplicantStore,
}) => {
  const { data: applicants, isFetching } = applicantsState;

  const { selectedItems, handleItemSelection, handleSelectAll, selectAll } =
    useSelectApplicantStore();
  return (
    <div>
      {!isFetching ? (
        <div>
          <div className="sm:flex gap-3 items-center mb-3 text-sm hidden">
            <p>Select All:</p>
            <Checkbox
              checked={selectAll}
              onCheckedChange={(check) =>
                handleSelectAll(check, applicants?.data)
              }
            />
          </div>
          <MobileTableWrapper>
            {applicants?.data?.map((applicant, index) => (
              <div
                key={index}
                className="flex gap-5 px-3 py-2 rounded bg-white  shadow-small-select  text-[14px]  "
              >
                <table className="w-full">
                  <MobileTRow
                    title={"Applicant ID"}
                    actionTrigger={
                      <div>
                        <Actions handleView={handleView} data={applicant} />
                        <Checkbox
                          checked={selectedItems?.some(
                            (id) => id === applicant?._id,
                          )}
                          onCheckedChange={(check) =>
                            handleItemSelection(check, applicant)
                          }
                        />
                      </div>
                    }
                  >
                    <div className="flex gap-2 items-center py-[6px] w-full h-full">
                      <Avatar image={applicant?.photo} />
                      <span className="">
                        {applicant?.applicantNumber || "Applicant ID"}
                      </span>
                    </div>
                  </MobileTRow>

                  <MobileTRow
                    action
                    title={"Name"}
                    text={`${applicant?.firstName} ${applicant?.middleName || ""} ${applicant?.lastName}`}
                  />

                  <MobileTRow
                    title={"Section"}
                    text={applicant?.section?.name}
                  />

                  <MobileTRow title={"Class"} text={applicant?.class?.name} />

                  <MobileTRow title={"Admission Status"}>
                    <StatusIdentifier status={applicant?.admissionStatus} />
                  </MobileTRow>
                </table>
              </div>
            ))}
          </MobileTableWrapper>
        </div>
      ) : (
        <MobileTableLoader />
      )}
    </div>
  );
};

export default ApplicantMobileView;
