import useClassStore from "../hooks/useClassStore";
import SimpleCard from "Component/Cards/SimpleCard";

const ClassCard = ({ data, key }) => {
  const { setOpenDeleteClassModal, setOpenEditClassModal, setSelectedClass } =
    useClassStore();
  return (
    <SimpleCard
      key={key}
      onClickEdit={() => {
        setOpenEditClassModal(true);
        setSelectedClass(data);
      }}
      onClickDelete={() => {
        setOpenDeleteClassModal(true);
        setSelectedClass(data);
      }}
    >
      <div className="text-xs flex flex-col gap-2">
        <p className="font-semibold">{data?.name}</p>
        <p className="text-gray-500">{data?.category?.name}</p>
      </div>
    </SimpleCard>
  );
};

export default ClassCard;
