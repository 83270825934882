import { useState, useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import { genderList } from "Utils/constants";
import { useCreateApplicantsMutation } from "Network/parentNetwork/applicants";
import useUploadFileHooks from "../../../../hooks/File/useUploadFileHooks";
import { transformClassOption } from "Utils/transform";
import {
  transformCustomForm,
  getTransformedFormFields,
  applicantValidationSchema,
} from "Screens/AdmissionScreen/Applicants/util";
import useApplicantStore from "./useApplicationStore";

const useCreateApplicants = ({ application, selectedClass }) => {
  const applicatioStore = useApplicantStore();
  const [createApplicants, applicantsState] = useCreateApplicantsMutation();

  const [selectedGender, setSelectedGender] = useState(genderList[0]);

  const selectedApplication = {
    value: application?._id,
    label: `${application?.session}: ${application?.description}`,
  };

  const customForm = application?.form?.data?.sections || [];

  const processedForm = useMemo(
    () => transformCustomForm({ customForm }),
    [customForm],
  );

  const transformedSelectedClass = transformClassOption([selectedClass])[0];

  const { resetFile } = useUploadFileHooks();

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: selectedGender?.value,
    address: "",
    classId: selectedClass?._id,
    applicationId: application?._id,
    medicalInfo: "",
    photo: undefined,
    supportingDocuments: [],
    parentOrGuardianInfo: {
      fullName: "",
      relationShip: "",
      phone: "",
      email: "",
    },
  };

  const getCustomFormSectionsField = useCallback(
    (formValues) =>
      getTransformedFormFields({ formValues, initialValues, processedForm }),
    [processedForm],
  );

  const handleSubmit = (values, actions) => {
    const data = {
      sectionId: application?.section?._id,
      categoryId: selectedClass?.category?._id,
      ...getCustomFormSectionsField(values),
      address: values?.address || undefined,
      middleName: values.middleName || undefined,
      medicalInfo: values.medicalInfo || undefined,
    };

    createApplicants({ data, schoolId: application?.schoolId })
      .unwrap()
      .then((_) => {
        actions.resetForm();
        applicatioStore.setOpen(false);
        toastify.success("Application created");
        resetFile();
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return {
    selectedGender,
    setSelectedGender,
    selectedApplication,
    selectedClass,
    transformedSelectedClass,
    initialValues,
    validationSchema: applicantValidationSchema,
    handleSubmit,
    applicantsState,
    processedForm,
  };
};

export default useCreateApplicants;
