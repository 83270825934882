import { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { useGetSectionsQuery } from "Network/academics/sectionAcademics";
import { useGetClassesQuery } from "Network/academics/classAcademics";
import { useGetSubjectsQuery } from "Network/academics/subjectAcademics";
import {
  useCreateCurriculumMutation,
  useGetCurriculumsQuery,
  useDeleteCurriculumMutation,
} from "Network/academics/curriculumAcademics";

import useAssignmentStore from "./useAssignmentStore";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";

const validationSchema = Yup.object().shape({
  sectionId: Yup.string().required("Section ID is required"),
  classId: Yup.string().required("Class ID is required"),
  subjectIds: Yup.array()
    .of(Yup.string())
    .required("Subjects are required")
    .min(1, "At least one subject is required"),
});

const useAssignSubjectToClass = () => {
  const [selectedClass, setSelectedClass] = useState({
    label: "Select Class",
    value: null,
  });
  const [selectedSection, setSelectedSection] = useState({
    label: "Select Section",
    value: null,
  });
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const { selectedCurriculum, setConfirmRemoveAll } = useAssignmentStore();

  const sectionState = useGetSectionsQuery();
  const subjectState = useGetSubjectsQuery();
  const classesState = useGetClassesQuery(
    { sectionId: selectedSection.value },
    {
      skip: !selectedSection.value,
    },
  );

  const [createCurriculum, createCurriculumState] =
    useCreateCurriculumMutation();
  const [deleteCurriculum, deleteCurriculumState] =
    useDeleteCurriculumMutation();

  const { page, search, handleSearch, setPage } = usePaginationAndSearch();

  const curriculumState = useGetCurriculumsQuery({ page, search });

  const initialValues = {
    sectionId: "",
    classId: "",
    categoryId: "",
    subjectIds: [],
  };

  const handleSubmit = (values, actions) => {
    const categoryId = classesState?.data?.data?.find(
      (item) => item._id === selectedClass.value,
    )?.category?._id;
    const data = {
      ...values,
      categoryId,
    };

    createCurriculum(data)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        setSelectedClass({ label: "Select Class", value: null });
        setSelectedSection({ label: "Select Section", value: null });
        setSelectedSubjects([]);
        toast.success("Curriculum created");
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleDelete = () => {
    deleteCurriculum(selectedCurriculum?._id)
      .unwrap()
      .then((_) => {
        toast.success("Curriculum deleted");
        setConfirmRemoveAll(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
          setConfirmRemoveAll(false);
        }
      });
  };

  return {
    validationSchema,
    initialValues,
    handleSubmit,

    selectedClass,
    setSelectedClass,
    selectedSection,
    setSelectedSection,
    selectedSubjects,
    setSelectedSubjects,

    sectionState,
    classesState,
    subjectState,

    createCurriculumState,
    curriculumState,

    handleDelete,
    deleteCurriculumState,

    setPage,
    handleSearch,
  };
};

export default useAssignSubjectToClass;
