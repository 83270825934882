import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import useAssignRole from "../hooks/useAssignRole";
import Button from "Component/Forms/Button";
import Select from "Component/Forms/Select";
import { transformSelectOption } from "Utils/transform";

const AssignRole = ({ open, setOpen }) => {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    selectedRole,
    setSelectedRole,
    assignRoleState,
    rolesState,
  } = useAssignRole();

  return (
    <CustomModal
      open={open}
      handleClose={() => !assignRoleState.isLoading && setOpen(false)}
      className={"min-w-[30%]"}
      title="Assign Role"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-3 w-full mt-2">
            <Select
              withLabel
              label="Role"
              data={transformSelectOption(rolesState.data)}
              value={selectedRole}
              className={`${props.errors?.roleId && "border-[red] "}`}
              isLoading={rolesState.isLoading}
              onChangeOption={(x) => {
                props.setFieldValue("roleId", x?.value);
                setSelectedRole(x);
              }}
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[30%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Assign Role"
                handleSubmit={props.handleSubmit}
                isLoading={assignRoleState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AssignRole;
