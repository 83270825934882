import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { useGetSessionsQuery } from "Network/academics/sessionAcademics";
import { useGetTermsQuery } from "Network/academics/term";

const validationSchema = Yup.object().shape({
  sessionId: Yup.string().required("Session is required"),
  termId: Yup.string().required("Term is required"),
  reportType: Yup.string().required("Report type is required"),
});

const useGenerateStudentReport = () => {
  const [selectedSession, setSelectedSession] = useState({
    value: "",
    label: "Select Session",
  });
  const [selectedTerm, setSelectedTerm] = useState({
    value: "",
    label: "Select Term",
  });

  const [selectedReportType, setSelectedReportType] = useState({
    value: "",
    label: "Result Sheet",
  });

  const sessionState = useGetSessionsQuery();
  const termState = useGetTermsQuery();

  const initialValues = {
    sessionId: "",
    termId: "",
    reportType: "resultSheet",
  };

  const handleSubmit = (values, actions) => {
    console.log(values);
  };
  return {
    initialValues,
    validationSchema,

    selectedSession,
    setSelectedSession,
    selectedTerm,
    setSelectedTerm,
    selectedReportType,
    setSelectedReportType,

    sessionState,
    termState,

    handleSubmit,
  };
};

export default useGenerateStudentReport;
