import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import CustomModal from "Component/Forms/CustomModal";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import { useCreateDocumentMutation } from "Network/admission/configurations/supportingDocument";

const CreateDoc = ({ openCreateDoc, setOpenCreateDoc }) => {
  const [createDocument, documentState] = useCreateDocumentMutation();
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Document name is required"),
  });

  const handleSubmit = (values, action) => {
    createDocument(values)
      .unwrap()
      .then((res) => {
        toast.success("Document created");
        setOpenCreateDoc(false);
        action.resetForm();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <CustomModal
      open={openCreateDoc}
      title="Create Document"
      handleClose={() => !documentState.isLoading && setOpenCreateDoc(false)}
      modalClassName="min-h-[100px] sm:h-[165px] sm:top-32 w-[35%]"
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="flex flex-col h-full mt-5 gap-4 w-full ">
              <Input
                withTitle
                title="Supporting documents"
                placeholder="Example BirthCertificate, NINSlip, etc"
                name="name"
                className="text-primary font-semibold"
              />

              <Button
                isLoading={documentState.isLoading}
                handleSubmit={props.handleSubmit}
                title="Create"
                className="w-fit text-[12px] py-2 px-4"
              />
            </div>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default CreateDoc;
