import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import SimpleCard from "Component/Cards/SimpleCard";
import ConfirmDelete from "Component/ConfirmDelete";
import {
  useCreateTermMutation,
  useGetTermsQuery,
  useDeleteTermMutation,
} from "Network/academics/term";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Term name is required"),
});
const TermDetails = () => {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);

  const termState = useGetTermsQuery();
  const [createTerm, createTermState] = useCreateTermMutation();
  const [deleteTerm, deleteTermState] = useDeleteTermMutation();

  const initialValues = {
    name: "",
  };

  const handleSubmit = (values, actions) => {
    createTerm(values)
      .unwrap()
      .then((_) => {
        toast.success("Session created successfully");
        actions.resetForm();
      })
      .catch((err) => {
        if (err.status === 400) {
          const msg =
            typeof err.data?.message === "string"
              ? err.data?.message
              : err.data?.message[0];
          toast.error(msg);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleDelete = () => {
    deleteTerm(selectedTerm._id)
      .unwrap()
      .then((_) => {
        toast.success("Term deleted successfully");
        setOpenConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenConfirmDelete(false);
      });
  };

  return (
    <div className="text-xs flex flex-col gap-8">
      <div className="flex flex-col gap-2 sticky top-10 z-50 bg-white">
        <p className="font-medium text-gray-600">Create Term</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="flex gap-2 w-full items-center">
              <Input
                placeholder="Academic Term"
                name="name"
                className="min-w-[80%]"
              />
              <Button
                title="Submit"
                className="w-full h-[40px]"
                isLoading={createTermState.isLoading}
                handleSubmit={props.handleSubmit}
              />
            </div>
          )}
        </Formik>
      </div>

      <div className="flex flex-col gap-2">
        <p className="font-medium text-gray-600">Term List</p>

        {!termState.isFetching ? (
          termState.data.length > 0 ? (
            <div className="grid grid-cols-2 gap-5">
              {termState.data.map((term, index) => (
                <SimpleCard
                  className="min-w-fit h-fit py-2"
                  title={term.name}
                  showEditButton={false}
                  key={index}
                  onClickDelete={() => {
                    setOpenConfirmDelete(true);
                    setSelectedTerm(term);
                  }}
                />
              ))}
            </div>
          ) : (
            <p className="flex justify-center w-full">No Data</p>
          )
        ) : (
          <div className="flex mt-2 justify-center">
            <CircularProgress />
          </div>
        )}
      </div>
      {openConfirmDelete && (
        <ConfirmDelete
          openConfirmDelete={openConfirmDelete}
          setOpenConfirmDelete={setOpenConfirmDelete}
          title="Term"
          deleteName={selectedTerm?.name}
          handleDelete={handleDelete}
          isLoading={deleteTermState.isLoading}
        />
      )}
    </div>
  );
};

export default TermDetails;
