import { useState } from "react";
import { toast } from "react-toastify";

import useUploadFile from "hooks/File/useUploadFile";
import {
  useCreateAttachmentMutation,
  useGetAttachmentsQuery,
  useDeleteAttachmentMutation,
  useEditAttachmentMutation,
} from "Network/admission/configurations/attachment";

const useAttachment = () => {
  const [createAttachment, createAttachmentState] =
    useCreateAttachmentMutation();

  const attachments = useGetAttachmentsQuery();

  const [handleUpload, uploadState] = useUploadFile({
    filesTypeListToAllow: ["pdf"],
  });

  const [deleteAttachment, deleteAttachmentState] =
    useDeleteAttachmentMutation();
  const [editAttachment, editAttachmentState] = useEditAttachmentMutation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = (values, actions) => {
    createAttachment({ ...values, url: uploadState?.data?.src })
      .unwrap()
      .then(() => {
        toast.success("file uploaded successfully");
        actions.resetForm();
        uploadState.resetFile();
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
        uploadState.resetFile();
        setOpenUpdateModal(false);
      });
  };

  const handleDelete = () => {
    deleteAttachment(selectedItem?._id)
      .unwrap()
      .then(() => {
        toast.success("File deleted successfully");
        setConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
        setOpenUpdateModal(false);
      });
  };

  const handleUpdate = (value) => {
    editAttachment({
      data: { name: value, url: selectedItem?.url },
      id: selectedItem?._id,
    })
      .unwrap()
      .then(() => {
        toast.success("File Edited successfully");
        setOpenUpdateModal(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
        setOpenUpdateModal(false);
      });
  };

  return {
    attachments,
    createAttachmentState,
    deleteAttachmentState,
    editAttachmentState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
    handleUpload,
    uploadState,
  };
};

export default useAttachment;
