import { MdDelete } from "react-icons/md";

import AccordionDetailsCard from "Component/Cards/AccordionDetailsCard";
import Button from "Component/Forms/Button";
import Divider from "Component/Divider";
import useAssignmentStore from "../hooks/useAssignmentStore";

const SubjectsCard = ({ data }) => {
  const {
    setConfirmRemoveAll,
    setConfirmRemoveSingle,
    setSelectedStaffSingleSubject,
    setSelectedStaffAllSubjects,
  } = useAssignmentStore();

  const totalSubjects = data?.subjects?.reduce((total, classItem) => {
    return total + classItem.subjects.length;
  }, 0);
  return (
    <AccordionDetailsCard
      title={`${data?.firstName} ${data?.middleName} ${data?.lastName}`}
      subTitle={data?.staffNumber}
      countName={"Total Subjects"}
      count={totalSubjects || 0}
    >
      <div>
        {totalSubjects > 0 ? (
          <div
            className=" flex flex-col gap-2 max-h-[300px] overflow-y-auto ml-2"
            style={{ scrollbarWidth: "thin" }}
          >
            {data?.subjects?.map((subject, index) => (
              <div key={index}>
                <Section
                  data={subject}
                  staffData={{
                    staffNumber: data?.staffNumber,
                    _id: data?._id,
                    className: subject?.class?.name,
                    subjects: data?.subjects,
                  }}
                  setConfirmRemoveSingle={setConfirmRemoveSingle}
                  setSelectedStaffSingleSubject={setSelectedStaffSingleSubject}
                  setSelectedStaffAllSubjects={setSelectedStaffAllSubjects}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-xs">No Subjects</div>
        )}

        {totalSubjects > 0 && (
          <div className="flex gap-2 w-full justify-end border-t-1 mt-3">
            <Button
              Icon={<MdDelete />}
              variant="icon"
              className="text-red-500"
              handleSubmit={() => {
                setConfirmRemoveAll(true);
                setSelectedStaffAllSubjects(data);
              }}
            />
          </div>
        )}
      </div>
    </AccordionDetailsCard>
  );
};

export default SubjectsCard;

const Section = ({
  staffData,
  data,
  setConfirmRemoveSingle = () => {},
  setSelectedStaffSingleSubject = () => {},
  setSelectedStaffAllSubjects = () => {},
}) => {
  return (
    <div className="flex flex-col gap-[2px] text-xs">
      <Divider title={data?.class?.name} />
      {data?.subjects?.map((sb, index) => (
        <div key={index} className="flex justify-between items-center ">
          <p>{sb?.name}</p>

          <Button
            Icon={<MdDelete />}
            variant="icon"
            className="text-red-500"
            handleSubmit={() => {
              setConfirmRemoveSingle(true);
              setSelectedStaffSingleSubject(sb);
              setSelectedStaffAllSubjects(staffData);
            }}
          />
        </div>
      ))}
    </div>
  );
};
