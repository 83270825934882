// store.js
import { create } from "zustand";

// Create a store with Zustand
const useScoreEntryStore = create((set) => ({
  // Initial state
  selectedSection: {
    label: "Select Section",
    value: null,
  },
  selectedClass: {
    label: "Select Class",
    value: null,
  },
  selectedSubject: null,

  sectionData: null,

  classData: null,

  // Actions
  setSelectedSection: (section) =>
    set({
      selectedSection: section,
      // Reset dependent states when section changes
      selectedClass: {
        label: "Select Class",
        value: null,
      },
      selectedSubject: null,
    }),

  setSelectedClass: (classItem) =>
    set({
      selectedClass: classItem,
      // Reset subject when class changes
      selectedSubject: null,
    }),

  setSelectedSubject: (subject) =>
    set({
      selectedSubject: subject,
    }),

  setSectionData: (data) => set({ sectionData: data }),

  setClassData: (data) => set({ classData: data }),

  // Reset all selections
  resetSelections: () =>
    set({
      selectedSection: {
        label: "Select Section",
        value: null,
      },
      selectedClass: {
        label: "Select Class",
        value: null,
      },
      selectedSubject: null,

      sectionData: null,

      classData: null,
    }),
}));

export default useScoreEntryStore;
