import userMangementAPi from "./apiSlice";
import { tags } from "../../Utils/constants";

const auth = userMangementAPi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: "/organisations",
        method: "POST",
        body: data,
      }),
    }),

    registerUser: builder.mutation({
      query: (data) => ({
        url: "/users",
        method: "POST",
        body: data,
      }),
    }),

    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        tags.MY_PROFILE_CONTROL_TAG,
        tags.SCHOOL_PROFILE_CONTROL_TAG,
      ],
    }),

    checkShortName: builder.query({
      query: (shortName) => ({
        url: `/organisations/shortname-exist?shortName=${shortName}`,
      }),
    }),

    verifyEmail: builder.query({
      query: (hash) => ({
        url: `/auth/verify-email/${hash}`,
      }),
    }),

    resendVerifyEmail: builder.mutation({
      query: (data) => ({
        url: "/auth/resend-verify-email",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useRegisterUserMutation,
  useLoginMutation,
  useCheckShortNameQuery,
  useVerifyEmailQuery,
  useResendVerifyEmailMutation,
} = auth;
