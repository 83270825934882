import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import { startCase } from "lodash";

import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import Avatar from "Component/Avatar";
import cn from "Utils/cn";
import ConfirmBox from "Component/ConfirmBox";
import BackButton from "Component/BackButton";
import StatusIdentifier from "Component/StatusIdentifier";
import useUpdateApplicantStatus from "../hooks/useUpdateApplicantStatus";
import { statusConstant } from "Utils/constants";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";

const ApplicantDetails = () => {
  const params = useParams();

  const {
    openApprove,
    setOpenApprove,
    openReject,
    setOpenReject,
    handleUpdateStatus,
    isFetching,
    isLoading,
    isLoadingUpdateStatus,
    data,
  } = useUpdateApplicantStatus({ id: params.id });

  const formData = JSON.parse(data?.formData || "{}");

  const transformData = formData
    ? Object?.entries(formData)?.map(([sectionKey, fields]) => ({
        sectionTitle: startCase(sectionKey),
        data: Object?.entries(fields)?.map(([fieldKey, fieldValue]) => ({
          fieldTitle: startCase(fieldKey),
          fieldValue,
        })),
      }))
    : [];

  return (
    <MainLayout isLoading={isLoading}>
      <BackButton
        navigateTo={`/${params.shortName}/${admissionFlattenRoutes.applicants}`}
      />

      {!isFetching ? (
        <div className="flex flex-col gap-5 sm:gap-3">
          <div className="bg-white p-3 flex flex-col gap-4 mt-2">
            <div className="flex sm:flex-col justify-between sm:gap-2 items-center">
              <p className="flex items-center font-bold">
                Applicant Information
              </p>

              <StatusIdentifier
                status={statusConstant[data?.admissionStatus]}
              />
            </div>

            <div className="flex sm:flex-col sm:items-center gap-3 border-1 sm:border-none">
              <Avatar
                image={data?.photo}
                className="w-[250px] sm:w-[100px] h-[250px] sm:h-[100px] rounded-none"
              />

              <div className="flex flex-col w-full border-l-1 sm:border-1">
                <Row
                  isHeader
                  title={"Application ID"}
                  text={data?.applicantNumber}
                />
                <Row
                  capitaliseTitle
                  background
                  title={"Last Name"}
                  text={data?.lastName}
                />
                <Row
                  capitaliseTitle
                  title={"First Name"}
                  text={data?.firstName}
                />
                <Row
                  capitaliseTitle
                  background
                  title={"Other Name"}
                  text={data?.middleName}
                />
                <Row
                  capitaliseTitle
                  title={"D.o.b"}
                  text={dayjs(data?.dob).format("DD/MM/YYYY")}
                />
                <Row
                  capitaliseTitle
                  background
                  title={"Gender"}
                  text={data?.gender}
                />

                <Row
                  capitaliseTitle
                  title={"Section Applied for"}
                  text={data?.section?.name}
                />
                <Row
                  capitaliseTitle
                  background
                  title={"Class"}
                  text={data?.class?.name}
                />

                <Row
                  capitaliseTitle
                  title={"Category"}
                  text={data?.category?.name || "None"}
                />

                <Row
                  capitaliseTitle
                  title={"Payment status"}
                  text={
                    <StatusIdentifier
                      className="bg-transparent p-0 text-[12px] sm:text-[10px] font-semibold"
                      status={statusConstant[data?.payment?.status]}
                    />
                  }
                  background
                />
              </div>
            </div>

            {transformData?.map((customData, index) => (
              <div className="flex flex-col w-full border-1">
                <Row
                  background
                  isHeader
                  key={index}
                  title={customData?.sectionTitle}
                />

                {customData?.data?.map((sectionData, sectionIndex) => (
                  <Row
                    key={sectionIndex}
                    title={sectionData?.fieldTitle}
                    text={sectionData?.fieldValue}
                    background={(sectionIndex + 1) % 2 === 0}
                  />
                ))}
              </div>
            ))}

            <div className="flex flex-col w-full border-1">
              <Row background isHeader title={"Guardian Information"} />
              <Row
                title={"Full Name"}
                text={data?.parentOrGuardianInfo?.fullName}
              />
              <Row
                background
                title={"Email"}
                text={data?.parentOrGuardianInfo?.email}
              />
              <Row
                title={"Relationship"}
                text={data?.parentOrGuardianInfo?.relationShip}
              />
            </div>

            <div className="flex flex-col w-full border-1">
              <Row background isHeader title={"Document's Uploaded"} />
              {data?.supportingDocuments?.map((doc) => (
                <Row title={doc?.name} link={doc?.url} />
              ))}
            </div>

            <div className="flex gap-5 sm:gap-0 sm:justify-between">
              <Button
                className="w-[121px] sm:w-[48%] bg-[#8D0909] py-2"
                title="Decline"
                handleSubmit={() => setOpenReject(true)}
              />
              {statusConstant.admitted === data?.admissionStatus &&
              data?.admissionLetter !== undefined ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data?.admissionLetter?.letterUrl}
                >
                  <Button
                    variant="outline"
                    className="w-auto  py-2"
                    title="View Admission Letter"
                  />
                </a>
              ) : (
                <Button
                  className="w-[121px] sm:w-[48%] bg-[#248D09] py-2"
                  title="Admit"
                  handleSubmit={() => setOpenApprove(true)}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center mt-4">
          <CircularProgress />
        </div>
      )}

      {openApprove && (
        <ConfirmBox
          openConfirmBox={openApprove}
          setOpenConfirmBox={setOpenApprove}
          title="Approve Appilcation"
          isLoading={isLoadingUpdateStatus}
          handleConfirm={() => handleUpdateStatus("approve")}
        />
      )}

      {openReject && (
        <ConfirmBox
          openConfirmBox={openReject}
          setOpenConfirmBox={setOpenReject}
          title="Reject Appilcation"
          isLoading={isLoadingUpdateStatus}
          handleConfirm={() => handleUpdateStatus("decline")}
        />
      )}
    </MainLayout>
  );
};

export default ApplicantDetails;

const Row = ({ title, text, link, isHeader, capitaliseTitle, background }) => {
  return (
    <div
      className={cn(
        "flex gap-3 sm:justify-between h-[26px] text-[12px] sm:text-[10px] items-center w-full",
        isHeader && "font-bold",
        background && "bg-slate-100",
      )}
    >
      <p
        className={cn(
          "px-3 w-[30%] sm:w-full",
          (isHeader || capitaliseTitle) && "uppercase",
        )}
      >
        {title}
      </p>
      {text && <p className="border-1 h-full sm:hidden" />}
      {text && <p className="px-3 w-[69%] sm:w-full">{text}</p>}
      {link && (
        <a
          className="px-3 w-[69%] sm:w-full text-blue-600"
          target="_blank"
          rel="noreferrer"
          href={link}
        >
          View
        </a>
      )}
    </div>
  );
};
