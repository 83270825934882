import admissionApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const supportingDocument = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: () => ({
        url: `/schools/${getSchoolId()}/supporting-documents`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.SCHOOL_SUPPORTING_DOCUMENT,
                id,
              })),
              { type: tags.SCHOOL_SUPPORTING_DOCUMENT, id: "LIST" },
            ]
          : [{ type: tags.SCHOOL_SUPPORTING_DOCUMENT, id: "LIST" }],
    }),

    getDocument: builder.query({
      query: (id) => ({
        url: `/schools/${getSchoolId()}/supporting-documents/${id}`,
      }),
      providesTags: (result, error, id) => [
        { type: tags.SCHOOL_SUPPORTING_DOCUMENT, id },
      ],
    }),

    createDocument: builder.mutation({
      query: (data) => ({
        url: `/schools/${getSchoolId()}/supporting-documents`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: tags.SCHOOL_SUPPORTING_DOCUMENT, id: "LIST" }],
    }),

    editDocument: builder.mutation({
      query: ({ data, id }) => ({
        url: `/schools/${getSchoolId()}/supporting-documents/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_SUPPORTING_DOCUMENT, id: arg._id },
      ],
    }),

    deleteDocument: builder.mutation({
      query: (id) => ({
        url: `/schools/${getSchoolId()}/supporting-documents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_SUPPORTING_DOCUMENT, id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
} = supportingDocument;
