import Profile from "Screens/Profile/Profile";
import SchoolProfile from "Screens/SchoolProfile";
import ProtectedRoute from "Component/Layout/ProtectedRoute";
import { PERMISSIONS_LISTS } from "Utils/constants";

const generalRoutesList = [
  {
    type: "collapse",
    name: "profile",
    key: `profile`,
    route: "general-settings/profile",
    noCollapse: true,
    component: <Profile />,
  },

  {
    type: "collapse",
    name: "school-profile",
    key: `school-profile`,
    route: "general-settings/school-profile",
    noCollapse: true,
    component: (
      <ProtectedRoute
        element={<SchoolProfile />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },
];

export default generalRoutesList;
