import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "sections";

const sectionAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getSections: builder.query({
      query: () => ({
        url: `/${endpoint}/organisation/${getSchoolId()}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_SECTIONS,
                id,
              })),
              { type: tags.ACADEMICS_SECTIONS, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_SECTIONS, id: "LIST" }],
    }),

    createSection: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_SECTIONS, id: "LIST" }],
    }),

    deleteSection: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_SECTIONS, id: "LIST" }],
    }),

    updateSection: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PUT",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_SECTIONS, id: "LIST" }],
    }),
  }),
});

export const {
  useGetSectionsQuery,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
} = sectionAcademics;
