import CustomModal from "Component/Forms/CustomModal";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";

const IdentificationInitials = ({ open, setOpen }) => {
  return (
    <CustomModal
      open={open}
      handleClose={() => setOpen(false)}
      title="Set ID Initials"
      className="w-[30%] max-w-[70%] sm:w-[95%] sm:max-w-[95%] max-h-[90%] sm:max-h-[95%] overflow-y-auto"
    >
      <div className="flex flex-col gap-5 mt-3">
        <InputNoFormik withTitle title="ID initials" />

        <div className="flex flex-col gap-1 text-xs">
          <p>
            ** Initials set will follow the format [SFS/25/001] to generate
            student ID
          </p>
          <p className="text-[#FF2C1E]">
            ** This can onlly be done once make sure you input the right details
          </p>
        </div>
        <div className="flex justify-between">
          <Button
            handleSubmit={() => setOpen(false)}
            title="Cancel"
            className="w-fit text-[12px] py-2 px-4 font-medium"
            variant="outline"
          />

          <Button
            title="Submit"
            className="w-fit text-[12px] py-2 px-4 font-medium"
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default IdentificationInitials;
