import { useState } from "react";
import { toast } from "react-toastify";

import {
  useGetSessionsQuery,
  useCreateSessionMutation,
  useEditSessionMutation,
  useDeleteSessionMutation,
} from "Network/admission/configurations/session";

const useSession = () => {
  const sessions = useGetSessionsQuery();
  const [creatSession, createSessionsState] = useCreateSessionMutation();
  const [deleteSession, deleteSessionState] = useDeleteSessionMutation();
  const [editSession, editSessionState] = useEditSessionMutation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = (values, actions) => {
    creatSession(values)
      .unwrap()
      .then(() => actions.resetForm())
      .catch(() => toast.error("Something went wrong"));
  };

  const handleDelete = () => {
    deleteSession({ id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Session deleted successfully");
        setConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  const handleUpdate = (value) => {
    editSession({ data: { name: value }, id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Session Edited successfully");
        setOpenUpdateModal(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  return {
    sessions,
    createSessionsState,
    deleteSessionState,
    editSessionState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
  };
};

export default useSession;
