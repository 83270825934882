import { useState } from "react";
import { toast } from "react-toastify";

import {
  useGetEntryRequirementsQuery,
  useCreateEntryRequirementMutation,
  useDeleteEntryRequirementMutation,
  useEditEntryRequirementMutation,
} from "Network/admission/configurations/admissionEntryRequirement";

const useAdmissionEntryRequirement = () => {
  const entryRequirements = useGetEntryRequirementsQuery();
  const [creatEntryRequirement, createEntryRequirementState] =
    useCreateEntryRequirementMutation();
  const [deleteEntryRequirement, deleteEntryRequirementState] =
    useDeleteEntryRequirementMutation();
  const [editEntryRequirement, editEntryRequirementState] =
    useEditEntryRequirementMutation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = (values, actions) => {
    creatEntryRequirement(values)
      .unwrap()
      .then(() => actions.resetForm())
      .catch(() => toast.error("Something went wrong"));
  };

  const handleDelete = () => {
    deleteEntryRequirement(selectedItem?._id)
      .unwrap()
      .then(() => {
        toast.success("Document deleted successfully");
        setConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  const handleUpdate = (value) => {
    editEntryRequirement({
      data: { requirementText: value },
      id: selectedItem?._id,
    })
      .unwrap()
      .then(() => {
        toast.success("Document Edited successfully");
        setOpenUpdateModal(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  return {
    entryRequirements,
    createEntryRequirementState,
    deleteEntryRequirementState,
    editEntryRequirementState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
  };
};

export default useAdmissionEntryRequirement;
