import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { tags } from "Utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_USER_MANAGEMENT_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("school_token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const refreshToken = localStorage.getItem("school_refresh_token");

  if (result?.meta?.response?.status === 401) {
    // Attempt to refresh the token
    const refreshResult = await baseQuery(
      { url: "/auth/refresh-token", method: "POST", body: { refreshToken } },
      api,
      extraOptions,
    );

    if (refreshResult?.data?.accessToken) {
      // Store the new token
      localStorage.setItem("school_token", refreshResult?.data?.accessToken);
      localStorage.setItem(
        "school_refresh_token",
        refreshResult?.data?.refreshToken,
      );

      // Retry the original request with the new token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // Log out user if token refresh fails
      localStorage.clear();
    }
  }

  return result;
};

const userMangementAPi = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  reducerPath: "userMangementAPi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [],
});

export default userMangementAPi;
