import { useState } from "react";
import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "Component/Forms/Input";
import { IoIosEye } from "react-icons/io";
import { useNavigate } from "react-router";
import Select from "Component/Forms/Select";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import CreateSection from "./Manage/CreateSection";
import CreateCategory from "./Manage/CreateCategory";
import { useParams } from "react-router-dom";

import useClass from "./hooks/useClass";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";

const Classes = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    sections,
    categories,
    classState,
    selectedSection,
    selectedCategory,
    handleSubmit,
    setSelectedSection,
    setSelectedCategory,
  } = useClass();

  const [openCreateSection, setOpenCreateSection] = useState(false);
  const [openCreateCategory, setOpenCreateCategory] = useState(false);

  const initialValues = {
    name: "",
    sectionId: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Class name is required"),
    sectionId: Yup.string().required("Section is required"),
  });

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Classes"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            className="w-fit sm:w-[50%] text-[12px] h-[41px] "
            title="View Classes "
            Icon={IoIosEye}
            withIcon
            handleSubmit={() =>
              navigate(
                `/${params.shortName}/${admissionFlattenRoutes.classList}`,
              )
            }
          />
        </div>
      </BodyHeaderLayout>

      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
              <div className="flex flex-wrap md:flex-nowrap   gap-4 w-full">
                <Input
                  withTitle
                  title="Class Name"
                  placeholder="Enter class name"
                  name="name"
                />
                <div className="w-[100%]">
                  <Select
                    label="Section"
                    className={`${props.errors?.sectionId && "!border-[red] "}`}
                    labelClass="text-[14px]"
                    optionClass="z-50"
                    data={
                      sections?.data?.map((x) => ({
                        value: x._id,
                        label: x.name,
                      })) || []
                    }
                    value={selectedSection}
                    isLoading={sections.isFetching}
                    onChangeOption={(x) => {
                      props.setFieldValue("sectionId", x?.value);
                      setSelectedSection(x);
                    }}
                  />
                  <span
                    onClick={() => setOpenCreateSection(true)}
                    className="text-[11px] text-primary underline cursor-pointer"
                  >
                    Create new section
                  </span>
                </div>

                <div className="w-[100%]">
                  <Select
                    label="Category"
                    labelClass="text-[14px]"
                    optionClass="z-50"
                    data={[
                      { value: null, label: "None" },
                      ...(categories && categories.data
                        ? categories.data.map((x) => ({
                            value: x._id,
                            label: x.name,
                          }))
                        : []),
                    ]}
                    value={selectedCategory}
                    isLoading={categories.isFetching}
                    onChangeOption={(x) => {
                      setSelectedCategory(x);
                    }}
                  />
                  <span
                    onClick={() => setOpenCreateCategory(true)}
                    className="text-[11px] text-primary underline cursor-pointer"
                  >
                    Create new category
                  </span>
                </div>
              </div>
              <Button
                className="w-[200px] h-[41px] "
                title="Create Class"
                handleSubmit={props.handleSubmit}
                isLoading={classState.isLoading}
              />
            </div>
          )}
        </Formik>
      </div>

      <CreateSection
        openCreateSection={openCreateSection}
        setOpenCreateSection={setOpenCreateSection}
      />
      <CreateCategory
        openCreateCategory={openCreateCategory}
        setOpenCreateCategory={setOpenCreateCategory}
      />
    </MainLayout>
  );
};

export default Classes;
