import { useState, useEffect } from "react";
import { useGetMyProfileQuery } from "Network/userManagement/profile";
import { genderList } from "Utils/constants";

const useGetProfile = (formik) => {
  const [gender, setGender] = useState({
    value: null,
    label: "Choose a gender",
  });

  const profileState = useGetMyProfileQuery();

  const resetForm = () => {
    formik.setFieldValue("firstName", profileState?.data?.firstName);
    formik.setFieldValue("lastName", profileState?.data?.lastName);
    formik.setFieldValue("email", profileState?.data?.email);
    formik.setFieldValue("phoneNumber", profileState?.data?.phoneNumber ?? "");
    formik.setFieldValue("address", profileState?.data?.address ?? "");
    if (profileState?.data?.gender) {
      formik.setFieldValue("gender", profileState?.data?.gender);
      setGender(
        genderList.filter((cn) => cn.value === profileState?.data?.gender)[0],
      );
    }
  };

  useEffect(() => {
    if (profileState.isSuccess) {
      resetForm();
    }
  }, [profileState.isSuccess]);

  return { profileState, gender, setGender, resetForm };
};

export default useGetProfile;
