import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import Input from "Component/Forms/Input";
import Select from "Component/Forms/Select";
import Button from "Component/Forms/Button";
import { transformSelectOption } from "Utils/transform";
import useCreateSection from "../hooks/useCreateSection";

const CreateSection = ({ open, setOpen }) => {
  const {
    validationSchema,
    initialValues,
    handleSubmit,

    createSectionState,
    gradingScaleState,
    assessmentState,

    selectedAssessment,
    setSelectedAssessment,
    selectedGradingScale,
    setSelectedGradingScale,
  } = useCreateSection({ setOpen });

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      title="Create Section"
      className="min-w-[30%]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-5">
            <Input withTitle title="Section Name" name="name" />

            <Select
              withLabel
              label="Grading Scale"
              data={transformSelectOption(gradingScaleState?.data)}
              isLoading={gradingScaleState.isLoading}
              value={selectedGradingScale}
              className={`${props.errors?.gradingScaleId && "border-[red] "}`}
              onChangeOption={(x) => {
                props.setFieldValue("gradingScaleId", x?.value);
                setSelectedGradingScale(x);
              }}
            />

            <Select
              withLabel
              label="Assessment Structure"
              data={transformSelectOption(assessmentState?.data)}
              isLoading={assessmentState.isLoading}
              value={selectedAssessment}
              className={`${props.errors?.assessmentStructureId && "border-[red] "}`}
              onChangeOption={(x) => {
                props.setFieldValue("assessmentStructureId", x?.value);
                setSelectedAssessment(x);
              }}
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[80px] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Create"
                handleSubmit={props.handleSubmit}
                isLoading={createSectionState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateSection;
