import { useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import FirstScreen from "./FirstScreen";
import SecondScreen from "./SecondScreen";
import Sucess from "./Sucess";
import useRegistration from "hooks/Auth/useRegistration";
import Layout from "./Layout";

const SignUpScreen = () => {
  const success = useSelector((state) => state.appSlice?.registrationSuccess);
  const [currentScreen, setCurrentScreen] = useState(0);
  const { handleRegister, loading, initialValues, validationSchemas } =
    useRegistration();

  const handleNext = () => {
    setCurrentScreen(1);
  };

  const handleSubmit = (values) => {
    if (currentScreen === 1) {
      handleRegister(values);
    } else {
      handleNext();
    }
  };

  return (
    <div>
      {success ? (
        <Sucess />
      ) : (
        <Layout>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas[currentScreen]}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <div className="min-h-[30vh] w-full bg-white shadow-lg px-8 py-5 flex flex-col gap-5 mt-3">
                <div className="flex gap-3 justify-center">
                  {Array.from({ length: 2 }).map((_, index) => (
                    <p
                      className={`px-4 py-[3px] ${index === currentScreen ? "bg-primary" : "border-1 border-primary"}  rounded-lg`}
                    />
                  ))}
                </div>
                {currentScreen === 0 ? (
                  <FirstScreen formikProps={props} />
                ) : (
                  <SecondScreen
                    back={() => {
                      setCurrentScreen(0);
                    }}
                    formikProps={props}
                    loading={loading}
                  />
                )}
              </div>
            )}
          </Formik>
        </Layout>
      )}
    </div>
  );
};

export default SignUpScreen;
