import { toast } from "react-toastify";

import IDForm from "./IDForm";
import { IntialsConstants } from "Utils/constants";
import {
  useCreateIDInitialsMutation,
  useUpdateIDInitialsMutation,
} from "Network/academics/identification";

const StaffID = ({ initialState }) => {
  const [createIDInitials, createIDInitialsState] =
    useCreateIDInitialsMutation();
  const [updateIDInitials, updateIDInitialsState] =
    useUpdateIDInitialsMutation();

  const staffInitials =
    initialState?.data?.find(
      (initial) => initial.type === IntialsConstants.staff,
    ) || null;

  const handleSubmit = (values) => {
    if (staffInitials) {
      const data = {
        firstPart: values.firstPart,
        secondPart: values.secondPart,
        type: IntialsConstants.staff,
      };
      updateIDInitials({ data, id: staffInitials._id })
        .unwrap()
        .then((_) => {
          toast.success("Staff ID updated successfully");
        })
        .catch((err) => {
          if (err.status === 400) {
            const msg =
              typeof err.data?.message === "string"
                ? err.data?.message
                : err.data?.message[0];
            toast.error(msg);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      createIDInitials({
        firstPart: values.firstPart,
        secondPart: values.secondPart,
        type: IntialsConstants.staff,
      })
        .unwrap()
        .then((_) => {
          toast.success("Staff ID set successfully");
        })
        .catch((err) => {
          if (err.status === 400) {
            const msg =
              typeof err.data?.message === "string"
                ? err.data?.message
                : err.data?.message[0];
            toast.error(msg);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  return (
    <div>
      <IDForm
        initialFirstPart={staffInitials?.firstPart || ""}
        initialSecondPart={staffInitials?.secondPart || ""}
        onSubmit={handleSubmit}
        queryLoading={initialState?.isFetching}
        mutationLoading={
          createIDInitialsState?.isLoading || updateIDInitialsState?.isLoading
        }
      />
    </div>
  );
};

export default StaffID;
