import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import CustomSearch from "Component/CustomSearch";
import ApplicantDeskTopView from "./View/ApplicantDeskTopView";
import ApplicantMobileView from "./View/ApplicantMobileView";
import ViewApplicant from "./View/ViewApplicant";
import UploadProof from "./Manage/UploadProof";
import Pagination from "Component/Pagination/Pagination";
import { useGetApplicantPaymentsQuery } from "Network/admission/applicantPayment";
import usePaymentFilters from "./hooks/usePaymentFilters";

const ApplicantsPaymentVerification = () => {
  const {
    openView,
    setOpenView,
    setPage,
    page,
    search,
    applicants,
    handleSearch,
    openUpload,
    setOpenUpload,
  } = usePaymentFilters();

  const applicantsState = useGetApplicantPaymentsQuery({ page, search });

  return (
    <MainLayout>
      <div>
        <BodyHeaderLayout title={"Applicants Payment Verification"} />

        <div className="flex justify-between sm:flex-col mb-[20px] bg-white p-3">
          <CustomSearch
            className="w-[40%] sm:w-[100%] text-[14px]  "
            placeholderText="Search by name, section, class or admission status"
            handleChange={(e) => handleSearch(e.target.value)}
          />
        </div>

        <ApplicantDeskTopView applicantsState={applicantsState} />

        <ApplicantMobileView applicantsState={applicantsState} />

        {!applicantsState.isFetching && applicantsState?.data?.length !== 0 && (
          <div className="flex w-full justify-end mt-2">
            <Pagination
              onPageChange={setPage}
              pageTotal={applicantsState?.data?.totalPages}
              initialPage={applicantsState?.data?.currentPage}
            />
          </div>
        )}
      </div>

      <ViewApplicant
        open={openView}
        applicant={applicants}
        handleClose={() => setOpenView(false)}
      />

      <UploadProof
        open={openUpload}
        applicant={applicants}
        handleClose={() => setOpenUpload(false)}
      />
    </MainLayout>
  );
};

export default ApplicantsPaymentVerification;
