import { HiOutlineIdentification } from "react-icons/hi2";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Popover, Tabs } from "@radix-ui/themes";
import { CircularProgress } from "@mui/material";

import StudentID from "./StudentID";
import StaffID from "./StaffID";
import AdminID from "./AdminID";
import { useGetIDInitialsQuery } from "Network/academics/identification";

const IDManagement = ({
  miniSidebar,
  setMiniSidebar,
  setOnMouseEnter,
  isMobile,
}) => {
  const idInitialsState = useGetIDInitialsQuery();

  return (
    <Popover.Root className="p-0 m-0">
      <Popover.Trigger>
        <div
          onClick={() => {
            setMiniSidebar(false);
            setOnMouseEnter(false);
          }}
        >
          <Trigger miniSidebar={miniSidebar} />
        </div>
      </Popover.Trigger>
      <Popover.Content
        className=" z-auto h-[370px]"
        style={{
          padding: 0,
          margin: 0,
          width: isMobile ? "400px" : "800px",
          scrollbarWidth: "thin",
        }}
        side={isMobile ? "top" : "right"}
      >
        <div>
          <Tabs.Root defaultValue="student-id">
            <Tabs.List className="sticky top-0 bg-white z-50">
              <Tabs.Trigger value="student-id">
                <p className="">Student</p>
              </Tabs.Trigger>
              <Tabs.Trigger value="staff-id">
                <p className="">Staff</p>
              </Tabs.Trigger>
              <Tabs.Trigger value="admin-id">
                <p className="">Admin</p>
              </Tabs.Trigger>
            </Tabs.List>

            {!idInitialsState.isLoading ? (
              <div className="p-4 h-full w-full">
                <Tabs.Content value="student-id">
                  <StudentID initialState={idInitialsState} />
                </Tabs.Content>

                <Tabs.Content value="staff-id">
                  <StaffID initialState={idInitialsState} />
                </Tabs.Content>

                <Tabs.Content value="admin-id">
                  <AdminID initialState={idInitialsState} />
                </Tabs.Content>
              </div>
            ) : (
              <div className="flex justify-center pt-5 h-full w-full">
                <CircularProgress />
              </div>
            )}
          </Tabs.Root>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default IDManagement;

const Trigger = ({ miniSidebar }) => {
  if (miniSidebar) {
    return <HiOutlineIdentification className="text-blue-600 text-lg" />;
  }
  return (
    <div className="border-1 rounded-md p-2  mx-4 cursor-pointer">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <HiOutlineIdentification className="text-blue-600 text-lg" />

          <div className="text-xs">ID Management</div>
        </div>

        <MdKeyboardArrowRight className="text-lg" />
      </div>
    </div>
  );
};
