import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "classes";

const classAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getClasses: builder.query({
      query: ({ page, search, limit, sectionId }) => {
        const url = `/${endpoint}/organisation/${getSchoolId()}`;

        const params = { limit };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        if (sectionId !== undefined && sectionId !== "") {
          params.sectionId = sectionId;
        }

        return { url, params };
      },

      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: tags.ACADEMICS_CLASS,
                id,
              })),
              { type: tags.ACADEMICS_CLASS, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_CLASS, id: "LIST" }],
    }),

    createClass: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_CLASS, id: "LIST" }],
    }),

    deleteClass: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_CLASS, id: "LIST" }],
    }),

    updateClass: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PATCH",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_CLASS, id: "LIST" }],
    }),
  }),
});

export const {
  useGetClassesQuery,
  useCreateClassMutation,
  useDeleteClassMutation,
  useUpdateClassMutation,
} = classAcademics;
