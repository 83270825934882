import { Formik } from "formik";
import * as Yup from "yup";

import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";

const validationSchema = Yup.object().shape({
  firstPart: Yup.string().required("First part is required").max(5),
  secondPart: Yup.string().required("Second part is required").max(15),
});

// Make it accept onSubmit as a prop
const IDForm = ({
  onSubmit,
  initialFirstPart = "",
  initialSecondPart = "",
  queryLoading,
  mutationLoading,
}) => {
  const initialValues = {
    firstPart: initialFirstPart,
    secondPart: initialSecondPart,
  };

  // Use the passed in submit function or fall back to a default
  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-3">
            <Input
              disabled={queryLoading}
              withTitle
              title="First Part"
              placeholder="Abbr. of your school name or any word of your choice"
              name="firstPart"
            />

            <Input
              disabled={queryLoading}
              withTitle
              title="Second Part"
              placeholder="Any word or number of your choice. can be session year"
              name="secondPart"
            />

            <Button
              title="Save"
              isLoading={mutationLoading}
              className="w-full mt-3"
              handleSubmit={() => props.handleSubmit()}
            />
            <div className="flex flex-col gap-2 border-1 rounded-md p-2 text-[14px] mt-5">
              <p className="text-gray-500 font-semibold">Preview</p>

              <div className="flex gap-3">
                <p>
                  {props?.values?.firstPart
                    ? props?.values?.firstPart
                    : "______"}
                  /
                </p>
                <p>
                  {props?.values?.secondPart
                    ? props?.values?.secondPart
                    : "______"}
                  /
                </p>
                <p>100</p>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default IDForm;
