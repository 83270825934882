import {
  TableWrapper,
  THead,
  TRow,
  TableLoader,
  TableNoData,
} from "Component/Table";

import InputField from "./components/InputField";

const DesktopInputView = ({
  scoreSheets,
  assessments,
  isLoading,
  isFetching,
  currentField,
  getMaxValueForAssessment,
  handleSaveScore,
  tableLength,
}) => {
  return (
    <TableWrapper className="max-h-fit sm:hidden">
      <table>
        <THead className="w-full bg-gray-100">
          <td className="py-5 px-2 w-[2%]">S/N</td>
          <td className="px-2">Student Name</td>
          {!isLoading &&
            assessments.map((ass) => (
              <td key={ass._id || ass.name} className="px-2">
                {ass?.name}
              </td>
            ))}

          <td className="px-2 w-[5%]">Total</td>

          <td className="px-2 w-[5%]">Grade</td>
        </THead>

        {!isLoading ? (
          <tbody>
            {scoreSheets.length > 0 ? (
              scoreSheets.map((score, index) => (
                <TRow key={score._id || index}>
                  <td className="px-6 py-5">{index + 1}</td>
                  <td className="px-2 py-3 flex flex-col gap-1 justify-center">
                    <p className="capitalize">
                      {`${score?.student?.firstName} ${score?.student?.middleName || ""} ${score?.student?.lastName}`}
                    </p>
                    <p className="text-[10px]">
                      {score?.student?.studentNumber}
                    </p>
                  </td>
                  {score?.assessments?.map((assessment) => {
                    const maxValue = getMaxValueForAssessment(assessment?.name);
                    return (
                      <td
                        key={assessment._id || assessment.name}
                        className="px-2 whitespace-nowrap"
                      >
                        <InputField
                          currentField={currentField}
                          assessment={assessment}
                          handleSaveScore={handleSaveScore}
                          maxValue={maxValue}
                          score={score}
                          isFetching={isFetching}
                        />
                        <span className="text-xs text-gray-500 ml-1">
                          /{maxValue}
                        </span>
                      </td>
                    );
                  })}

                  <td className="px-2 whitespace-nowrap">{score?.total}</td>
                  <td className="px-2 whitespace-nowrap">{score?.grade}</td>
                </TRow>
              ))
            ) : (
              <TableNoData colSpan={tableLength} />
            )}
          </tbody>
        ) : (
          <TableLoader colSpan={tableLength} />
        )}
      </table>
    </TableWrapper>
  );
};

export default DesktopInputView;
