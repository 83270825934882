import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";

import { useGetApplicationsQuery } from "Network/admission/applications";
import { useCreateApplicantsMutation } from "Network/admission/applicants";
import useUploadFileHooks from "../../../../hooks/File/useUploadFileHooks";
import useCreateApplicantStore from "./useCreateApplicantStore";
import {
  transformCustomForm,
  getTransformedFormFields,
  applicantValidationSchema,
} from "../util";

const useCreateApplicants = () => {
  const {
    selectedGender,
    setSelectedGender,
    selectedClass,
    selectedApplication,
    setSelectedClass,
    setSelectedApplication,
    resetState,
    resetSelectedClass,
  } = useCreateApplicantStore();

  const [createApplicants, applicantsState] = useCreateApplicantsMutation();

  const applications = useGetApplicationsQuery({
    page: undefined,
    published: true,
  });

  const { resetFile } = useUploadFileHooks();

  const singleApplication = useMemo(() => {
    return (
      selectedApplication &&
      applications?.data?.data?.find(
        (application) => application?._id === selectedApplication.value,
      )
    );
  }, [selectedApplication, applications]);

  const customForm = singleApplication?.form?.data?.sections || [];

  const processedForm = useMemo(
    () => transformCustomForm({ customForm }),
    [customForm],
  );

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: selectedGender?.value,
    address: "",
    classId: "",
    applicationId: "",
    medicalInfo: "",
    photo: undefined,
    supportingDocuments: [],
    parentOrGuardianInfo: {
      fullName: "",
      relationShip: "",
      phone: "",
      email: "",
    },
  };

  const getCategoryId = useCallback(() => {
    return (
      singleApplication?.classes?.find((cl) => cl?._id === selectedClass?.value)
        ?.category?._id || undefined
    );
  }, [selectedClass?.value]);

  const getCustomFormSectionsField = useCallback(
    (formValues) =>
      getTransformedFormFields({ formValues, initialValues, processedForm }),
    [processedForm],
  );
  const handleSubmit = (values, actions) => {
    const data = {
      sectionId: selectedApplication && singleApplication?.section?._id,
      categoryId: getCategoryId(),
      ...getCustomFormSectionsField(values),
      address: values?.address || undefined,
      middleName: values.middleName || undefined,
      medicalInfo: values.medicalInfo || undefined,
    };

    createApplicants({ data })
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toast.success("Application created");
        resetState();
        resetFile();
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return {
    selectedGender,
    setSelectedGender,
    selectedApplication,
    setSelectedApplication,
    selectedClass,
    setSelectedClass,
    applications,
    singleApplication,
    initialValues,
    validationSchema: applicantValidationSchema,
    handleSubmit,
    applicantsState,
    processedForm,
    resetSelectedClass,
  };
};

export default useCreateApplicants;
