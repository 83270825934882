import { useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdDeleteForever } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import CreateGradeScale from "./CreateGradeScale";
import {
  useGetGradingScalesQuery,
  useDeleteGradingScaleMutation,
} from "Network/academics/gradeManagement/gradeScaleAcademics";
import cn from "Utils/cn";
import ConfirmDelete from "Component/ConfirmDelete";

const GradingScales = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedGradingScale, setSelectedGradingScale] = useState(null);
  const [deleteGradingScale, deleteGradingScaleState] =
    useDeleteGradingScaleMutation();

  const gradingScaleState = useGetGradingScalesQuery();

  const handleDelete = () => {
    deleteGradingScale(selectedGradingScale?._id)
      .unwrap()
      .then(() => {
        setOpenDeleteModal(false);
        toast.success("Grading scale deleted successfully");
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenDeleteModal(false);
      });
  };
  return (
    <MainLayout>
      <BodyHeaderLayout title="Grading Scales">
        <Button
          Icon={IoAddCircleOutline}
          withIcon
          className="w-fit text-sm h-[41px]"
          title="Create Grading Scale"
          handleSubmit={() => setOpenCreateModal(true)}
        />
      </BodyHeaderLayout>

      {!gradingScaleState.isFetching ? (
        gradingScaleState?.data?.length > 0 ? (
          <div className="grid grid-cols-4 sm:grid-cols-1 mt-20 gap-10">
            {gradingScaleState?.data?.map((grade, index) => (
              <ScaleTable
                data={grade}
                key={index}
                handleDelete={() => {
                  setSelectedGradingScale(grade);
                  setOpenDeleteModal(true);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="flex justify-center mt-20">
            No Grading Scales found
          </div>
        )
      ) : (
        <div className="flex justify-center mt-20">
          <CircularProgress />
        </div>
      )}

      {openCreateModal && (
        <CreateGradeScale open={openCreateModal} setOpen={setOpenCreateModal} />
      )}

      {openDeleteModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteModal}
          setOpenConfirmDelete={setOpenDeleteModal}
          title="Grading Scale"
          deleteName={selectedGradingScale?.name}
          isLoading={deleteGradingScaleState.isLoading}
          handleDelete={handleDelete}
        />
      )}
    </MainLayout>
  );
};

export default GradingScales;

const ScaleTable = ({ data, key, handleDelete }) => {
  return (
    <div key={key} className="flex flex-col border-1 text-sm">
      <div className="flex justify-between items-center border-b-1 p-2 bg-[#C8E3C1]">
        <p className="font-medium">{data?.name}</p>
        <Button
          variant="icon"
          handleSubmit={handleDelete}
          Icon={<MdDeleteForever size={20} className="text-red-500" />}
        />
      </div>

      {data?.grades?.map((grade, index) => (
        <div
          key={index}
          className={cn(
            "flex justify-between items-center  p-2",
            data.grades.length - 1 !== index && "border-b-1",
          )}
        >
          <div className="w-[33%]">
            <p className="w-[40px] h-[40px] flex items-center justify-center rounded-full border-1 text-[16px]">
              {grade?.name}
            </p>
          </div>
          <p className="w-[33%]">
            {grade?.scoreFrom}-{grade?.scoreTo}
          </p>

          <p className="w-[33%]">{grade?.remark}</p>
        </div>
      ))}
    </div>
  );
};
