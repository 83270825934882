import { useState } from "react";

import { useAsyncUploadFile } from "hooks/File/useUploadFile";
import { filesAllowed } from "Utils/constants";

const useUploadDocuments = () => {
  const [selectedFiles, setSelectedFiles] = useState({});
  const [uploadingDocument, setUploadingDocument] = useState(null);

  const [handleUpload] = useAsyncUploadFile({
    filesTypeListToAllow: [...filesAllowed, "pdf"],
  });

  const handleFileUpload = async (
    file,
    documentName,
    setFieldValue,
    values,
  ) => {
    if (!file) return;
    setUploadingDocument(documentName);

    const fileUrl = await handleUpload({
      fileUploaded: file,
      fileType: documentName,
    });

    if (fileUrl?.data?.fileUrl) {
      const updatedDocuments = values.supportingDocuments.filter(
        (doc) => doc.name !== documentName,
      );
      updatedDocuments.push({
        name: documentName,
        url: fileUrl?.data?.fileUrl,
      });

      setFieldValue("supportingDocuments", updatedDocuments);
    }

    setUploadingDocument(null);
  };

  const handleFileChange = (e, documentName) => {
    const file = e.target.files[0];
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [documentName]: file,
    }));
  };

  const handleClear = (documentName, setFieldValue, values) => {
    // Remove the file from selectedFiles state
    setSelectedFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[documentName];
      return updatedFiles;
    });

    // Remove the document from supportingDocuments in Formik
    const updatedDocuments = values.supportingDocuments.filter(
      (doc) => doc.name !== documentName,
    );
    setFieldValue("supportingDocuments", updatedDocuments);

    // Reset the file input field
    const fileInput = document.querySelector(`input[name="${documentName}"]`);
    if (fileInput) {
      fileInput.value = null;
    }
  };

  return {
    selectedFiles,
    handleFileChange,
    handleFileUpload,
    handleClear,
    uploadingDocument,
  };
};

export default useUploadDocuments;
