import { toast } from "react-toastify";

import { useDeleteApplicantsMutation } from "Network/admission/applicants";
import useGetApplicantsFilters from "./useGetApplicantsFilters";

const useDeleteApplicant = ({ id }) => {
  const { setOpenConfirmDelete } = useGetApplicantsFilters();

  const [deleteApplicant, deleteApplicantState] = useDeleteApplicantsMutation();

  const handleDeleteApplicant = () => {
    deleteApplicant({ id })
      .unwrap()
      .then((_) => {
        toast.success("Applicant Deleted Successfully");
        setOpenConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleDeleteApplicant,
    deleteApplicantState,
  };
};

export default useDeleteApplicant;
