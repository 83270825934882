import * as Yup from "yup";
import { toast } from "react-toastify";

import useUploadFileHooks from "../../../../hooks/File/useUploadFileHooks";
import useCreateStudentsStore from "./useCreateStudentsStore";
import { useGetSectionsQuery } from "Network/academics/sectionAcademics";
import { useGetClassesQuery } from "Network/academics/classAcademics";
import { useGetTermsQuery } from "Network/academics/term";
import { useGetSessionsQuery } from "Network/academics/sessionAcademics";
import { useCreateStudentMutation } from "Network/academics/studentAcademics";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required"),
  dob: Yup.date().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string(),
  enrollmentClassId: Yup.string().required("Class is required"),
  enrollmentSectionId: Yup.string().required("Section is required"),
  enrollmentSessionId: Yup.string().required("Session is required"),
  enrollmentTermId: Yup.string().required("Term is required"),
  medicalInfo: Yup.string(),
  photo: Yup.string(),
  parentOrGuardianInfo: Yup.object().shape({
    fullName: Yup.string().required("Full name of parent/guardian is required"),
    relationShip: Yup.string().required("Relationship is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  }),
});

const useCreateStudents = () => {
  const {
    selectedGender,
    setSelectedGender,
    selectedClass,
    setSelectedClass,
    selectedSection,
    setSelectedSection,
    selectedTerm,
    setSelectedTerm,
    selectedSession,
    setSelectedSession,
    resetState,
  } = useCreateStudentsStore();

  const sectionState = useGetSectionsQuery();
  const classesState = useGetClassesQuery(
    { sectionId: selectedSection.value },
    {
      skip: !selectedSection.value,
    },
  );

  const termState = useGetTermsQuery();
  const sessionState = useGetSessionsQuery();
  const [createStudent, createStudentState] = useCreateStudentMutation();

  const { resetFile } = useUploadFileHooks();

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: selectedGender?.value,
    address: "",
    enrollmentClassId: "",
    medicalInfo: "",
    enrollmentTermId: "",
    enrollmentSectionId: "",
    enrollmentSessionId: "",
    photo: undefined,
    parentOrGuardianInfo: {
      fullName: "",
      relationShip: "",
      phone: "",
      email: "",
    },
  };

  const handleSubmit = (values, actions) => {
    createStudent(values)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toast.success("Student created");
        resetState();
        resetFile();
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    selectedGender,
    setSelectedGender,
    selectedClass,
    setSelectedClass,
    selectedSection,
    setSelectedSection,
    selectedTerm,
    setSelectedTerm,
    selectedSession,
    setSelectedSession,
    handleSubmit,

    initialValues,
    validationSchema,
    sectionState,
    classesState,
    termState,
    sessionState,
    createStudentState,
  };
};

export default useCreateStudents;
