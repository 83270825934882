import { useEffect } from "react";
import { useSetTemplateMutation } from "Network/admission/configurations/template";
import { toast } from "react-toastify";

const useSetTemplate = () => {
  const [setTemplate, tempMutationState] = useSetTemplateMutation();

  const handleSetTemplate = (selected) => {
    setTemplate({ admission: selected });
  };

  useEffect(() => {
    if (tempMutationState.isError) toast.error("Something went wrong");
    if (tempMutationState.isSuccess) toast.success("New Template set");
  }, [tempMutationState]);

  return { setTemplate, tempMutationState, handleSetTemplate };
};

export default useSetTemplate;
