import React from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

import CustomModal from "Component/Forms/CustomModal";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import { useCreateCategoryMutation } from "Network/admission/classes/category";

const CreateCategory = ({ openCreateCategory, setOpenCreateCategory }) => {
  const [createCategory, categoryState] = useCreateCategoryMutation();
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Category name is required"),
  });

  const handleSubmit = (values, actions) => {
    createCategory(values)
      .unwrap()
      .then((res) => {
        toast.success("Category created");
        actions.resetForm();
        setOpenCreateCategory(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <CustomModal
      open={openCreateCategory}
      handleClose={() => setOpenCreateCategory(false)}
      modalClassName="min-h-[100px] sm:h-[165px] sm:top-32 w-[35%]"
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="flex flex-col h-full mt-5 gap-4 w-full ">
              <Input
                withTitle
                title="Category Name"
                placeholder="Example Science, Arts or Commercial"
                name="name"
                className="text-primary font-semibold"
              />

              <Button
                isLoading={categoryState.isLoading}
                handleSubmit={props.handleSubmit}
                title="Add Category"
                className="w-fit text-[12px] py-2 px-4"
              />
            </div>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default CreateCategory;
