import * as Yup from "yup";

import { useRegisterMutation } from "Network/userManagement/auth";
import { useGetPlansQuery } from "Network/userManagement/plans";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRegistration } from "../../Store/AppSlice";
import { replaceWhiteSpaces } from "Utils";

const firstScreenSchema = Yup.object().shape({
  schoolName: Yup.string().required("School name is required"),
  shortName: Yup.string().required("Short name is required"),
  address: Yup.string().required("Address is required"),
  localGovernment: Yup.string().required("Local government is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
});

const secondScreenSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Contact is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const useRegistration = () => {
  const [register, result] = useRegisterMutation();
  const { data: plans } = useGetPlansQuery();
  const dispatch = useDispatch();

  const initialValues = {
    schoolName: "",
    shortName: "",
    address: "",
    localGovernment: "",
    country: "",
    state: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };
  const validationSchemas = [firstScreenSchema, secondScreenSchema];

  const handleRegister = (values) => {
    const plan = plans?.filter(
      (planItem) => planItem?.name === "Standard Plan",
    )[0];
    const body = {
      organisationDetails: {
        name: values.schoolName,
        shortName: replaceWhiteSpaces(values?.shortName?.toLocaleLowerCase()),
        plan_id: plan?._id,
        contact: values.phone,
        address: values.address,
        state: values.state,
        country: values.country,
        localGovernment: values.localGovernment,
      },
      adminDetails: {
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        email: values.email,

        contact: values.phone,
        address: values.address,
      },
    };

    register(body)
      .unwrap()
      .then((res) => {
        toast.success("Successfull");
        dispatch(
          setRegistration({
            data: values?.shortName,
            success: true,
          }),
        );
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err?.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleRegister,
    loading: result.isLoading,
    initialValues,
    validationSchemas,
  };
};

export default useRegistration;
