import Input from "Component/Forms/Input";
import Select from "Component/Forms/Select";

const CustomFormSection = ({ customForm, props }) => {
  return (
    <div className="flex flex-col gap-10">
      {customForm.map((form, index) => (
        <div key={index} className="flex flex-col gap-1">
          <h3 className="font-bold sm:text-[14px] sm:font-semibold">
            {form?.sectionName}
          </h3>

          <div className="flex flex-col gap-4">
            {form?.fieldTypes.map((field, idx) => {
              if (field?.fieldType === "text") {
                return (
                  <Input
                    key={idx}
                    withTitle
                    title={field?.title}
                    placeholder={`Enter ${field?.title}`}
                    name={field?.fieldName}
                  />
                );
              }
              if (field?.fieldType === "select") {
                return (
                  <Select
                    key={idx}
                    withLabel
                    label={field?.title}
                    value={{
                      value:
                        props?.values[form?.sectionKey]?.[field?.fieldKey] ||
                        null,
                      label:
                        props?.values[form?.sectionKey]?.[field?.fieldKey] ||
                        "None",
                    }}
                    data={[
                      { value: null, label: "None" },
                      ...field.options.map((option) => ({
                        value: option,
                        label: option,
                      })),
                    ]}
                    onChangeOption={(x) => {
                      props.setFieldValue(field?.fieldName, x?.value);
                    }}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomFormSection;
