import { useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import { academicFlattenRoutes } from "Routes/academicRoutes";
import CustomSearch from "Component/CustomSearch";
import StudentDeskTopView from "./View/StudentDesktopView";
import StudentMobileView from "./View/StudentMobileView";
import Pagination from "Component/Pagination/Pagination";
import SelectedMenu from "./Manage/SelectedMenu";
import { createSelectionStore } from "Component/ActionMenuWrapper";
import BulkSelection from "./Manage/BulkSelection";
import { useGetStudentsQuery } from "Network/academics/studentAcademics";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";
import ConfirmDelete from "Component/ConfirmDelete";
import ConfirmBox from "Component/ConfirmBox";
import useStudentActionStore from "./hooks/useStudentActionStore";
import useDeleteStudent from "./hooks/useDeleteStudent";
import useUpdateStudentStatus from "./hooks/useUpdateStudentStatus";
import GenerateReport from "./Manage/GenerateReport";

const Students = () => {
  const useSelectApplicantStore = createSelectionStore();
  const params = useParams();
  const navigate = useNavigate();

  const {
    openConfirmDelete,
    setOpenConfirmDelete,

    openReportModal,
    setOpenReportModal,

    openUpdateStatusModal,
    setOpenUpdateStatusModal,

    selectedStudent,
    openBulkSelection,
    setOpenBulkSelection,
  } = useStudentActionStore();

  const { handleDeleteStudent, deleteStudentState } = useDeleteStudent();
  const { handleUpdateStudentStatus, updateStudentStatusState } =
    useUpdateStudentStatus();

  const { page, search, handleSearch, setPage } = usePaginationAndSearch();
  const studentsState = useGetStudentsQuery({ page, search });

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Students"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Add Students"
            handleSubmit={() =>
              navigate(
                `/${params.shortName}/${academicFlattenRoutes.createStudent}`,
              )
            }
          />
        </div>
      </BodyHeaderLayout>

      <div className="flex justify-between sm:flex-col mb-[20px] bg-white p-3">
        <CustomSearch
          className="w-[40%] sm:w-[100%] text-[14px]  "
          placeholderText="Search by name, section, or class"
          handleChange={(e) => handleSearch(e.target.value)}
        />

        <Button
          title="Bulk Selection"
          className="text-sm"
          handleSubmit={() => setOpenBulkSelection(true)}
        />
      </div>

      <StudentDeskTopView
        useSelectApplicantStore={useSelectApplicantStore}
        studentsState={studentsState}
      />
      <StudentMobileView
        useSelectApplicantStore={useSelectApplicantStore}
        studentsState={studentsState}
      />

      <div className="flex w-full justify-end mt-2">
        <Pagination
          onPageChange={setPage}
          pageTotal={studentsState?.data?.totalPages}
          initialPage={studentsState?.data?.page}
        />
      </div>

      <SelectedMenu useSelectApplicantStore={useSelectApplicantStore} />

      {openBulkSelection && (
        <BulkSelection
          open={openBulkSelection}
          setOpen={setOpenBulkSelection}
        />
      )}

      {openReportModal && (
        <GenerateReport open={openReportModal} setOpen={setOpenReportModal} />
      )}

      {openConfirmDelete && (
        <ConfirmDelete
          openConfirmDelete={openConfirmDelete}
          setOpenConfirmDelete={setOpenConfirmDelete}
          title="Student"
          deleteName={selectedStudent?.studentNumber}
          handleDelete={handleDeleteStudent}
          isLoading={deleteStudentState.isLoading}
        />
      )}

      {openUpdateStatusModal && (
        <ConfirmBox
          openConfirmBox={openUpdateStatusModal}
          setOpenConfirmBox={setOpenUpdateStatusModal}
          title="Update Status"
          isLoading={updateStudentStatusState.isLoading}
          handleConfirm={handleUpdateStudentStatus}
        />
      )}
    </MainLayout>
  );
};

export default Students;
