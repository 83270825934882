import { MdDelete } from "react-icons/md";

import AccordionDetailsCard from "Component/Cards/AccordionDetailsCard";
import Button from "Component/Forms/Button";
import useSubjectAssignmentStore from "../hooks/useAssignmentStore";

const ClassCard = ({ data }) => {
  const { setConfirmRemoveAll, setConfirmRemoveSingle, setSelectedCurriculum } =
    useSubjectAssignmentStore();

  return (
    <AccordionDetailsCard
      title={data?.class?.name}
      count={data?.subjects?.length}
      countName={"Total Subjects"}
    >
      <div>
        <div
          className=" flex flex-col gap-3 max-h-[300px] overflow-y-auto ml-2"
          style={{ scrollbarWidth: "thin" }}
        >
          {data?.subjects?.map((subject, index) => (
            <div key={index} className="flex justify-between items-center">
              <p>{subject?.name}</p>

              <Button
                Icon={<MdDelete />}
                disabled
                variant="icon"
                className="text-red-500"
                handleSubmit={() => setConfirmRemoveSingle(true)}
              />
            </div>
          ))}
        </div>

        <div className="flex gap-2 w-full justify-end border-t-1 mt-3">
          <Button
            Icon={<MdDelete />}
            variant="icon"
            className="text-red-500"
            handleSubmit={() => {
              setConfirmRemoveAll(true);
              setSelectedCurriculum(data);
            }}
          />
        </div>
      </div>
    </AccordionDetailsCard>
  );
};

export default ClassCard;
