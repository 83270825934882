/** eslint-disable no-unneeded-ternary  */

import dayjs from "dayjs";
import { Checkbox } from "@radix-ui/themes";

import {
  TableWrapper,
  THead,
  TRow,
  TableLoader,
  TableNoData,
} from "Component/Table";
import Actions from "../Manage/Actions";
import Avatar from "Component/Avatar";
import StatusIdentifier from "Component/StatusIdentifier";

const ApplicantDeskTopView = ({ applicantsState, useSelectApplicantStore }) => {
  const { data: applicants, isFetching } = applicantsState;

  const { selectedItems, handleItemSelection, handleSelectAll, selectAll } =
    useSelectApplicantStore();

  return (
    <div>
      <TableWrapper>
        <table className="w-full">
          <THead className="w-full">
            <td className="flex justify-center py-5 min-w-[2%]">
              <Checkbox
                checked={selectAll}
                onCheckedChange={(check) =>
                  handleSelectAll(check, applicants?.data)
                }
              />
            </td>
            <td className="w-[2%]">S/N</td>

            <td className="w-[20%] px-2">Applicant</td>
            <td className="w-[10%] px-2">Section</td>
            <td className="w-[10%] px-2">Class</td>
            <td className="w-[12%] px-2">Payment Type</td>
            <td className="w-[14%] px-2">Payment Status</td>
            <td className="w-[12%] px-2">Date</td>
            <td className="w-[14%] px-2">Admission Status</td>
            <td className="w-[2%] pr-2 ">Actions</td>
          </THead>
          {!isFetching ? (
            <tbody>
              {applicants?.data?.length !== 0 ? (
                applicants?.data?.map((applicant, index) => (
                  <TRow key={index}>
                    <td className="px-6 py-5">
                      <Checkbox
                        checked={selectedItems?.some(
                          (id) => id === applicant?._id,
                        )}
                        onCheckedChange={(check) =>
                          handleItemSelection(check, applicant)
                        }
                      />
                    </td>

                    <td className="px-6 py-5">{index + 1}</td>
                    <td className="flex gap-2 items-center py-2 px-2">
                      <Avatar image={applicant?.photo} />
                      <div className="flex flex-col">
                        <span className="text-[12] mt-[3px] capitalize">
                          {`${applicant?.firstName} ${applicant?.middleName || ""} ${applicant?.lastName}`}
                        </span>
                        <span className="text-[8] font-extralight text-primary  rounded w-fit p-[2px]">
                          {applicant?.applicantNumber || "Applicant ID"}
                        </span>
                      </div>
                    </td>
                    <td className="px-2">{applicant?.section?.name}</td>
                    <td className="px-2">{applicant?.class?.name}</td>
                    <td className="px-2">
                      {applicant?.application?.paymentMode}
                    </td>
                    <td className="px-2">
                      <StatusIdentifier status={applicant?.payment?.status} />
                    </td>
                    <td className="px-2">
                      {dayjs(applicant?.createdAt).format("DD MMM YYYY")}
                    </td>
                    <td className="px-2">
                      <StatusIdentifier status={applicant?.admissionStatus} />
                    </td>
                    <td>
                      <Actions data={applicant} />
                    </td>
                  </TRow>
                ))
              ) : (
                <TableNoData colSpan={9} />
              )}
            </tbody>
          ) : (
            <TableLoader colSpan={9} />
          )}
        </table>
      </TableWrapper>
    </div>
  );
};

export default ApplicantDeskTopView;
