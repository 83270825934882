import { useState } from "react";
import { toast } from "react-toastify";

import {
  useGetApplicantQuery,
  useUpdateApplicantsStatusMutation,
} from "Network/admission/applicants";

const useUpdateApplicantStatus = ({ id }) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const { data, isLoading, isFetching } = useGetApplicantQuery(id);

  const [updateStatus, { isLoading: isLoadingUpdateStatus }] =
    useUpdateApplicantsStatusMutation();

  const handleUpdateStatus = (status) => {
    updateStatus({ applicantsIds: [data?._id], status })
      .unwrap()
      .then((_) => {
        toast.success("Status Updated Successfully");
        setOpenApprove(false);
        setOpenReject(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    openApprove,
    setOpenApprove,
    openReject,
    setOpenReject,
    handleUpdateStatus,
    isFetching,
    isLoading,
    isLoadingUpdateStatus,
    data,
  };
};

export default useUpdateApplicantStatus;
