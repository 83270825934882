import cn from "Utils/cn";
import {
  AccordionRoot,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "Component/Accordion";

const AccordionDetailsCard = ({
  title,
  subTitle,
  count,
  countName,
  children,
}) => {
  return (
    <AccordionRoot defaultValue={0}>
      <AccordionItem value="0">
        <AccordionTrigger
          className={" flex-col-reverse gap-1 py-2 border-0 rounded-t-lg"}
          arrowClassName={cn(
            "justify-end ",
            subTitle && "flex justify-between text-xs",
          )}
          accordionTriggerTitle={subTitle}
        >
          <div className="flex justify-between items-center w-full text-xs ">
            <p>{title}</p>
            <div className="flex items-center gap-3">
              <p>{countName}</p>
              <p className="px-2 py-[6px] bg-[#B6EBAB] rounded-full border-1">
                {count}
              </p>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent className="bg-white">{children}</AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  );
};

export default AccordionDetailsCard;
