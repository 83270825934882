import { CircularProgress } from "@mui/material";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import { IoAddCircleOutline } from "react-icons/io5";
import Button from "Component/Forms/Button";
import CreateRole from "./CreateRole";
import { useGetRolesQuery } from "Network/userManagement/userManagementRole";
import OneFieldUpdate from "Screens/AdmissionScreen/Settings/component/OneFieldUpdate";
import ConfirmDelete from "Component/ConfirmDelete";
import useCreateRole from "./hooks/useCreateRole";
import RoleCard from "./RoleCard";
import AssignPermissionToRole from "./AssignPermissionToRole";
import useRoleStore from "./hooks/useRoleStore";
import usePermission from "./hooks/usePermission";

const RoleManagement = () => {
  const {
    openCreateModal,
    setOpenCreateModal,
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    selectedRole,
    selectedRolePermission,
    openDeleteRolePermissionModal,
    setOpenDeleteRolePermissionModal,

    handleUpdate,
    editRoleState,
    handleDelete,
    deleteRoleState,
  } = useCreateRole();

  const { deletePermissionState, handleDeletePermission } = usePermission();

  const { openAssignPermissionModal, setOpenAssignPermissionModal } =
    useRoleStore();

  const roleState = useGetRolesQuery({});
  return (
    <MainLayout>
      <BodyHeaderLayout title="Roles">
        <Button
          Icon={IoAddCircleOutline}
          withIcon
          className="w-fit text-sm h-[41px]"
          title="Create Role"
          handleSubmit={() => setOpenCreateModal(true)}
        />
      </BodyHeaderLayout>

      <div className="relative pb-10 mt-10">
        {!roleState?.isFetching ? (
          roleState?.data?.length > 0 ? (
            <div
              className="grid grid-cols-4 sm:grid-cols-1 gap-5 max-h-[70vh] overflow-y-auto"
              style={{ scrollbarWidth: "none" }}
            >
              {roleState?.data?.map((role, index) => (
                <div key={index}>
                  <RoleCard data={role} />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex justify-center">No Data</div>
          )
        ) : (
          <div className="flex justify-center mt-10">
            <CircularProgress />
          </div>
        )}
      </div>

      {openCreateModal && (
        <CreateRole open={openCreateModal} setOpen={setOpenCreateModal} />
      )}

      {openAssignPermissionModal && (
        <AssignPermissionToRole
          open={openAssignPermissionModal}
          setOpen={setOpenAssignPermissionModal}
        />
      )}

      {openEditModal && (
        <OneFieldUpdate
          openUpdateModal={openEditModal}
          setOpenUpdateModal={setOpenEditModal}
          title="Role"
          label="Role Name"
          value={selectedRole?.name}
          handleUpdate={(e) => handleUpdate(e)}
          isLoading={editRoleState.isLoading}
        />
      )}

      {openDeleteModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteModal}
          setOpenConfirmDelete={setOpenDeleteModal}
          title={"Role"}
          isLoading={deleteRoleState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      {openDeleteRolePermissionModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteRolePermissionModal}
          setOpenConfirmDelete={setOpenDeleteRolePermissionModal}
          title={`${selectedRolePermission?.name} Permission From ${selectedRole?.name}`}
          isLoading={deletePermissionState.isLoading}
          handleDelete={handleDeletePermission}
        />
      )}
    </MainLayout>
  );
};

export default RoleManagement;
