import {
  MobileTableWrapper,
  MobileTRow,
  MobileTableLoader,
  MobileTableNoData,
} from "Component/Table/Mobile";
import InputField from "./components/InputField";

const MobileInputView = ({
  scoreSheets,
  isLoading,
  isFetching,
  currentField,
  getMaxValueForAssessment,
  handleSaveScore,
}) => {
  if (isLoading) {
    return (
      <MobileTableWrapper className={"hidden sm:block"}>
        <MobileTableLoader />
      </MobileTableWrapper>
    );
  }

  return (
    <div>
      {!isLoading ? (
        scoreSheets.length > 0 ? (
          <MobileTableWrapper className={"hidden sm:block"}>
            {scoreSheets.map((score, index) => (
              <div
                key={index}
                className="flex gap-5 px-3 py-2 rounded bg-gray-100  shadow-small-select  text-[14px]  "
              >
                <div className="bg-white p-3 w-full">
                  <table className="w-full">
                    <MobileTRow title={"S/N"} text={index + 1} />

                    <MobileTRow title={"Student Name"}>
                      <div className="flex flex-col gap-1">
                        <p className="capitalize text-sm">
                          {`${score?.student?.firstName} ${score?.student?.middleName || ""} ${score?.student?.lastName}`}
                        </p>
                        <p className="text-[10px]">
                          {score?.student?.studentNumber}
                        </p>
                      </div>
                    </MobileTRow>

                    {score?.assessments?.map((assessment) => {
                      const maxValue = getMaxValueForAssessment(
                        assessment?.name,
                      );

                      return (
                        <MobileTRow title={assessment?.name}>
                          <>
                            <InputField
                              currentField={currentField}
                              assessment={assessment}
                              handleSaveScore={handleSaveScore}
                              maxValue={maxValue}
                              score={score}
                              isFetching={isFetching}
                            />
                            <span className="text-xs text-gray-500 ml-1">
                              /{maxValue}
                            </span>
                          </>
                        </MobileTRow>
                      );
                    })}

                    <MobileTRow title={"Total"} text={score?.total} />
                    <MobileTRow title={"Grade"} text={score?.grade} />
                  </table>
                </div>
              </div>
            ))}
          </MobileTableWrapper>
        ) : (
          <MobileTableNoData />
        )
      ) : (
        <MobileTableLoader />
      )}
    </div>
  );
};

export default MobileInputView;
