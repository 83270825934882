import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import useAssignmentStore from "./useAssignmentStore";
import { useUpdateStaffMutation } from "Network/academics/staffAcademics";
import { transformAssignedSubjectsBeforeEdit } from "Screens/AcademicScreens/Staff/lib";

const useAssignSubjectToTeacher = () => {
  const {
    selectedStaffSingleSubject,
    selectedStaffAllSubjects,
    setConfirmRemoveSingle,
    setConfirmRemoveAll,
  } = useAssignmentStore();

  const [deleteSubject, deleteSubjectState] = useUpdateStaffMutation();

  const removeDeleteSubject = (
    currentSubjects,
    subjectToDeleteClassName,
    subjectToDelete,
  ) => {
    return currentSubjects.map((item) => {
      // Check if this is the class we want
      if (item.class.name === subjectToDeleteClassName) {
        // If yes, filter out the subject with matching _id
        return {
          ...item,
          subjects: item.subjects.filter(
            (subject) => subject._id !== subjectToDelete._id,
          ),
        };
      }
      // If not the target class, return unchanged
      return item;
    });
  };

  const handleDeleteSingleSubject = () => {
    const subjectToDelete = selectedStaffSingleSubject;
    const currentSubjects = selectedStaffAllSubjects?.subjects || [];
    const subjectToDeleteClassName = selectedStaffAllSubjects.className;

    const updatedSubjects = removeDeleteSubject(
      currentSubjects,
      subjectToDeleteClassName,
      subjectToDelete,
    );

    const data = {
      subjects: transformAssignedSubjectsBeforeEdit(updatedSubjects),
    };

    deleteSubject({ data, id: selectedStaffAllSubjects._id })
      .unwrap()
      .then((_) => {
        toastify.success("Subject removed");
        setConfirmRemoveSingle(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleDeleteAllSubject = () => {
    const data = { subjects: [] };

    deleteSubject({ data, id: selectedStaffAllSubjects._id })
      .unwrap()
      .then((_) => {
        toastify.success("Removed all subjects");
        setConfirmRemoveAll(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleDeleteSingleSubject,
    handleDeleteAllSubject,
    deleteSubjectState,
  };
};

export default useAssignSubjectToTeacher;
