import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";

const endpoint = "role-permissions";

const userPermissions = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPermissions: builder.query({
      query: () => ({
        url: `/permissions`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ROLE_PERMISSIONS,
                id,
              })),
              { type: tags.ROLE_PERMISSIONS, id: "LIST" },
            ]
          : [{ type: tags.ROLE_PERMISSIONS, id: "LIST" }],
    }),

    getAllRolePermissions: builder.query({
      query: () => ({
        url: `/${endpoint}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ROLE_PERMISSIONS,
                id,
              })),
              { type: tags.ROLE_PERMISSIONS, id: "LIST" },
            ]
          : [{ type: tags.ROLE_PERMISSIONS, id: "LIST" }],
    }),

    assignPermissionToRole: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [
        { type: tags.ROLE_PERMISSIONS, id: "LIST" },
        { type: tags.USER_MANAGEMENT_ROLE, id: "LIST" },
      ],
    }),

    deletePermissionFromRole: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: tags.ROLE_PERMISSIONS, id: "LIST" },
        { type: tags.USER_MANAGEMENT_ROLE, id: "LIST" },
      ],
    }),

    updatePermissionRole: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [
        { type: tags.ROLE_PERMISSIONS, id: "LIST" },
        { type: tags.USER_MANAGEMENT_ROLE, id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetAllPermissionsQuery,
  useGetAllRolePermissionsQuery,
  useAssignPermissionToRoleMutation,
  useDeletePermissionFromRoleMutation,
  useUpdatePermissionRoleMutation,
} = userPermissions;
