import userMangementAPi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "users";

const users = userMangementAPi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page, search, limit }) => {
        const url = `/${endpoint}/organisation/${getSchoolId()}`;

        const params = { limit };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        return { url, params };
      },

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_STAFF,
                id,
              })),
              { type: tags.ACADEMICS_STAFF, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_STAFF, id: "LIST" }],
    }),

    getUser: builder.query({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: tags.ACADEMICS_STAFF, id }],
    }),
  }),
});

export const { useGetUsersQuery, useGetUserQuery } = users;
