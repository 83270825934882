import { useEffect } from "react";
import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import SelectClass from "./View/SelectClass";
import SelectSubject from "./View/SelectSubject";
import InputScores from "./View/InputScores";
import useScoreEntryStore from "../hooks/useScoreEntryStore";
import { getUserId } from "Network/utils";
import { useGetStaffByUserIdQuery } from "Network/academics/staffAcademics";
import usePermissionStore from "hooks/Auth/usePermissionStore";
import { PERMISSIONS_LISTS } from "Utils/constants";

const InputAssessment = () => {
  const { resetSelections } = useScoreEntryStore();
  const { hasAnyPermission } = usePermissionStore();

  const isAdmin = hasAnyPermission([
    PERMISSIONS_LISTS.ADMIN,
    PERMISSIONS_LISTS.ORGANISATION_ADMIN,
  ]);

  const staffState = useGetStaffByUserIdQuery(getUserId(), {
    skip: isAdmin,
  });

  useEffect(() => {
    resetSelections();
  }, []);

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Input Assessment"} />
      <div className="mt-10 flex flex-col gap-10">
        <SelectClass staffState={staffState} />
        <SelectSubject staff={staffState?.data} />
        <InputScores />
      </div>
    </MainLayout>
  );
};

export default InputAssessment;
