import admissionApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const application = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    getApplications: builder.query({
      query: ({ page, search, published, sessionId }) => {
        const url = `/applications/schools/${getSchoolId()}`;

        const params = {};

        if (page !== undefined) {
          params.page = page;
        }

        if (published !== undefined) {
          params.published = published;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        if (sessionId !== undefined && sessionId !== "") {
          params.sessionId = sessionId;
        }

        return { url, params };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: tags.SCHOOL_APPLICATIONS,
                id,
              })),
              { type: tags.SCHOOL_APPLICATIONS, id: "LIST" },
            ]
          : [{ type: tags.SCHOOL_APPLICATIONS, id: "LIST" }],
    }),

    getApplicationsByShortName: builder.query({
      query: ({ page, search, shortName }) => {
        const url = `/applications/schools/shortName/${shortName}`;

        const params = { published: true };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }
        return { url, params };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: tags.SCHOOL_APPLICATIONS,
                id,
              })),
              { type: tags.SCHOOL_APPLICATIONS, id: "LIST" },
            ]
          : [{ type: tags.SCHOOL_APPLICATIONS, id: "LIST" }],
    }),

    getApplication: builder.query({
      query: (id) => ({
        url: `/applications/${id}`,
      }),
      providesTags: (result, error, id) => [
        { type: tags.SCHOOL_APPLICATIONS, id },
      ],
    }),

    createApplication: builder.mutation({
      query: (data) => ({
        url: "/applications",
        method: "POST",
        body: {
          ...data,
          schoolId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.SCHOOL_APPLICATIONS, id: "LIST" }],
    }),

    editApplication: builder.mutation({
      query: ({ data, id }) => ({
        url: `/applications/${id}`,
        method: "PUT",
        body: {
          ...data,
          schoolId: getSchoolId(),
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_APPLICATIONS, id: arg._id },
      ],
    }),

    deleteApplication: builder.mutation({
      query: ({ id }) => ({
        url: `/applications/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_APPLICATIONS, id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetApplicationsByShortNameQuery,
  useCreateApplicationMutation,
  useDeleteApplicationMutation,
  useEditApplicationMutation,
  useGetApplicationQuery,
} = application;
