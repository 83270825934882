import { useState } from "react";
import { toast } from "react-toastify";

import useScoreEntryStore from "./useScoreEntryStore";
import { useGetCurrentSessionAndTermQuery } from "Network/academics/sessionAcademics";
import {
  useGetScoreSheetsQuery,
  useSaveScoreMutation,
} from "Network/academics/scoreAcademics";
import { useGetAssessmentQuery } from "Network/academics/gradeManagement/assessmentComponentAcademics";

const useInputScore = () => {
  const { selectedClass, selectedSubject, sectionData, classData } =
    useScoreEntryStore();

  const sessionState = useGetCurrentSessionAndTermQuery();

  const skipCondition =
    !selectedClass?.value || sessionState?.isLoading || !selectedSubject;

  const sheetState = useGetScoreSheetsQuery(
    {
      classId: selectedClass?.value,
      sessionId: sessionState?.data?.currentSession?._id,
      termId: sessionState?.data?.currentTerm?._id,
      subjectId: selectedSubject?._id,
    },
    {
      skip: skipCondition,
    },
  );

  const assessmentState = useGetAssessmentQuery(
    sectionData?.assessmentStructureId,
    {
      skip: skipCondition,
      keepUnusedDataFor: 0,
    },
  );

  const [saveScore] = useSaveScoreMutation();
  const [currentField, setCurrentField] = useState(null);

  const handleSaveScore = (e, maxValue, assessment, score) => {
    const value = Number(e.target.value);
    let newValue = value;
    if (value > maxValue) {
      newValue = maxValue;
      e.target.value = maxValue;
    }
    const data = {
      _id: score?._id,
      average: score?.average,
      total: score?.total,
      studentId: score?.studentId,
      classId: score?.classId,
      subjectId: score?.subjectId,
      sessionId: score?.sessionId,
      termId: score?.termId,
      sectionId: score?.sectionId,
      remark: score?.remark,
      organisationId: score?.organisationId,
      grade: score?.grade,
      assessments: score?.assessments.map((ass) => {
        if (ass?.name === assessment?.name) {
          return {
            ...ass,
            value: newValue,
          };
        }
        return ass;
      }),
    };

    setCurrentField(assessment);

    saveScore({ data, id: score?._id })
      .unwrap()
      .then(() => {
        setCurrentField(null);
        e.target.value = null;
      })
      .catch((err) => {
        toast.error(err.data?.message);
        setCurrentField(null);
      });
  };

  const assessments = assessmentState?.data?.assessments || [];
  const scoreSheets = sheetState?.data || [];

  const getMaxValueForAssessment = (assessmentName) => {
    return (
      assessments.find((ass) => ass?.name === assessmentName)?.maxValue || 0
    );
  };

  const isLoading = sheetState.isLoading || assessmentState.isLoading;
  const isFetching = sheetState.isFetching || assessmentState.isFetching;
  const isError = sheetState.isError || assessmentState.isError;

  return {
    selectedClass,
    selectedSubject,
    sectionData,
    classData,
    scoreSheets,
    currentField,
    assessments,
    assessmentState,

    getMaxValueForAssessment,
    handleSaveScore,

    isLoading,
    isFetching,
    isError,
  };
};

export default useInputScore;
