import { CircularProgress } from "@mui/material";

import DesktopInputView from "./DesktopInputView";
import MobileInputView from "./MobileInputView";

import useInputScore from "Screens/AcademicScreens/Assessment/hooks/useInputScore";

const InputScores = () => {
  const {
    selectedSubject,
    classData,
    scoreSheets,
    currentField,
    assessments,
    assessmentState,

    getMaxValueForAssessment,
    handleSaveScore,

    isLoading,
    isFetching,
    isError,
  } = useInputScore();

  if (selectedSubject === null) {
    return null;
  }
  if (isLoading) {
    return <div className="p-5 bg-white rounded">Loading...</div>;
  }

  if (isError) {
    return (
      <div className="p-5 bg-white rounded text-red-500">
        Error loading data
      </div>
    );
  }

  return (
    <div className="p-5 bg-white rounded flex flex-col gap-4 w-full">
      <div className="grid grid-cols-2">
        {!isLoading && (
          <p className="text-sm font-semibold text-nowrap">
            Enter Scores for {selectedSubject?.name} - {classData?.name}
          </p>
        )}

        {isFetching && (
          <div className="flex justify-end">
            <CircularProgress size={20} />
          </div>
        )}
      </div>

      <div className="sm:hidden">
        <DesktopInputView
          scoreSheets={scoreSheets}
          assessments={assessments}
          isLoading={isLoading}
          isFetching={isFetching}
          currentField={currentField}
          getMaxValueForAssessment={getMaxValueForAssessment}
          handleSaveScore={handleSaveScore}
          tableLength={assessmentState?.data?.assessments?.length || 3}
        />
      </div>

      <div className="hidden sm:block">
        <MobileInputView
          scoreSheets={scoreSheets}
          assessments={assessments}
          isLoading={isLoading}
          isFetching={isFetching}
          currentField={currentField}
          getMaxValueForAssessment={getMaxValueForAssessment}
          handleSaveScore={handleSaveScore}
        />
      </div>
    </div>
  );
};

export default InputScores;
