import { create } from "zustand";

const useStaffActionStore = create((set) => ({
  openConfirmDelete: false,
  openEdit: false,
  openBulkSelection: false,
  selectedStaff: null,
  openUpdateStatusModal: false,
  openAssignRoleModal: false,
  openAssignClassModal: false,
  openAssignSubjectModal: false,

  setOpenConfirmDelete: (openConfirmDelete) => set({ openConfirmDelete }),
  setOpenEdit: (openEdit) => set({ openEdit }),
  setOpenBulkSelection: (openBulkSelection) => set({ openBulkSelection }),
  setSelectedStaff: (selectedStaff) => set({ selectedStaff }),
  setOpenReportModal: (openReportModal) => set({ openReportModal }),
  setOpenUpdateStatusModal: (openUpdateStatusModal) =>
    set({ openUpdateStatusModal }),

  setOpenAssignRoleModal: (openAssignRoleModal) => set({ openAssignRoleModal }),
  setOpenAssignClassModal: (openAssignClassModal) =>
    set({ openAssignClassModal }),

  setOpenAssignSubjectModal: (openAssignSubjectModal) =>
    set({ openAssignSubjectModal }),
}));

export default useStaffActionStore;
