import userMangementAPi from "./apiSlice";

const plans = userMangementAPi.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: () => ({
        url: "/plans",
      }),
    }),
  }),
});

export const { useGetPlansQuery } = plans;
