import { create } from "zustand";
import { genderList, termList } from "Utils/constants";

const initialState = {
  selectedGender: genderList[0],
  selectedClass: { value: null, label: "Select Class" },
  selectedSection: { value: null, label: "Select Section" },
  selectedTerm: { value: null, label: "Select Term" },
  selectedSession: { value: null, label: "Select Session" },
};

const useCreateStudentsStore = create((set) => ({
  ...initialState, // Spread initial state to set default values

  setSelectedGender: (gender) => set({ selectedGender: gender }),

  setSelectedTerm: (term) => set({ selectedTerm: term }),

  setSelectedSession: (session) => set({ selectedSession: session }),

  setSelectedClass: (classValue) => set({ selectedClass: classValue }),
  resetSelectedClass: () => set({ selectedClass: initialState.selectedClass }),

  setSelectedSection: (sectionValue) => set({ selectedSection: sectionValue }),
  resetSelectedSection: () =>
    set({ selectedSection: initialState.selectedSection }),

  resetState: () => set({ ...initialState }), // Reset to initial state
}));

export default useCreateStudentsStore;
