import { useState } from "react";
import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import Select from "Component/Forms/Select";
import { transformClassOption, transformStaffOption } from "Utils/transform";
import Button from "Component/Forms/Button";
import useAssignClass from "Screens/AcademicScreens/Staff/hooks/useAssignClass";

const AssignClassToTeacher = ({ open, setOpen }) => {
  const [selectedTeacher, setSelectedTeacher] = useState({
    value: null,
    label: "Select Teacher",
  });
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    selectedClasses,
    setSelectedClasses,
    assignClassState,
    classState,
    staffState,
    setSelectedStaff,
  } = useAssignClass();

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      className={"min-w-[40%] "}
      title="Assign Class to a Teacher"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-3 w-full mt-2">
            <Select
              withLabel
              label="Teacher"
              data={transformStaffOption(staffState?.data?.staff)}
              value={selectedTeacher}
              isLoading={staffState.isLoading}
              onChangeOption={(x) => {
                setSelectedTeacher(x);
                setSelectedStaff(
                  staffState?.data?.staff.find((each) => each._id === x.value),
                );
              }}
            />
            <Select
              withLabel
              label="Classes"
              data={transformClassOption(classState.data?.data)}
              value={selectedClasses}
              selectMultiple
              className={`${props.errors?.classIds && "border-[red] "}`}
              isLoading={classState.isLoading}
              onChangeOption={(x) => {
                props.setFieldValue(
                  "classIds",
                  x.map((eachClass) => eachClass?.value),
                );
                setSelectedClasses(x);
              }}
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[25%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Assign Class"
                disabled={selectedTeacher.value === null}
                handleSubmit={props.handleSubmit}
                isLoading={assignClassState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AssignClassToTeacher;
