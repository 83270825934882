import { Checkbox } from "@radix-ui/themes";

import {
  MobileTableWrapper,
  MobileTRow,
  MobileTableLoader,
} from "Component/Table/Mobile";
import Actions from "../Manage/Actions";
import Avatar from "Component/Avatar";
import StatusIdentifier from "Component/StatusIdentifier";

const StudentMobileView = ({ useSelectApplicantStore, studentsState }) => {
  const { selectedItems, handleItemSelection, handleSelectAll, selectAll } =
    useSelectApplicantStore();
  return (
    <div>
      {!studentsState.isFetching ? (
        <div>
          <div className="sm:flex gap-3 items-center mb-3 text-sm hidden">
            <p>Select All:</p>
            <Checkbox
              checked={selectAll}
              // onCheckedChange={(check) => handleSelectAll(check, applicants)}
            />
          </div>
          <MobileTableWrapper>
            {studentsState?.data?.students?.map((data, index) => (
              <div
                key={index}
                className="flex gap-5 px-3 py-2 rounded bg-white  shadow-small-select  text-[14px]  "
              >
                <table className="w-full">
                  <MobileTRow
                    title={"Applicant ID"}
                    actionTrigger={
                      <div>
                        <Actions data={data} />
                        <Checkbox
                        // checked={selectedItems.some(
                        //   (id) => id === applicant?._id,
                        // )}
                        // onCheckedChange={(check) =>
                        //   handleItemSelection(check, applicant)
                        // }
                        />
                      </div>
                    }
                  >
                    <div className="flex gap-2 items-center py-[6px] w-full h-full">
                      <Avatar image={data?.photo} />
                      <span className="">{data?.studentNumber}</span>
                    </div>
                  </MobileTRow>

                  <MobileTRow
                    action
                    title={"Name"}
                    text={`${data?.firstName} ${data?.middleName || ""} ${data?.lastName}`}
                  />

                  <MobileTRow title={"Gender"} text={data?.gender} />

                  <MobileTRow title={"Section"} text={data?.section?.name} />

                  <MobileTRow title={"Class"} text={data?.class?.name} />

                  <MobileTRow title={"Status"}>
                    <StatusIdentifier status={data?.status} />
                  </MobileTRow>
                </table>
              </div>
            ))}
          </MobileTableWrapper>
        </div>
      ) : (
        <MobileTableLoader />
      )}
    </div>
  );
};

export default StudentMobileView;
