import { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import useStaffActionStore from "./useStaffActionStore";
import useAssignmentStore from "Screens/AcademicScreens/SubjectsManagement/hooks/useAssignmentStore";
import { useGetSubjectsQuery } from "Network/academics/subjectAcademics";
import { useGetClassesQuery } from "Network/academics/classAcademics";
import {
  useGetStaffQuery,
  useUpdateStaffMutation,
} from "Network/academics/staffAcademics";
import { transformAssignedSubjectsBeforeEdit } from "../lib";

const validationSchema = Yup.object().shape({
  subjects: Yup.array()
    .of(
      Yup.object().shape({
        classId: Yup.string().required("Class is required"),
        subjectIds: Yup.array()
          .of(Yup.string())
          .min(1, "At least one subject is required")
          .required("Subjects are required"),
      }),
    )
    .min(1, "At least one class assignment is required"),
});

const useAssignSubject = () => {
  // Keep track of multiple selected class-subject pairs
  const [classPairs, setClassPairs] = useState([
    { index: 0, selectedClass: null, selectedSubjects: [] },
  ]);

  const subjectState = useGetSubjectsQuery();
  const classState = useGetClassesQuery({});
  const staffState = useGetStaffQuery({});

  const [assignSubject, assignSubjectState] = useUpdateStaffMutation();

  const { selectedStaff, setSelectedStaff, setOpenAssignSubjectModal } =
    useStaffActionStore();
  const { setOpenModal } = useAssignmentStore();

  const initialValues = {
    subjects: [
      {
        classId: "",
        subjectIds: [],
      },
    ],
  };

  const addClassPair = () => {
    setClassPairs([
      ...classPairs,
      {
        index: classPairs.length,
        selectedClass: null,
        selectedSubjects: [],
      },
    ]);
  };

  // Remove a class-subject pair
  const removeClassPair = (index) => {
    if (classPairs.length > 1) {
      const updatedPairs = classPairs.filter((pair) => pair.index !== index);
      // Re-index the pairs
      const reindexedPairs = updatedPairs.map((pair, idx) => ({
        ...pair,
        index: idx,
      }));
      setClassPairs(reindexedPairs);
    }
  };

  // Update a specific class selection
  const updateSelectedClass = (index, selectedClass) => {
    const updatedPairs = classPairs.map((pair) => {
      if (pair.index === index) {
        return { ...pair, selectedClass };
      }
      return pair;
    });
    setClassPairs(updatedPairs);
  };

  // Update subjects for a specific class
  const updateSelectedSubjects = (index, selectedSubjects) => {
    const updatedPairs = classPairs.map((pair) => {
      if (pair.index === index) {
        return { ...pair, selectedSubjects };
      }
      return pair;
    });
    setClassPairs(updatedPairs);
  };

  const compareCurrentSubjectsToPayload = (currentSubjects, payload) => {
    const updatedSubjects = [...currentSubjects]; // Create a copy of current subjects

    // Process each item in the payload
    payload.forEach((payloadItem) => {
      // Find if class exists in current subjects
      const existingClassIndex = updatedSubjects.findIndex(
        (subject) => subject.classId === payloadItem.classId,
      );

      if (existingClassIndex !== -1) {
        // Class exists, check subjects
        const currentSubjectIds =
          updatedSubjects[existingClassIndex].subjectIds;

        // Find new subject IDs that don't exist in current subjects
        const newSubjectIds = payloadItem.subjectIds.filter(
          (subjectId) => !currentSubjectIds.includes(subjectId),
        );

        // Add new subject IDs to existing subjects
        if (newSubjectIds.length > 0) {
          updatedSubjects[existingClassIndex].subjectIds = [
            ...currentSubjectIds,
            ...newSubjectIds,
          ];
        }
      } else {
        // Class doesn't exist, add the whole payload item
        updatedSubjects.push(payloadItem);
      }
    });

    return updatedSubjects;
  };

  const handleSubmit = (values, actions) => {
    // Map the UI state to the expected API format
    const formattedData = classPairs.map((pair) => ({
      classId: pair.selectedClass?.value || "",
      subjectIds: pair.selectedSubjects.map((subject) => subject.value),
    }));

    const currentData = transformAssignedSubjectsBeforeEdit(
      selectedStaff.subjects,
    );

    const updatedSubjects = compareCurrentSubjectsToPayload(
      currentData,
      formattedData,
    );

    const data = {
      ...selectedStaff,
      subjects: updatedSubjects,
    };

    delete data.classIds;

    assignSubject({ data, id: selectedStaff._id })
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Subject assigned");
        setOpenAssignSubjectModal(false);
        setOpenModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    assignSubjectState,
    subjectState,
    classState,
    staffState,
    setSelectedStaff,
    classPairs,
    addClassPair,
    removeClassPair,
    updateSelectedClass,
    updateSelectedSubjects,
  };
};

export default useAssignSubject;
