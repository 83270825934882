import { useState, useEffect } from "react";
import { FaCameraRetro } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";

import avatarImage from "Assets/avatar.png";
import useUploadFile from "hooks/File/useUploadFile";

const UploadImage = ({ onFileChanged = () => {}, defaultImage }) => {
  const [handleUpload, { data, selectedImage, setSelectedImage, isLoading }] =
    useUploadFile();
  const [disableFileInput, setDisableFileInput] = useState(false);

  const handleUploadFile = (fileUploaded) => {
    handleUpload({
      fileUploaded,
      fileType: "student-profile-pic",
    });
  };

  useEffect(() => {
    if (data?.fileUrl) {
      setSelectedImage(data.fileUrl);
      setDisableFileInput(true);
      onFileChanged(data.fileUrl);
    } else {
      setSelectedImage(defaultImage || avatarImage);
      setDisableFileInput(false);
    }
  }, [isLoading]);

  return (
    <div className="flex justify-center bg-white px-3 py-3">
      <div className=" relative rounded-full overflow-hidden lg:min-w-20 lg:max-w-20 h-20 sm:h-16 sm:max-w-20 sm:min-w-16 mx-3  mb-4 mr-4">
        <img
          src={selectedImage}
          alt="Profile"
          className="w-full h-full  object-cover flex-grow"
        />
        <label
          htmlFor="profile-image"
          className="absolute inset-0 flex items-center justify-center cursor-pointer bg-black bg-opacity-20 text-white hover:bg-opacity-50 transition-opacity"
        >
          {!isLoading ? (
            <span className="text-xl text-white  mb-2">
              {data?.src && selectedImage !== avatarImage ? (
                <IoCloseOutline
                  className="text-[26px] font-bold"
                  onClick={() => {
                    setDisableFileInput(true);
                    setSelectedImage(avatarImage);
                    onFileChanged("");
                    setTimeout(() => {
                      setDisableFileInput(false);
                    }, 1000);
                  }}
                />
              ) : (
                <FaCameraRetro />
              )}
            </span>
          ) : (
            <CircularProgress size={24} sx={{ color: "white" }} />
          )}
        </label>
        {(!isLoading || selectedImage === avatarImage) && (
          <input
            disabled={disableFileInput}
            id="profile-image"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={(e) =>
              e?.target?.files[0] !== undefined &&
              handleUploadFile(e?.target?.files[0])
            }
          />
        )}
      </div>
    </div>
  );
};

export default UploadImage;
