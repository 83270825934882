const Subject = ({ data }) => {
  return (
    <div className="flex flex-col gap-5 h-full sm:text-xs">
      {data?.subjects?.length > 0 ? (
        <div
          className="flex flex-col gap-1 max-h-[70vh] overflow-y-auto"
          style={{ scrollbarWidth: "thin" }}
        >
          <table className="w-full text-sm">
            <tr className="w-full font-bold sticky top-0 bg-white">
              <td className="w-[20%] px-4 py-2">Class</td>
              <td className="">Subjects</td>
            </tr>
            <tbody>
              {data?.subjects?.map((cl) => (
                <tr className="border-y-1">
                  <td className="p-4 text-nowrap">{cl?.class?.name}</td>
                  <td className="flex flex-wrap gap-2 items-center py-4">
                    {cl?.subjects?.map((subject, index) => (
                      <p
                        key={index}
                        className=" border-1 rounded-full p-1 sm:text-xs"
                      >
                        {subject?.name}
                      </p>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-sm flex justify-center">No Subjects</div>
      )}
    </div>
  );
};

export default Subject;
