import { IoIosAdd } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";

import Input from "Component/Forms/Input";
import Select from "Component/Forms/Select";
import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import { useCreateFormMutation } from "Network/admission/configurations/formBuilder";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";

const typeList = [
  { label: "Text", value: "text" },
  { label: "Select Dropdown", value: "select" },
  { label: "Checkbox", value: "checkbox" },
];

const FormBuilder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [createForm, formState] = useCreateFormMutation();

  const validationSchema = Yup.object().shape({
    formName: Yup.string().required("Form Name is required"),
    sections: Yup.array().of(
      Yup.object().shape({
        sectionName: Yup.string().required("Section Name is required"),
        fieldTypes: Yup.array().of(
          Yup.object().shape({
            fieldType: Yup.string().required("Field Type is required"),
            title: Yup.string().when("fieldType", {
              is: (val) => Boolean(val),
              then: (s) => s.required("Title is required"),
              otherwise: (s) => s,
            }),
            options: Yup.array().when("fieldType", {
              is: (value) => value === "select" || value === "checkbox",
              then: (s) =>
                s
                  .of(Yup.string().required("Option is required"))
                  .min(1, "At least one option is required"),
              otherwise: (s) => s,
            }),
          }),
        ),
      }),
    ),
  });

  const initialFieldType = {
    fieldType: "",
    title: "",
    options: [],
  };

  const initialValues = {
    formName: "",
    sections: [
      {
        sectionName: "",
        fieldTypes: [initialFieldType],
      },
    ],
  };

  const handleSubmit = (values, actions) => {
    const { formName, ...restValues } = values;
    const data = {
      name: formName,
      data: restValues,
    };
    createForm({ ...data })
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toast.success("Form created");
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return (
    <MainLayout>
      <BodyHeaderLayout title="Form Builder" />

      <div className="flex flex-col gap-5">
        <Button
          className="w-fit text-[12px] h-[41px] self-end"
          title="View Forms"
          handleSubmit={() =>
            navigate(
              `/${params.shortName}/${admissionFlattenRoutes.formBuilderList}`,
            )
          }
        />

        <div className="bg-white p-3 rounded-md">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form className="flex flex-col gap-5">
                <Input title="Form Name" withTitle name="formName" />
                <FieldArray name="sections">
                  {({ push, remove }) => (
                    <div>
                      {props.values.sections.map((section, index) => (
                        <div
                          key={index}
                          className="mb-3 flex flex-col gap-2 border-1 rounded-md p-2"
                        >
                          <Input
                            title="Section Name"
                            withTitle
                            name={`sections[${index}].sectionName`}
                          />

                          <FieldArray name={`sections[${index}].fieldTypes`}>
                            {({
                              push: pushFieldType,
                              remove: removeFieldType,
                            }) => (
                              <div>
                                {section.fieldTypes.map((field, fieldIndex) => (
                                  <div
                                    key={fieldIndex}
                                    className="mb-3 flex flex-col gap-2 border-1 rounded-md p-2 ml-4"
                                  >
                                    <Select
                                      withLabel
                                      label="Field Type"
                                      data={typeList}
                                      value={typeList.find(
                                        (option) =>
                                          option.value === field.fieldType,
                                      )}
                                      onChangeOption={(selectedOption) => {
                                        props.setFieldValue(
                                          `sections[${index}].fieldTypes[${fieldIndex}].fieldType`,
                                          selectedOption?.value,
                                        );

                                        if (selectedOption?.value === "text") {
                                          props.setFieldValue(
                                            `sections[${index}].fieldTypes[${fieldIndex}].options`,
                                            [],
                                          );
                                        } else if (
                                          ["select", "checkbox"].includes(
                                            selectedOption?.value,
                                          ) &&
                                          field.options.length === 0
                                        ) {
                                          props.setFieldValue(
                                            `sections[${index}].fieldTypes[${fieldIndex}].options`,
                                            [""],
                                          );
                                        }
                                      }}
                                    />
                                    <Input
                                      title="Title"
                                      withTitle
                                      name={`sections[${index}].fieldTypes[${fieldIndex}].title`}
                                    />

                                    {["select", "checkbox"].includes(
                                      field.fieldType,
                                    ) && (
                                      <FieldArray
                                        name={`sections[${index}].fieldTypes[${fieldIndex}].options`}
                                      >
                                        {({
                                          push: pushOption,
                                          remove: removeOption,
                                        }) => (
                                          <div className="ml-3 flex flex-col gap-2 border-1 rounded-md p-2">
                                            {field.options.map(
                                              (option, optIndex) => (
                                                <div
                                                  key={optIndex}
                                                  className="flex gap-2 relative"
                                                >
                                                  <Input
                                                    className="w-[90%]"
                                                    showError={false}
                                                    title={`Option ${optIndex + 1}`}
                                                    withTitle
                                                    name={`sections[${index}].fieldTypes[${fieldIndex}].options[${optIndex}]`}
                                                  />
                                                  <Button
                                                    className="self-end"
                                                    variant="icon"
                                                    handleSubmit={() =>
                                                      removeOption(optIndex)
                                                    }
                                                    Icon={
                                                      <MdDeleteForever
                                                        size={20}
                                                        className={`${
                                                          optIndex === 0
                                                            ? "text-gray-400"
                                                            : "text-red-500"
                                                        }`}
                                                      />
                                                    }
                                                    disabled={optIndex === 0}
                                                  />
                                                </div>
                                              ),
                                            )}
                                            <Button
                                              className="mt-4 self-start"
                                              variant="icon"
                                              handleSubmit={() =>
                                                pushOption("")
                                              }
                                              Icon={
                                                <IoIosAdd
                                                  size={25}
                                                  className="text-[#057877]"
                                                />
                                              }
                                            />
                                          </div>
                                        )}
                                      </FieldArray>
                                    )}

                                    <Button
                                      className="self-end"
                                      variant="icon"
                                      handleSubmit={() =>
                                        removeFieldType(fieldIndex)
                                      }
                                      disabled={
                                        section.fieldTypes.length === 1 &&
                                        fieldIndex === 0
                                      }
                                      Icon={
                                        <MdDeleteForever
                                          size={20}
                                          className="text-red-500"
                                        />
                                      }
                                    />
                                  </div>
                                ))}
                                <Button
                                  variant="icon"
                                  handleSubmit={() =>
                                    pushFieldType(initialFieldType)
                                  }
                                  Icon={
                                    <IoIosAdd
                                      size={25}
                                      className="text-[#057877]"
                                    />
                                  }
                                />
                              </div>
                            )}
                          </FieldArray>

                          <Button
                            className="self-end"
                            variant="icon"
                            handleSubmit={() => remove(index)}
                            disabled={props.values.sections.length === 1}
                            Icon={
                              <MdDeleteForever
                                size={20}
                                className="text-red-500"
                              />
                            }
                          />
                        </div>
                      ))}
                      <Button
                        variant="icon"
                        handleSubmit={() =>
                          push({
                            sectionName: "",
                            fieldTypes: [initialFieldType],
                          })
                        }
                        Icon={<IoIosAdd size={25} className="text-[#057877]" />}
                      />
                    </div>
                  )}
                </FieldArray>

                <div className="w-full flex justify-end mt-3">
                  <Button
                    className="w-[100px]"
                    isLoading={formState.isLoading}
                    title="Submit"
                    handleSubmit={props.handleSubmit}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default FormBuilder;
