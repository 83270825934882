import { IoCalendarClearOutline } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Popover, Tabs } from "@radix-ui/themes";
import { CircularProgress } from "@mui/material";
import usePermissionStore from "hooks/Auth/usePermissionStore";

import cn from "Utils/cn";
import SesionDetails from "./SesionDetails";
import TermDetails from "./TermDetails";
import SetCurrent from "./SetCurrent";
import { useGetCurrentSessionAndTermQuery } from "Network/academics/sessionAcademics";
import { PERMISSIONS_LISTS } from "Utils/constants";

const SessionControl = ({
  miniSidebar,
  setMiniSidebar,
  setOnMouseEnter,
  isMobile,
}) => {
  const currentSessionAndTermState = useGetCurrentSessionAndTermQuery();
  const { hasAnyPermission } = usePermissionStore();

  const handleClick = () => {
    setMiniSidebar(false);
    setOnMouseEnter(false);
  };

  const triggerContent = (
    <div onClick={handleClick}>
      <Trigger
        miniSidebar={miniSidebar}
        currentSessionAndTermState={currentSessionAndTermState}
        isAdmin={hasAnyPermission([
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ])}
      />
    </div>
  );

  return (
    <Popover.Root className="p-0 m-0">
      {hasAnyPermission([
        PERMISSIONS_LISTS.ADMIN,
        PERMISSIONS_LISTS.ORGANISATION_ADMIN,
      ]) ? (
        <Popover.Trigger>{triggerContent}</Popover.Trigger>
      ) : (
        triggerContent
      )}
      <Popover.Content
        className=" z-auto h-[500px]"
        style={{
          padding: 0,
          margin: 0,
          width: isMobile ? "400px" : "800px",
          scrollbarWidth: "thin",
        }}
        side={isMobile ? "top" : "right"}
      >
        <div>
          <Tabs.Root defaultValue="session">
            <Tabs.List className="sticky top-0 bg-white z-50">
              <Tabs.Trigger value="session">
                <p className="">Session</p>
              </Tabs.Trigger>
              <Tabs.Trigger value="term">
                <p className="">Term</p>
              </Tabs.Trigger>
              <Tabs.Trigger value="current">
                <p className="">Set Current</p>
              </Tabs.Trigger>
            </Tabs.List>

            <div className="p-4 h-full w-full">
              <Tabs.Content value="session">
                <SesionDetails />
              </Tabs.Content>

              <Tabs.Content value="term">
                <TermDetails />
              </Tabs.Content>

              <Tabs.Content value="current">
                <SetCurrent />
              </Tabs.Content>
            </div>
          </Tabs.Root>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default SessionControl;

const Trigger = ({ miniSidebar, currentSessionAndTermState, isAdmin }) => {
  if (miniSidebar) {
    return <IoCalendarClearOutline className="text-blue-600 text-lg" />;
  }
  return (
    <div
      className={cn(
        "border-1 rounded-md p-2 flex flex-col gap-3 mx-4",
        isAdmin && "cursor-pointer",
      )}
    >
      <p className="text-xs text-gray-500 font-medium">
        Current Academic Period
      </p>
      <div className="flex justify-between items-center">
        {!currentSessionAndTermState.isLoading ? (
          <div className="flex items-center gap-3">
            <IoCalendarClearOutline className="text-blue-600 text-lg" />

            <div className="text-xs">
              <p className="font-semibold">
                {currentSessionAndTermState?.data?.currentTerm?.name}
              </p>
              <p className="text-gray-500">
                {currentSessionAndTermState?.data?.currentSession?.name} session
              </p>
            </div>
          </div>
        ) : (
          <CircularProgress size="20px" />
        )}

        {isAdmin && <MdKeyboardArrowRight className="text-lg" />}
      </div>
    </div>
  );
};
