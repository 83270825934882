import { NonTableRow, Title } from "Component/Table";
import dayjs from "dayjs";

const Bio = ({ data }) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-1">
        <Title text={"Personal Information"} />
        <NonTableRow
          title="Fullname"
          text={`${data?.firstName} ${data?.middleName || ""} ${data?.lastName}`}
        />
        <NonTableRow title="Gender" text={data?.gender} />
        <NonTableRow
          title="Date of Birth"
          text={dayjs(data?.dob).format("DD/MM/YYYY")}
        />
        <NonTableRow
          title="Year of Admission"
          text={dayjs(data?.createdAt).format("DD/MM/YYYY")}
        />
        <NonTableRow title="Address" text={data?.address} />
      </div>

      <div className="flex flex-col gap-1">
        <Title text={"Guardian Information"} />
        <NonTableRow title="Name" text={data?.parentOrGuardianInfo?.fullName} />
        <NonTableRow
          title="Relationship"
          text={data?.parentOrGuardianInfo?.relationShip}
        />
        <NonTableRow title="Email" text={data?.parentOrGuardianInfo?.email} />
        <NonTableRow title="Phone" text={data?.parentOrGuardianInfo?.phone} />
      </div>

      <div className="flex flex-col gap-1">
        <Title text={"Health Information"} />
        <NonTableRow title="Health Status" text={data?.medicalInfo} />
      </div>
    </div>
  );
};

export default Bio;
