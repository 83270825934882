import parentApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const applicants = parentApi.injectEndpoints({
  endpoints: (builder) => ({
    createApplicants: builder.mutation({
      query: ({ data, schoolId }) => ({
        url: "/applicants",
        method: "POST",
        body: {
          ...data,
          schoolId: schoolId || getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.SCHOOL_APPLICANTS, id: "LIST" }],
    }),
  }),
});

export const { useCreateApplicantsMutation } = applicants;
