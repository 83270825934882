import React from "react";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";

import pic from "../../../../../Assets/child.png";
import {
  useGetSchoolProfileQuery,
  useGetMyProfileQuery,
} from "Network/userManagement/profile";
import { useGetSessionsQuery } from "Network/admission/configurations/session";

const Template1 = () => {
  const { data, isLoading } = useGetSchoolProfileQuery();
  const { data: userData, isLoading: userDataLoading } = useGetMyProfileQuery();
  const { data: sessionData, isLoading: sessionDataLoading } =
    useGetSessionsQuery();

  if (isLoading || userDataLoading || sessionDataLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="font-sans m-auto mt-5 bg-white p-5 md:p-20 w-[80%] sm:w-[100%] border-1 sm:text-[12px]">
      <div className="header flex mt-3">
        <img
          src={data?.image || pic}
          alt=""
          className="w-20 h-20 sm:w-6 sm:h-6"
        />
        <div className="justify-center items-center ">
          <h1 className="text-[1rem] md:text-4xl text-center sm:text-12 font-bold ml-[-0.2rem] md:mx-16">
            {data?.schoolName || "N/A"}
          </h1>
          <div className="header-info sm:text-[0.5rem] text-center font-semibold mb-3">
            <p>Motto: {data?.motto || "N/A"}</p>
            <p>Address: {data?.address || "N/A"}</p>
            <p>Phone: {data?.contact || "N/A"}</p>
          </div>
        </div>
      </div>

      <div className="watermark absolute top-[95%] left-[65%] transform -translate-x-1/2 -translate-y-1/2 opacity-10 z-0">
        <img
          src={data?.image || pic}
          alt="School Logo"
          className="w-[80%] h-[50%] align-middle"
        />
      </div>

      <div className="content relative z-10 mb-2 md:mb-5">
        <div className="ref-date flex justify-between mb-5 sm:text-[0.4rem]">
          <p>Ref: ____________________</p>
          <p>Your Ref: ___________________</p>
          <p>
            Date: <span>{dayjs().format("DD/MM/YYYY")}</span>
          </p>
        </div>
        <hr className="my-4 border-t-2 border-black" />
        <div className="info-with-passport-container flex justify-between">
          <div className="sm:text-[0.5rem]">
            <p className="font-bold">
              {userData?.firstName || "First Name"},{" "}
              {userData?.lastName || "Last Name"}
            </p>
            <p>NIA/APN0011</p>
            <p>{userData?.address || "N/A"}</p>
          </div>
          <div className="passport-photograph">
            <img
              src={userData?.image || pic}
              alt="Passport"
              height={115}
              width={115}
              className="w-15 h-15 sm:w-10 sm:h-10"
            />
          </div>
        </div>
        <p className="Admission-offer font-bold md:text-2xl mx-3 md:mx-8 underline text-center">
          OFFER OF PROVISIONAL ADMISSION INTO: JUNIOR SECTION FOR{" "}
          {sessionData[0].session} ACADEMIC SESSION
        </p>
        <p className="sm:text-[0.5rem]">
          I am pleased to inform you that you have been offered a Provisional
          Admission at Noble Intellects Academy for the {sessionData[0].session}
          Academic session as follows:
        </p>
        <div className="registration-material mb-5 mt-5">
          <ol className="list-disc pl-5">
            <li>Section: Junior</li>
            <li>Class: JSS 1</li>
          </ol>
        </div>
        <p className="sm:text-[0.5rem]">
          If you accept this offer, you are required to commence registration
          and resume classes as from{" "}
          <span className="font-bold">12th September, 2024</span> which is the
          resumption date.
        </p>
      </div>

      <div className="registration-material mb-5">
        <h2 className=" font-bold md:text-xl">ENTRY REQUIREMENTS</h2>
        <ol className="list-disc pl-5 sm:text-[0.5rem]">
          <li>
            Payment of fees should be made in the school premises or online with
            evidence of payment submitted.
          </li>
          <li>2 pairs of black colored socks</li>
          <li>1 pair of black shoes or sandals for classes</li>
          <li>One big sachet of detergent (Omo)</li>
          <li>One disinfectant (Dettol)</li>
          <li>Copy of this admission letter</li>
        </ol>
      </div>

      <div className="mt-4">
        <p className="sm:text-[0.5rem]">
          Please find attached the school rules and regulations for guidance.
        </p>
      </div>
      <div className="mt-4">
        <p className="sm:text-[0.5rem]">
          While thanking you for your support, please accept the assurance of
          our highest esteem.
        </p>
      </div>
      <div className="mt-4">
        <p className="sm:text-[0.5rem]">
          Thank you very much and congratulations.
        </p>
      </div>

      <div className="footer text-center">
        <div>
          <img src="sign.png" alt="Signature" className="signature w-25 h-12" />
        </div>
        <p className="sm:text-[0.5rem]">Accept my congratulations</p>
        <p className="sm:text-[0.5rem]">
          Mr. {userData?.firstName || "First Name"},{" "}
          {userData?.lastName || "Last Name"}
        </p>
        <p className="sm:text-[0.5rem]">Director {data?.schoolName || "N/A"}</p>
      </div>
    </div>
  );
};

export default Template1;
