import { create } from "zustand";

const useStudentActionStore = create((set) => ({
  openConfirmDelete: false,
  openEdit: false,
  openBulkSelection: false,
  selectedStudent: null,
  openReportModal: false,
  openUpdateStatusModal: false,

  setOpenConfirmDelete: (openConfirmDelete) => set({ openConfirmDelete }),
  setOpenEdit: (openEdit) => set({ openEdit }),
  setOpenBulkSelection: (openBulkSelection) => set({ openBulkSelection }),
  setSelectedStudent: (selectedStudent) => set({ selectedStudent }),
  setOpenReportModal: (openReportModal) => set({ openReportModal }),
  setOpenUpdateStatusModal: (openUpdateStatusModal) =>
    set({ openUpdateStatusModal }),
}));

export default useStudentActionStore;
