import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import Select from "Component/Forms/Select";
import { transformSelectOption, transformClassOption } from "Utils/transform";
import useAssignSubjectToClass from "../hooks/useAssignSubjectToClass";
import Button from "Component/Forms/Button";

const AssignSubjectToClass = ({ open, setOpen }) => {
  const {
    validationSchema,
    initialValues,
    handleSubmit,

    selectedClass,
    setSelectedClass,
    selectedSection,
    setSelectedSection,
    selectedSubjects,
    setSelectedSubjects,

    sectionState,
    classesState,
    subjectState,
    createCurriculumState,
  } = useAssignSubjectToClass();
  return (
    <CustomModal
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      className={"min-w-[40%] "}
      title="Assign Subject to Class"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-3 w-full mt-2">
            <Select
              withLabel
              label="Section"
              placeholder="Select Section"
              data={transformSelectOption(sectionState?.data)}
              isLoading={sectionState.isLoading}
              value={selectedSection}
              className={`${props.errors?.sectionId && "border-[red] "}`}
              onChangeOption={(x) => {
                props.setFieldValue("sectionId", x?.value);
                setSelectedSection(x);
              }}
            />

            <Select
              withLabel
              label="Class"
              data={transformClassOption(classesState?.data?.data)}
              isLoading={classesState.isLoading}
              value={selectedClass}
              className={`${props.errors?.classId && "border-[red] "}`}
              onChangeOption={(x) => {
                props.setFieldValue("classId", x?.value);
                setSelectedClass(x);
              }}
            />

            <Select
              withLabel
              label="Select Subjects"
              data={transformSelectOption(subjectState?.data)}
              isLoading={subjectState.isLoading}
              value={selectedSubjects}
              selectMultiple
              className={`${props.errors?.subjectIds && "border-[red] "}`}
              onChangeOption={(x) => {
                props.setFieldValue(
                  "subjectIds",
                  x?.map((y) => y?.value),
                );
                setSelectedSubjects(x);
              }}
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Assign to Class"
                handleSubmit={props.handleSubmit}
                isLoading={createCurriculumState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AssignSubjectToClass;
