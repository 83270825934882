import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { getSchoolId, getUserId } from "Network/utils";

const useReturnSchool = () => {
  const params = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem("school_refresh_token");
  const decodedToken = decodeToken(token);

  useEffect(() => {
    if (!token) {
      navigate(`/${params.shortName}/login`);
      return;
    }

    if (
      getSchoolId() !== decodedToken?.organisationId &&
      getUserId() !== decodedToken?.userId
    ) {
      navigate("/");
    }
  }, [params.shortName]);

  return { decodedToken };
};

export default useReturnSchool;
