import { toast } from "react-toastify";

import useStaffActionStore from "./useStaffActionStore";
import { useDeleteStaffMutation } from "Network/academics/staffAcademics";

const useDeleteStaff = () => {
  const { setOpenConfirmDelete, selectedStaff } = useStaffActionStore();
  const [deleteStaff, deleteStaffState] = useDeleteStaffMutation();

  const handleDeleteStaff = () => {
    deleteStaff(selectedStaff._id)
      .unwrap()
      .then((_) => {
        toast.success("Staff Deleted Successfully");
        setOpenConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleDeleteStaff,
    deleteStaffState,
  };
};

export default useDeleteStaff;
