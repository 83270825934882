import { useState } from "react";
import { toast } from "react-toastify";

import CustomModal from "Component/Forms/CustomModal";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import { useCreateCategoryMutation } from "Network/academics/categoryAcademics";

const CreateCategory = ({ open, setOpen }) => {
  const [text, settext] = useState("");
  const [createCategory, createCategoryState] = useCreateCategoryMutation();

  const handleSubmit = () => {
    createCategory({
      name: text,
    })
      .unwrap()
      .then((_) => {
        toast.success("Category created");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpen(false);
      });
  };
  return (
    <CustomModal
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      title="Create Category"
    >
      <InputNoFormik
        withTitle
        title="Category Name"
        value={text}
        handleChange={(e) => settext(e.target.value)}
      />

      <div className="flex justify-between mt-3 gap-10">
        <Button
          variant="outline"
          className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
          title="Cancel"
          handleSubmit={() => setOpen(false)}
        />

        <Button
          className="w-[80px] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
          title="Create"
          disabled={text === ""}
          isLoading={createCategoryState.isLoading}
          handleSubmit={handleSubmit}
        />
      </div>
    </CustomModal>
  );
};

export default CreateCategory;
