import { toast } from "react-toastify";

import IDForm from "./IDForm";
import { IntialsConstants } from "Utils/constants";
import {
  useCreateIDInitialsMutation,
  useUpdateIDInitialsMutation,
} from "Network/academics/identification";

const AdminID = ({ initialState }) => {
  const [createIDInitials, createIDInitialsState] =
    useCreateIDInitialsMutation();
  const [updateIDInitials, updateIDInitialsState] =
    useUpdateIDInitialsMutation();

  const adminInitials =
    initialState?.data?.find(
      (initial) => initial.type === IntialsConstants.admin,
    ) || null;

  const handleSubmit = (values) => {
    if (adminInitials) {
      const data = {
        firstPart: values.firstPart,
        secondPart: values.secondPart,
        type: IntialsConstants.admin,
      };
      updateIDInitials({ data, id: adminInitials._id })
        .unwrap()
        .then((_) => {
          toast.success("Admin ID updated successfully");
        })
        .catch((err) => {
          if (err.status === 400) {
            const msg =
              typeof err.data?.message === "string"
                ? err.data?.message
                : err.data?.message[0];
            toast.error(msg);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      createIDInitials({
        firstPart: values.firstPart,
        secondPart: values.secondPart,
        type: IntialsConstants.admin,
      })
        .unwrap()
        .then((_) => {
          toast.success("Admin ID set successfully");
        })
        .catch((err) => {
          if (err.status === 400) {
            const msg =
              typeof err.data?.message === "string"
                ? err.data?.message
                : err.data?.message[0];
            toast.error(msg);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  return (
    <div>
      <IDForm
        initialFirstPart={adminInitials?.firstPart || ""}
        initialSecondPart={adminInitials?.secondPart || ""}
        onSubmit={handleSubmit}
        queryLoading={initialState?.isFetching}
        mutationLoading={
          createIDInitialsState?.isLoading || updateIDInitialsState?.isLoading
        }
      />
    </div>
  );
};

export default AdminID;
