import { Formik, Form, FieldArray } from "formik";
import { IoIosAdd } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

import CustomModal from "Component/Forms/CustomModal";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useCreateAssessment from "../hooks/useCreateAssessment";

const CreateAssessment = ({ open, setOpen }) => {
  const {
    initialValues,
    handleSubmit,
    validationSchema,
    createAssessmentState,
  } = useCreateAssessment({ setOpen });

  return (
    <CustomModal
      open={open}
      handleClose={() => !createAssessmentState.isLoading && setOpen(false)}
      title="Create Assessment"
      className={"min-w-[50%] min-h-[50vh] max-h-[90vh] overflow-y-auto"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form className="space-y-6">
            <Input
              name="name"
              withTitle
              title="Format Name"
              placeholder="e.g. Standard School Assessment"
            />

            <div className="border-t pt-4">
              <h3 className="text-md font-semibold mb-4">Assessments</h3>

              <FieldArray name="assessments">
                {({ remove, push }) => (
                  <div>
                    {props.values.assessments.length > 0 &&
                      props.values.assessments.map((_, index) => (
                        <div
                          key={index}
                          className="border p-4 rounded mb-4 bg-gray-50"
                        >
                          <div className="flex justify-between mb-2">
                            <h3 className="text-sm font-medium">
                              Assessment {index + 1}
                            </h3>
                            {props.values.assessments.length > 1 && (
                              <Button
                                className="self-end"
                                variant="icon"
                                handleSubmit={() => remove(index)}
                                Icon={
                                  <MdDeleteForever
                                    size={20}
                                    className="text-red-500"
                                  />
                                }
                              />
                            )}
                          </div>

                          <div className="grid grid-cols-2 gap-4">
                            <Input
                              withTitle
                              title="Type Name"
                              name={`assessments.${index}.name`}
                              placeholder="e.g. Test1, Test2, Exam"
                            />

                            <Input
                              withTitle
                              title="Score Range"
                              name={`assessments.${index}.maxValue`}
                              placeholder="e.g. 90"
                              type="number"
                            />
                          </div>
                        </div>
                      ))}

                    <Button
                      variant="icon"
                      handleSubmit={() =>
                        push({
                          name: "",
                          maxValue: "",
                        })
                      }
                      Icon={<IoIosAdd size={25} className="text-[#057877]" />}
                    />
                  </div>
                )}
              </FieldArray>
            </div>

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Create Assessment"
                handleSubmit={props.handleSubmit}
                isLoading={createAssessmentState.isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateAssessment;
