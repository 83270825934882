import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "score-sheets";

const scoreAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getScoreSheets: builder.query({
      query: ({ classId, termId, sessionId, subjectId }) => {
        const url = `/${endpoint}`;
        const params = {};
        if (classId !== undefined && classId !== "") {
          params.classId = classId;
        }

        if (termId !== undefined && termId !== "") {
          params.termId = termId;
        }

        if (sessionId !== undefined && sessionId !== "") {
          params.sessionId = sessionId;
        }

        if (subjectId !== undefined && subjectId !== "") {
          params.subjectId = subjectId;
        }
        return { url, params };
      },

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_SCORE,
                id,
              })),
              { type: tags.ACADEMICS_SCORE, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_SCORE, id: "LIST" }],
    }),

    saveScore: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PUT",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_SCORE, id: "LIST" }],
    }),
  }),
});

export const { useGetScoreSheetsQuery, useSaveScoreMutation } = scoreAcademics;
