import { useEffect } from "react";
import { Formik } from "formik";
import PreLayout from "Component/Layout/PreLayout";
import Button from "Component/Forms/Button";
import Input from "Component/Forms/Input";
import * as Yup from "yup";
import { useParams } from "react-router";
import { useResetPasswordMutation } from "Network/admission/auth/password";
import { toast } from "react-toastify";
import { useSearchParams, Link } from "react-router-dom";

const ResetPassword = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [resetPassword, resetPasswordState] = useResetPasswordMutation();

  useEffect(() => {
    if (resetPasswordState.isSuccess) toast.success("Success");
    if (resetPasswordState.isError) {
      if (resetPasswordState.error.status === 400) {
        toast.error(resetPasswordState?.error?.data?.message);
      } else {
        toast.error("Error occurred");
      }
    }
  }, [resetPasswordState.isLoading]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = (values) => {
    resetPassword({
      password: values.password,
      hash: searchParams.get("hash"),
    });
  };

  return (
    <PreLayout title={"Reset password"} className="max-w-[30vw] sm:max-w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
            {resetPasswordState.isUninitialized ||
            !resetPasswordState.isSuccess ? (
              <div className="flex flex-col gap-4 w-full">
                <p className="text-center text-[14px] text-primary font-semibold cursor-pointer">
                  Enter New Password
                </p>

                <Input
                  withTitle
                  title="Password"
                  placeholder="Enter password"
                  name="password"
                  type="password"
                />

                <Input
                  withTitle
                  title="Confirm Password"
                  placeholder="Enter confirm password"
                  name="confirmPassword"
                  type="password"
                />

                <Button
                  isLoading={resetPasswordState.isLoading}
                  handleSubmit={props.handleSubmit}
                  title="Set"
                />
              </div>
            ) : (
              <div className="flex flex-col items-center gap-6">
                <p className="text-center text-[14px]">
                  Your new password has been set, Click on the Log in below
                </p>
                <Link to={`/${params?.shortName}`}>
                  <p className="cursor-pointer text-center text-[14px] text-primary font-semibold">
                    Log in
                  </p>
                </Link>
              </div>
            )}
          </div>
        )}
      </Formik>
    </PreLayout>
  );
};

export default ResetPassword;
