import { create } from "zustand";

const initialState = {
  openDeleteClassModal: false,
  openCreateClassModal: false,
  openEditClassModal: false,
  selectedClass: null,
};

const useClassStore = create((set) => ({
  ...initialState,

  setOpenDeleteClassModal: (value) => set({ openDeleteClassModal: value }),

  setOpenCreateClassModal: (value) => set({ openCreateClassModal: value }),
  setOpenEditClassModal: (value) => set({ openEditClassModal: value }),
  setSelectedClass: (value) => set({ selectedClass: value }),
}));

export default useClassStore;
