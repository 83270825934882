import { useState } from "react";
import toast from "react-hot-toast";

import CustomModal from "Component/Forms/CustomModal";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import { useCreateSubjectMutation } from "Network/academics/subjectAcademics";

const CreateSubject = ({ open, setOpen }) => {
  const [text, settext] = useState("");

  const [createSubject, createSubjectState] = useCreateSubjectMutation();

  const handleSubmit = () => {
    createSubject({
      name: text,
    })
      .unwrap()
      .then((_) => {
        toast.success("Subject created");
        settext("");
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        settext("");
      });
  };

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        setOpen(false);
        settext("");
      }}
      title="Create Subject"
    >
      <div className="flex flex-col gap-5 mt-3">
        <InputNoFormik
          withTitle
          title="Subject Name"
          value={text}
          handleChange={(e) => settext(e.target.value)}
        />

        <div className="flex justify-between">
          <Button
            handleSubmit={() => setOpen(false)}
            title="Cancel"
            className="w-fit text-[12px] py-2 px-4"
            variant="outline"
          />

          <Button
            disabled={text === ""}
            isLoading={createSubjectState.isLoading}
            handleSubmit={handleSubmit}
            title="Create"
            className="w-fit text-[12px] py-2 px-4"
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default CreateSubject;
