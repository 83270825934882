import { getSchoolId } from "Network/utils";
import userMangementAPi from "./apiSlice";
import { tags } from "Utils/constants";

const endpoint = "roles";

const userManagementRole = userMangementAPi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: ({ page, search, limit }) => {
        const url = `/${endpoint}/organisation/${getSchoolId()}`;

        const params = { limit };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        return { url, params };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.USER_MANAGEMENT_ROLE,
                id,
              })),
              { type: tags.USER_MANAGEMENT_ROLE, id: "LIST" },
            ]
          : [{ type: tags.USER_MANAGEMENT_ROLE, id: "LIST" }],
    }),

    createRole: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.USER_MANAGEMENT_ROLE, id: "LIST" }],
    }),

    editRole: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}/${data?.id}`,
        method: "PATCH",
        body: {
          name: data.name,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.USER_MANAGEMENT_ROLE, id: "LIST" }],
    }),

    deleteRole: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.USER_MANAGEMENT_ROLE, id: "LIST" }],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useEditRoleMutation,
  useDeleteRoleMutation,
} = userManagementRole;
