import { create } from "zustand";

// Create a Zustand store
const useRoleStore = create((set) => ({
  // Modal states
  openCreateModal: false,
  openAssignPermissionModal: false,
  openEditModal: false,
  openDeleteModal: false,
  openDeleteRolePermissionModal: false,
  selectedRole: null,
  selectedRolePermission: null,

  // Actions to update states
  setOpenCreateModal: (isOpen) => set({ openCreateModal: isOpen }),
  setOpenAssignPermissionModal: (isOpen) =>
    set({ openAssignPermissionModal: isOpen }),
  setOpenEditModal: (isOpen) => set({ openEditModal: isOpen }),
  setOpenDeleteModal: (isOpen) => set({ openDeleteModal: isOpen }),
  setOpenDeleteRolePermissionModal: (isOpen) =>
    set({ openDeleteRolePermissionModal: isOpen }),
  setSelectedRole: (role) => set({ selectedRole: role }),
  setSelectedRolePermission: (role) => set({ selectedRolePermission: role }),

  // Reset all modals (convenience function)
  resetModals: () =>
    set({
      openCreateModal: false,
      openEditModal: false,
      openDeleteModal: false,
    }),
}));

export default useRoleStore;
