import { Formik } from "formik";

import Select from "Component/Forms/Select";
import { transformSelectOption } from "Utils/transform";
import CustomModal from "Component/Forms/CustomModal";
import Button from "Component/Forms/Button";
import useGenerateStudentReport from "../hooks/useGenerateStudentReport";

const GenerateReport = ({ open, setOpen }) => {
  const {
    initialValues,
    validationSchema,

    selectedSession,
    setSelectedSession,
    selectedTerm,
    setSelectedTerm,
    selectedReportType,
    setSelectedReportType,

    sessionState,
    termState,

    handleSubmit,
  } = useGenerateStudentReport();
  return (
    <CustomModal
      title="Generate Report"
      className={"min-w-[35%]"}
      open={open}
      handleClose={() => setOpen(false)}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="flex flex-col gap-8 bg-white p-4 rounded-md">
            <div className="grid grid-cols-2 gap-5">
              <Select
                label="Session"
                data={transformSelectOption(sessionState?.data)}
                value={selectedSession}
                className={`${props.errors?.sessionId && "border-[red] "}`}
                isLoading={sessionState.isLoading}
                onChangeOption={(x) => {
                  props.setFieldValue("sessionId", x?.value);
                  setSelectedSession(x);
                }}
              />
              <Select
                withLabel
                label="Term"
                data={transformSelectOption(termState?.data)}
                value={selectedTerm}
                className={`${props.errors?.termId && "border-[red] "}`}
                isLoading={termState.isLoading}
                onChangeOption={(x) => {
                  props.setFieldValue("termId", x?.value);
                  setSelectedTerm(x);
                }}
              />

              <div className="col-span-2">
                <Select
                  withLabel
                  label="Report Type"
                  className="w-full"
                  data={[
                    { label: "Class Report", value: "class" },
                    { label: "Section Report", value: "section" },
                  ]}
                  disabled
                  value={selectedReportType}
                />
              </div>
            </div>

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[30%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Generate Report"
                handleSubmit={props.handleSubmit}
                // isLoading={createClassState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default GenerateReport;
