import Avatar from "Component/Avatar";
import Button from "Component/Forms/Button";
import Skeleton from "react-loading-skeleton";

const SummaryCard = ({ staffState }) => {
  if (staffState.isFetching) {
    return (
      <div className="w-[20%] h-[400px]">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2 bg-white p-4 items-center sm:text-[13px] rounded-md w-[20%] sm:w-full h-fit">
      <Avatar
        className="w-[200px] h-[200px] sm:w-[100px] sm:h-[100px]"
        image={staffState?.data?.image}
      />
      <span className="text-center">
        <p className="font-bold text-center capitalize">
          {`${staffState?.data?.firstName} ${staffState?.data?.middleName || ""} ${staffState?.data?.lastName}`}
        </p>
      </span>
      <p className="font-bold text-gray-500">{staffState?.data?.staffNumber}</p>

      <Button title="Reset Password" disabled />
    </div>
  );
};

export default SummaryCard;
