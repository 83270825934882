export const transformAssignedSubjectsBeforeEdit = (subjects) => {
  if (subjects.length > 0) {
    const convertedSubjects = subjects.map((item) => ({
      classId: item.class._id,
      subjectIds: item.subjects.map((subject) => subject._id),
    }));

    return convertedSubjects;
  }
  return [];
};
