import { CircularProgress } from "@mui/material";
import cn from "Utils/cn";
import { useGetSubjectsByClassIdQuery } from "Network/academics/subjectAcademics";
import { useGetCurrentSessionAndTermQuery } from "Network/academics/sessionAcademics";
import useScoreEntryStore from "../../hooks/useScoreEntryStore";
import usePermissionStore from "hooks/Auth/usePermissionStore";
import { PERMISSIONS_LISTS } from "Utils/constants";

const SelectSubject = ({ staff }) => {
  const { selectedClass, setSelectedSubject, selectedSubject } =
    useScoreEntryStore();

  const { hasPermission, hasAnyPermission } = usePermissionStore();

  const sessionState = useGetCurrentSessionAndTermQuery();

  // Use this for staff with admin permission
  const subjectState = useGetSubjectsByClassIdQuery(
    {
      classId: selectedClass?.value,
      sessionId: sessionState?.data?.currentSession?._id,
      termId: sessionState?.data?.currentTerm?._id,
    },
    { skip: !selectedClass?.value && !sessionState?.isLoading },
  );

  // Use this for staff with subject teacher permission
  const staffSubjects =
    staff?.subjects?.find(
      (subject) => subject?.class?._id === selectedClass?.value,
    )?.subjects || [];

  const isSubjectTeacher = hasPermission(PERMISSIONS_LISTS.SUBJECT_TEACHER);
  const isAdmin = hasAnyPermission([
    PERMISSIONS_LISTS.ADMIN,
    PERMISSIONS_LISTS.ORGANISATION_ADMIN,
  ]);
  return (
    <div className="bg-white p-4 rounded-md flex flex-col gap-4 min-h-[200px]">
      <p className="text-sm font-semibold">
        Select Subject
        {selectedClass?.value && (
          <span className="capitalize"> for {selectedClass?.label}</span>
        )}
      </p>

      {selectedClass?.value ? (
        <div>
          {isAdmin && (
            <div>
              {!subjectState?.isFetching ? (
                <SubjectList
                  subjects={subjectState?.data}
                  selectedSubject={selectedSubject}
                  setSelectedSubject={setSelectedSubject}
                />
              ) : (
                <div className="flex items-center justify-center">
                  <CircularProgress />
                </div>
              )}
            </div>
          )}

          {isSubjectTeacher && !isAdmin && (
            <SubjectList
              subjects={staffSubjects}
              selectedSubject={selectedSubject}
              setSelectedSubject={setSelectedSubject}
            />
          )}
        </div>
      ) : (
        <div className="flex justify-center">No class Selected</div>
      )}
    </div>
  );
};

export default SelectSubject;

const SubjectList = ({ subjects, selectedSubject, setSelectedSubject }) => {
  return (
    <div className="grid grid-cols-4 sm:grid-cols-2 gap-4">
      {subjects?.map((subject, index) => (
        <p
          onClick={() => setSelectedSubject(subject)}
          key={index}
          className={cn(
            "border-1 p-2 rounded-md cursor-pointer text-sm hover:bg-gray-100 capitalize",
            selectedSubject?._id === subject?._id &&
              "bg-blue-100 border-blue-500",
          )}
        >
          {subject?.name}
        </p>
      ))}
    </div>
  );
};
