import PropTypes from "prop-types";
import CollapseArrow from "./component/CollapseArrow";
import Collapsible from "./component/Collapsible";
import cn from "Utils/cn";
import useSidebarStore from "./hooks/useSidebarStore";
import SidenavList from "./SidenavList";
import useWindowScreen from "Utils/useWindowScreen";
import { Tooltip } from "@radix-ui/themes";

function SidenavItem({
  name,
  active,
  nested,
  children,
  open,
  onClick = () => {},
  ...rest
}) {
  const { miniSidebar, toggleMiniSidebar } = useSidebarStore();
  const { isMobile } = useWindowScreen();

  return (
    <>
      <Tooltip content={name}>
        <div
          className={cn(
            "flex items-center gap-3 w-full hover:bg-[#e1f5e1] cursor-pointer text-black hover:font-semibold rounded-sm hover:shadow-sm px-5 py-3",
            active && active !== "isParent" && "bg-[#7ad37a] font-semibold",
            active && active === "isParent" && "bg-[#e1f5e1] font-semibold",
          )}
          onClick={() => {
            if (!nested) {
              onClick();
              if (isMobile) toggleMiniSidebar();
            }
          }}
          {...rest}
        >
          <p>{name[0].toUpperCase()}</p>

          {!miniSidebar && <p className="truncate">{name}</p>}

          {children && !miniSidebar && <CollapseArrow open={open} />}
        </div>
      </Tooltip>

      {children && (
        <Collapsible open={open} {...rest}>
          <SidenavList>{children}</SidenavList>
        </Collapsible>
      )}
    </>
  );
}

// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  active: false,
  nested: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default SidenavItem;
