import Select from "Component/Forms/Select";
import { transformSelectOption, transformClassOption } from "Utils/transform";
import { useGetSectionsQuery } from "Network/academics/sectionAcademics";
import { useGetClassesQuery } from "Network/academics/classAcademics";
import useScoreEntryStore from "../../hooks/useScoreEntryStore";
import usePermissionStore from "hooks/Auth/usePermissionStore";
import { PERMISSIONS_LISTS } from "Utils/constants";
import cn from "Utils/cn";

const SelectClass = ({ staffState }) => {
  const {
    setSelectedSection,
    setSelectedClass,
    selectedSection,
    selectedClass,
    setSelectedSubject,
    setSectionData,
    setClassData,
  } = useScoreEntryStore();

  const { hasPermission, hasAnyPermission } = usePermissionStore();

  const sectionState = useGetSectionsQuery();

  // Use this for staff with admin permission
  const classState = useGetClassesQuery(
    { sectionId: selectedSection.value },
    { skip: !selectedSection.value },
  );

  // Use this for staff with subject teacher permission
  const staffClasses = staffState?.data?.subjects?.map((item) => {
    return {
      ...item?.class,
    };
  });

  const isSubjectTeacher = hasPermission(PERMISSIONS_LISTS.SUBJECT_TEACHER);
  const isAdmin = hasAnyPermission([
    PERMISSIONS_LISTS.ADMIN,
    PERMISSIONS_LISTS.ORGANISATION_ADMIN,
  ]);

  return (
    <div className="bg-white p-4 rounded-md flex flex-col gap-4">
      <p className="text-sm font-semibold">{`Select ${isAdmin ? "Section and" : ""} Class`}</p>
      <div className={cn("grid grid-cols-2 gap-4", !isAdmin && "grid-cols-1")}>
        {isAdmin && (
          <Select
            withLabel
            label="Section"
            data={transformSelectOption(sectionState?.data)}
            isLoading={sectionState.isLoading}
            value={selectedSection}
            onChangeOption={(x) => {
              setSelectedSection(x);
              setSelectedClass({ label: "Select Class", value: null });
              setSelectedSubject(null);
              setSectionData(
                sectionState?.data?.find((item) => item._id === x.value),
              );
            }}
          />
        )}

        {isAdmin && (
          <Select
            withLabel
            label="Class"
            data={transformClassOption(classState?.data?.data)}
            isLoading={classState.isLoading}
            value={selectedClass}
            onChangeOption={(x) => {
              setSelectedClass(x);
              setClassData(
                classState?.data?.data?.find((item) => item._id === x.value),
              );
            }}
          />
        )}

        {isSubjectTeacher && !isAdmin && (
          <Select
            withLabel
            label="Class"
            data={transformClassOption(staffClasses)}
            isLoading={staffState.isFetching || sectionState.isLoading}
            value={selectedClass}
            onChangeOption={(x) => {
              setSelectedClass(x);
              const chosenClass = staffClasses?.find(
                (item) => item._id === x.value,
              );
              setClassData(chosenClass);
              setSectionData(
                sectionState?.data?.find(
                  (item) => item._id === chosenClass?.sectionId,
                ),
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SelectClass;
