import { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import useStaffActionStore from "./useStaffActionStore";
import useAssignmentStore from "Screens/AcademicScreens/SubjectsManagement/hooks/useAssignmentStore";
import { useGetClassesQuery } from "Network/academics/classAcademics";
import {
  useGetStaffQuery,
  useUpdateStaffMutation,
} from "Network/academics/staffAcademics";

const validationSchema = Yup.object().shape({
  classIds: Yup.array()
    .of(Yup.string())
    .min(1, "At least one class must be selected")
    .required("Classes are required"),
});

const useAssignClass = () => {
  const [selectedClasses, setSelectedClasses] = useState([]);

  const classState = useGetClassesQuery({});
  const staffState = useGetStaffQuery({});
  const [assignClass, assignClassState] = useUpdateStaffMutation();

  const { selectedStaff, setSelectedStaff, setOpenAssignClassModal } =
    useStaffActionStore();

  const { setOpenModal } = useAssignmentStore();

  const initialValues = {
    classIds: [],
  };

  const compareCurrentClassIdsToPayload = (currentData, payload) => {
    const updatedClassIds = [...currentData]; // Create a copy of current classIds

    // Process each item in the payload
    payload.forEach((payloadItem) => {
      const existClass = updatedClassIds.find(
        (classId) => classId === payloadItem,
      );

      if (!existClass) {
        updatedClassIds.push(payloadItem);
      }
    });

    return updatedClassIds;
  };

  const handleSubmit = (values, actions) => {
    const currentData = selectedStaff.classIds;
    const payload = values.classIds;

    const data = {
      ...selectedStaff,
      classIds: compareCurrentClassIdsToPayload(currentData, payload),
    };

    delete data.subjects;

    assignClass({ data, id: selectedStaff._id })
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Class assigned");
        setOpenAssignClassModal(false);
        setOpenModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    selectedClasses,
    setSelectedClasses,
    assignClassState,
    classState,
    staffState,
    setSelectedStaff,
  };
};

export default useAssignClass;
