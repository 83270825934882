import { toast } from "react-toastify";

import { useDeleteApplicationMutation } from "Network/admission/applications";

const useDeleteApplication = () => {
  const [deleteApplication, deleteApplicationsState] =
    useDeleteApplicationMutation();

  const handleDelete = ({ data, setOpen }) => {
    deleteApplication({ id: data._id })
      .unwrap()
      .then((_) => {
        toast.success("Application deleted");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    deleteApplicationsState,
    handleDelete,
  };
};

export default useDeleteApplication;
