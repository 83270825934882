import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import { useNavigate } from "react-router";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import Select from "Component/Forms/Select";
import Input from "Component/Forms/Input";
import UploadImage from "Screens/AdmissionScreen/Applicants/Manage/CreateApplicant/UploadImage";
import { genderList } from "Utils/constants";
import { academicFlattenRoutes } from "Routes/academicRoutes";
import useCreateStaff from "../../hooks/useCreateStaff";

const CreateStaff = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    selectedGender,
    setSelectedGender,

    handleSubmit,
    createStaffState,

    initialValues,
    validationSchema,
  } = useCreateStaff();

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Create new staff"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoIosEye}
            withIcon
            className="w-fit  text-[12px] h-[41px] "
            title="View Staff"
            // prettier-ignore
            handleSubmit={() =>
              navigate(`/${params.shortName}/${academicFlattenRoutes.staff}`)
            }
          />
        </div>
      </BodyHeaderLayout>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="bg-white">
            <UploadImage
              onFileChanged={(e) => {
                props.setFieldValue("image", e);
              }}
            />

            <div className="flex  flex-col gap-6 px-6 py-6 pt-0 ">
              <div className="grid grid-cols-3 sm:flex sm:flex-col sm:gap-3 gap-4">
                <Input
                  withTitle
                  title="First Name"
                  placeholder="Enter first name"
                  name="firstName"
                />
                <Input
                  withTitle
                  title="Middle Name"
                  placeholder="Enter first name"
                  name="middleName"
                />
                <Input
                  withTitle
                  title="Last Name"
                  placeholder="Enter first name"
                  name="lastName"
                />

                <Input
                  withTitle
                  title="Phone Number"
                  placeholder="Enter phone number"
                  name="phoneNumber"
                />

                <Input
                  withTitle
                  title="Email address"
                  placeholder="Enter email address"
                  name="email"
                />

                <Select
                  withLabel
                  label="Gender"
                  data={genderList}
                  value={selectedGender}
                  className={`${props.errors?.gender && "border-[red] "}`}
                  onChangeOption={(x) => {
                    props.setFieldValue("gender", x?.value);
                    setSelectedGender(x);
                  }}
                />

                <div className="flex flex-col gap-3 col-span-full">
                  <Input
                    withTitle
                    title="Address"
                    placeholder="Enter Address"
                    name="address"
                  />
                </div>
              </div>

              <div className="w-full flex justify-end">
                <Button
                  className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                  title="Create Staff"
                  handleSubmit={props.handleSubmit}
                  isLoading={createStaffState.isLoading}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </MainLayout>
  );
};

export default CreateStaff;
