import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "subjects";

const subjectAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubjects: builder.query({
      query: () => ({
        url: `/${endpoint}/organisation/${getSchoolId()}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_SUBJECTS,
                id,
              })),
              { type: tags.ACADEMICS_SUBJECTS, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_SUBJECTS, id: "LIST" }],
    }),

    getSubjectsByClassId: builder.query({
      query: ({ classId, termId, sessionId }) => {
        const url = `/${endpoint}/by-class`;
        const params = { classId };
        if (classId !== undefined && classId !== "") {
          params.classId = classId;
        }

        if (termId !== undefined && termId !== "") {
          params.termId = termId;
        }

        if (sessionId !== undefined && sessionId !== "") {
          params.sessionId = sessionId;
        }
        return { url, params };
      },

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_SUBJECTS,
                id,
              })),
              { type: tags.ACADEMICS_SUBJECTS, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_SUBJECTS, id: "LIST" }],
    }),

    createSubject: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_SUBJECTS, id: "LIST" }],
    }),

    deleteSubject: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_SUBJECTS, id: "LIST" }],
    }),

    updateSubject: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PUT",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_SUBJECTS, id: "LIST" }],
    }),
  }),
});

export const {
  useGetSubjectsQuery,
  useGetSubjectsByClassIdQuery,
  useCreateSubjectMutation,
  useDeleteSubjectMutation,
  useUpdateSubjectMutation,
} = subjectAcademics;
