import { NonTableRow, Title } from "Component/Table";

const Bio = ({ userData }) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-1">
        <Title text={"Personal Information"} />
        <NonTableRow
          title="Fullname"
          textClassName="capitalize"
          text={`${userData?.firstName} ${userData?.middleName || ""} ${userData?.lastName}`}
        />
        <NonTableRow
          title="Gender"
          text={userData?.gender}
          textClassName="capitalize"
        />
        <NonTableRow
          title="Address"
          text={userData?.address}
          textClassName="capitalize"
        />
        <NonTableRow
          title="Email"
          text={userData?.email}
          textClassName="lowercase"
        />
        <NonTableRow title="Phone" text={userData?.phoneNumber} />
      </div>

      {/* <div className="flex flex-col gap-1">
        <Title text={"Account Information"} />
        <NonTableRow title="Bank" text="Bank" />
        <NonTableRow title="Account Name" text="Mustapha Yusuf" />
        <NonTableRow title="Account Number" text="000000000" />
      </div> */}
    </div>
  );
};

export default Bio;
