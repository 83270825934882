/** eslint-disable react/jsx-props-no-spreading */

import * as Accordion from "@radix-ui/react-accordion";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import cn from "Utils/cn";

export const AccordionRoot = ({ children, className, ...props }) => (
  <Accordion.Root
    className={cn("w-full flex flex-col gap-5", className)}
    type="single"
    collapsible
    {...props}
  >
    {children}
  </Accordion.Root>
);

export const AccordionItem = ({ children, className, ...props }) => (
  <Accordion.Item className={cn("overflow-hidden", className)} {...props}>
    {children}
  </Accordion.Item>
);

export const AccordionTrigger = ({
  children,
  className,
  accordionTriggerTitle,
  arrowClassName,
  ...props
}) => (
  <Accordion.Header className="flex">
    <Accordion.Trigger
      className={cn(
        " group flex flex-col w-full min-h-[45px] cursor-pointer shadow-live-shadow flex-1 border-1 gap-3 bg-white px-5 text-[14px] pb-2",
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          "flex justify-between w-full items-center",
          arrowClassName,
        )}
      >
        {accordionTriggerTitle}
        <MdOutlineKeyboardArrowDown
          className="text-black text-[18px] sm:text-[16px] ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
          aria-hidden
        />
      </div>
      {children}
    </Accordion.Trigger>
  </Accordion.Header>
);

export const AccordionContent = ({ children, className, ...props }) => (
  <Accordion.Content
    className={cn(
      "data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[14px]",
      className,
    )}
    {...props}
  >
    <div className="py-[15px] px-5">{children}</div>
  </Accordion.Content>
);

AccordionRoot.displayName = "AccordionRoot";
AccordionItem.displayName = "AccordionItem";
AccordionTrigger.displayName = "AccordionTrigger";
AccordionContent.displayName = "AccordionContent";
