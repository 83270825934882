import { Formik, Form, FieldArray } from "formik";
import { IoIosAdd } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

import CustomModal from "Component/Forms/CustomModal";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useCreateGradeScale from "../hooks/useCreateGradeScale";

const CreateGradeScale = ({ open, setOpen }) => {
  const {
    initialValues,
    handleSubmit,
    validationSchema,
    createGradingScaleState,
  } = useCreateGradeScale({ setOpen });

  return (
    <CustomModal
      open={open}
      handleClose={() => !createGradingScaleState.isLoading && setOpen(false)}
      title="Create Grading Scale"
      className={"min-w-[50%] min-h-[50vh] max-h-[90vh] overflow-y-auto"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form className="space-y-6">
            <Input
              name="name"
              withTitle
              title="Format Name"
              placeholder="e.g. Standard School Grading"
            />

            <div className="border-t pt-4">
              <h3 className="text-md font-semibold mb-4">Grade Scales</h3>

              <FieldArray name="grades">
                {({ remove, push }) => (
                  <div>
                    {props.values.grades.length > 0 &&
                      props.values.grades.map((grade, index) => (
                        <div
                          key={index}
                          className="border p-4 rounded mb-4 bg-gray-50"
                        >
                          <div className="flex justify-between mb-2">
                            <h3 className="text-sm font-medium">
                              Grade {index + 1}
                            </h3>
                            {props.values.grades.length > 1 && (
                              <Button
                                className="self-end"
                                variant="icon"
                                handleSubmit={() => remove(index)}
                                Icon={
                                  <MdDeleteForever
                                    size={20}
                                    className="text-red-500"
                                  />
                                }
                              />
                            )}
                          </div>

                          <div className="grid grid-cols-4 sm:grid-cols-2 gap-4">
                            <Input
                              withTitle
                              title="Grade Type"
                              name={`grades.${index}.name`}
                              placeholder="e.g. A, B, C"
                            />

                            <Input
                              withTitle
                              title="Score Range From"
                              name={`grades.${index}.scoreFrom`}
                              placeholder="e.g. 90"
                              type="number"
                            />

                            <Input
                              withTitle
                              title="Score Range To"
                              name={`grades.${index}.scoreTo`}
                              placeholder="e.g. 100"
                              type="number"
                            />

                            <Input
                              withTitle
                              title="Remark"
                              name={`grades.${index}.remark`}
                              placeholder="e.g. Excellent"
                            />
                          </div>
                        </div>
                      ))}

                    <Button
                      variant="icon"
                      handleSubmit={() =>
                        push({
                          name: "",
                          scoreFrom: "",
                          scoreTo: "",
                          remark: "",
                        })
                      }
                      Icon={<IoIosAdd size={25} className="text-[#057877]" />}
                    />
                  </div>
                )}
              </FieldArray>
            </div>

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Save Grading Scale"
                handleSubmit={props.handleSubmit}
                isLoading={createGradingScaleState.isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateGradeScale;
