import { RxDashboard } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { LuUserPlus } from "react-icons/lu";
import { IoLibraryOutline } from "react-icons/io5";
import { FcCollaboration } from "react-icons/fc";
import { GiUpgrade } from "react-icons/gi";
import { MdOutlineAssessment, MdOutlineAssignmentInd } from "react-icons/md";

import { currentApp, PERMISSIONS_LISTS } from "Utils/constants";
import ProtectedRoute from "Component/Layout/ProtectedRoute";
import { Icon } from "Routes/utils/component";

import AcademicsHome from "Screens/AcademicScreens/Home";
import Students from "Screens/AcademicScreens/Students";
import CreateStudent from "Screens/AcademicScreens/Students/Manage/CreateStudent";
import StudentDetails from "Screens/AcademicScreens/Students/View/StudentDetails";
import Staff from "Screens/AcademicScreens/Staff";
import CreateStaff from "Screens/AcademicScreens/Staff/Manage/CreateStaff";
import StaffDetails from "Screens/AcademicScreens/Staff/View/StaffDetails";
import Subjects from "Screens/AcademicScreens/SubjectsManagement/Subjects";
import ClassSubjectAssignment from "Screens/AcademicScreens/SubjectsManagement/ClassSubjectAssignment";
import TeacherSubjectAssignment from "Screens/AcademicScreens/SubjectsManagement/TeacherSubjectAssignment";

import Classes from "Screens/AcademicScreens/ClassManagement/Classes";
import Section from "Screens/AcademicScreens/ClassManagement/Section";
import Category from "Screens/AcademicScreens/ClassManagement/Category";
import TeacherClassAssignment from "Screens/AcademicScreens/ClassManagement/TeacherClassAssignment";
import GradingScales from "Screens/AcademicScreens/GradeManagement/GradingScales";
import AssessmentComponents from "Screens/AcademicScreens/GradeManagement/AssessmentComponents";
import InputAssessment from "Screens/AcademicScreens/Assessment/InputAssessment";
import ClassReport from "Screens/AcademicScreens/Assessment/ClassReport";
import RoleManagement from "Screens/AcademicScreens/RoleManagement";

const route = currentApp.ACADEMICS;

const academicRoutesList = [
  {
    type: "collapse",
    name: "Dashboard",
    key: `dashboard`,
    route: `${route}/dashboard`,
    icon: <Icon Name={RxDashboard} />,
    noCollapse: true,
    sidenav: true,
    component: <AcademicsHome />,
  },

  {
    type: "collapse",
    name: "Students",
    key: `students`,
    route: `${route}/students`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    sidenav: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    component: (
      <ProtectedRoute
        element={<Students />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },

  {
    type: "collapse",
    name: "Create Students",
    key: `create-student`,
    route: `${route}/students/create-student`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    component: (
      <ProtectedRoute
        element={<CreateStudent />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },

  {
    type: "collapse",
    name: "Students",
    key: `student`,
    route: `${route}/students/:id`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    component: (
      <ProtectedRoute
        element={<StudentDetails />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },

  {
    type: "collapse",
    name: "Staff",
    key: `staff`,
    route: `${route}/staff`,
    icon: <Icon Name={LuUserPlus} />,
    noCollapse: true,
    sidenav: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    component: (
      <ProtectedRoute
        element={<Staff />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },

  {
    type: "collapse",
    name: "Roles",
    key: `roles`,
    route: `${route}/roles`,
    icon: <Icon Name={MdOutlineAssignmentInd} />,
    noCollapse: true,
    sidenav: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    component: (
      <ProtectedRoute
        element={<RoleManagement />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },

  {
    type: "collapse",
    name: "Create Staff",
    key: `create-staff`,
    route: `${route}/staff/create-staff`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    component: (
      <ProtectedRoute
        element={<CreateStaff />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },

  {
    type: "collapse",
    name: "Staff",
    key: `single-staff`,
    route: `${route}/staff/:staffId/:userId`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    component: (
      <ProtectedRoute
        element={<StaffDetails />}
        requiredPermissions={[
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ]}
      />
    ),
  },

  {
    type: "collapse",
    name: "Subjects Management",
    key: `subjects-management`,
    icon: <Icon Name={IoLibraryOutline} />,
    sidenav: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    collapse: [
      {
        name: "Subjects",
        key: `subjects`,
        route: `${route}/subjects-management/subjects`,
        component: (
          <ProtectedRoute
            element={<Subjects />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },
      {
        name: "Class Subject Assignment",
        key: `class-subject-assignment`,
        route: `${route}/subjects-management/class-subject-assignment`,
        component: (
          <ProtectedRoute
            element={<ClassSubjectAssignment />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },

      {
        name: "Teacher Subject Assignment",
        key: `teacher-subject-assignment`,
        route: `${route}/subjects-management/teacher-subject-assignment`,
        component: (
          <ProtectedRoute
            element={<TeacherSubjectAssignment />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },
    ],
  },

  {
    type: "collapse",
    name: "Class Management",
    key: `class-management`,
    icon: <Icon Name={FcCollaboration} />,
    sidenav: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    collapse: [
      {
        name: "Teacher Class Assignment",
        key: `teacher-class-assignment`,
        route: `${route}/class-management/teacher-class-assignment`,
        component: (
          <ProtectedRoute
            element={<TeacherClassAssignment />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },
      {
        name: "Class",
        key: `class`,
        route: `${route}/class-management/class`,
        component: (
          <ProtectedRoute
            element={<Classes />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },

      {
        name: "Sections",
        key: `sections`,
        route: `${route}/class-management/sections`,
        component: (
          <ProtectedRoute
            element={<Section />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },

      {
        name: "Category",
        key: `category`,
        route: `${route}/class-management/category`,
        component: (
          <ProtectedRoute
            element={<Category />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },
    ],
  },

  {
    type: "collapse",
    name: "Grade Management",
    key: `grade-management`,
    icon: <Icon Name={GiUpgrade} />,
    sidenav: true,
    permissions: [
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    collapse: [
      {
        name: "Grading Scales",
        key: `grading-scales`,
        route: `${route}/grade-management/grading-scales`,
        component: (
          <ProtectedRoute
            element={<GradingScales />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },
      {
        name: "Assessment Components",
        key: `assessment-components`,
        route: `${route}/grade-management/assessment-components`,
        component: (
          <ProtectedRoute
            element={<AssessmentComponents />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },
    ],
  },

  {
    type: "collapse",
    name: "Assessment",
    key: `assessment`,
    icon: <Icon Name={MdOutlineAssessment} />,
    sidenav: true,
    permissions: [
      PERMISSIONS_LISTS.SUBJECT_TEACHER,
      PERMISSIONS_LISTS.ADMIN,
      PERMISSIONS_LISTS.ORGANISATION_ADMIN,
    ],
    collapse: [
      {
        name: "Input Assessment",
        key: `input-assessment`,
        route: `${route}/assessment/input-assessment`,
        component: (
          <ProtectedRoute
            element={<InputAssessment />}
            requiredPermissions={[
              PERMISSIONS_LISTS.SUBJECT_TEACHER,
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },

      {
        name: "Print Class Report",
        key: `print-class-report`,
        route: `${route}/assessment/print-class-report`,
        permissions: [
          PERMISSIONS_LISTS.ADMIN,
          PERMISSIONS_LISTS.ORGANISATION_ADMIN,
        ],
        component: (
          <ProtectedRoute
            element={<ClassReport />}
            requiredPermissions={[
              PERMISSIONS_LISTS.ADMIN,
              PERMISSIONS_LISTS.ORGANISATION_ADMIN,
            ]}
          />
        ),
      },
    ],
  },
];

export default academicRoutesList;
