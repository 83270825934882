import * as Yup from "yup";
import { toast } from "react-toastify";

import useUploadFileHooks from "../../../../hooks/File/useUploadFileHooks";
import useCreateStaffStore from "./useCreateStaffStore";
import { useCreateStaffMutation } from "Network/academics/staffAcademics";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string(),
  phoneNumber: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  image: Yup.string(),
});

const useCreateStaff = () => {
  const {
    selectedGender,
    setSelectedGender,

    selectedQualification,
    setSelectedQualification,

    resetState,
  } = useCreateStaffStore();

  const { resetFile } = useUploadFileHooks();

  const [createStaff, createStaffState] = useCreateStaffMutation();

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: selectedGender?.value,
    address: "",
    phoneNumber: "",
    email: "",
    image: undefined,
  };

  const handleSubmit = (values, actions) => {
    const data = {
      user: values,
    };
    createStaff(data)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toast.success("Staff created");
        resetState();
        resetFile();
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    selectedGender,
    setSelectedGender,
    selectedQualification,
    setSelectedQualification,

    handleSubmit,
    createStaffState,

    initialValues,
    validationSchema,
  };
};

export default useCreateStaff;
