import { useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

import MainLayout from "Component/Layout/MainLayout";
import OneFieldUpdate from "Screens/AdmissionScreen/Settings/component/OneFieldUpdate";
import ConfirmDelete from "Component/ConfirmDelete";
import useModalStates from "../hooks/useModalStates";
import SimpleCard from "Component/Cards/SimpleCard";
import Button from "Component/Forms/Button";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import CreateCategory from "./CreateCategory";
import {
  useGetCategoriesQuery,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} from "Network/academics/categoryAcademics";

const Category = () => {
  const categoriesState = useGetCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [deleteCategory, deleteCategoryState] = useDeleteCategoryMutation();
  const [editCategory, editCategoryState] = useUpdateCategoryMutation();

  const {
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    openCreateModal,
    setOpenCreateModal,
  } = useModalStates();

  const handleDelete = () => {
    deleteCategory(selectedCategory._id)
      .unwrap()
      .then((_) => {
        toast.success("Category deleted successfully");
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenDeleteModal(false);
      });
  };

  const handleUpdate = (value) => {
    const data = {
      name: value,
    };
    editCategory({
      id: selectedCategory._id,
      data,
    })
      .unwrap()
      .then((_) => {
        toast.success("Category updated successfully");
        setOpenEditModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenEditModal(false);
      });
  };

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Categories"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Create Category"
            handleSubmit={() => setOpenCreateModal(true)}
          />
        </div>
      </BodyHeaderLayout>

      <div className="relative pb-10 mt-10">
        {!categoriesState.isFetching ? (
          <div
            className="grid grid-cols-4 sm:grid-cols-1 gap-5 max-h-[70vh] overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            {categoriesState?.data?.map((category, index) => (
              <SimpleCard
                title={category?.name}
                key={index}
                onClickDelete={() => {
                  setOpenDeleteModal(true);
                  setSelectedCategory(category);
                }}
                onClickEdit={() => {
                  setOpenEditModal(true);
                  setSelectedCategory(category);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="w-full flex justify-center">
            <CircularProgress />
          </div>
        )}
      </div>

      {openEditModal && (
        <OneFieldUpdate
          openUpdateModal={openEditModal}
          setOpenUpdateModal={setOpenEditModal}
          title="Category"
          label="Category Name"
          value={selectedCategory?.name}
          handleUpdate={(e) => handleUpdate(e)}
          isLoading={editCategoryState.isLoading}
        />
      )}

      {openDeleteModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteModal}
          setOpenConfirmDelete={setOpenDeleteModal}
          title={"Delete Category"}
          isLoading={deleteCategoryState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      {openCreateModal && (
        <CreateCategory open={openCreateModal} setOpen={setOpenCreateModal} />
      )}
    </MainLayout>
  );
};

export default Category;
