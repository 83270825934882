import { CircularProgress } from "@mui/material";

import CustomModal from "Component/Forms/CustomModal";
import { InputNoFormik } from "Component/Forms/Input";
import Select from "Component/Forms/Select";
import Button from "Component/Forms/Button";
import useBulkSelection from "../hooks/useBulkSelection";
import { transformSelectOption, transformClassOption } from "Utils/transform";

const BulkSelection = ({ open, setOpen }) => {
  const {
    sessions,
    selectedSession,
    setSelectedSession,

    applications,
    selectedApplication,
    setSelectedApplication,

    selectedClass,
    setSelectedClass,

    singleApplication,
    applicantsCountState,

    handleMoveApplicantsToAcademics,
    moveApplicantsToAcademicsState,
  } = useBulkSelection();

  return (
    <CustomModal
      open={open}
      handleClose={() =>
        !moveApplicantsToAcademicsState.isLoading && setOpen(false)
      }
      title="Bulk Selection"
      className="w-[35%] max-w-[70%] sm:w-[95%] sm:max-w-[95%] max-h-[90%] sm:max-h-[95%] overflow-y-auto"
    >
      <div className="mt-3 flex flex-col gap-5">
        <Select
          label="Academic Session"
          mainContainerStyle="text-[14px]"
          data={transformSelectOption(sessions?.data)}
          value={selectedSession}
          isLoading={sessions.isFetching}
          onChangeOption={(x) => {
            setSelectedSession(x);
          }}
        />

        <Select
          label="Application"
          mainContainerStyle="text-[14px]"
          data={
            applications?.data?.data?.map((x) => ({
              value: x._id,
              label: `${x.session?.name}: ${x.description}`,
            })) || []
          }
          value={selectedApplication}
          isLoading={applications.isFetching}
          onChangeOption={(x) => {
            setSelectedApplication(x);
          }}
        />

        <InputNoFormik
          withTitle
          title="Section"
          value={
            selectedApplication?.value ? singleApplication?.section?.name : ""
          }
          placeholder="Appliaction section will show here..."
          disabled
        />

        <Select
          withLabel
          label="Classes"
          selectMultiple
          data={transformClassOption(singleApplication?.classes)}
          value={selectedClass}
          onChangeOption={(x) => {
            setSelectedClass(x);
          }}
        />

        <div className="flex flex-col gap-3 items-center justify-center bg-[#eaf0f6] text-md h-[100px] px-2">
          <p className="text-slate-700">Total Selected Applicant</p>
          {!applicantsCountState.isLoading ? (
            <p className="font-semibold">{applicantsCountState?.data || 0}</p>
          ) : (
            <CircularProgress />
          )}
        </div>

        <div className="border-1 rounded-md p-2">
          <p className="text-slate-500 text-sm">Choose an action to perform</p>

          <div className="flex gap-4 justify-center mt-4">
            <Button
              title="Admit Students"
              className="px-6 py-2 sm:py-1 sm:px-1 text-sm sm:text-xs w-[30%] text-nowrap"
              disabled
            />
            <Button
              title="Move to Academics"
              className="px-6 py-2 sm:py-1 sm:px-1 text-sm sm:text-xs w-[30%] text-nowrap"
              isLoading={moveApplicantsToAcademicsState.isLoading}
              handleSubmit={handleMoveApplicantsToAcademics}
            />
            <Button
              title="Approve Payments"
              className="px-6 py-2 sm:py-1 sm:px-1 text-sm sm:text-xs w-[30%] text-nowrap"
              disabled
            />
          </div>
        </div>

        <Button
          handleSubmit={() => setOpen(false)}
          title="Cancel"
          className="w-fit text-[12px] py-2 px-4 font-medium"
          variant="outline"
        />
      </div>
    </CustomModal>
  );
};

export default BulkSelection;
