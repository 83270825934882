import { Formik } from "formik";

import CustomModal from "Component/Forms/CustomModal";
import Button from "Component/Forms/Button";
import usePermission from "./hooks/usePermission";
import Select from "Component/Forms/Select";
import { transformSelectOption } from "Utils/transform";
import useRoleStore from "./hooks/useRoleStore";

const AssignPermissionToRole = ({ open, setOpen }) => {
  const {
    initialValues,
    validationSchema,
    handleAssign,
    assignPermissionState,
    permissionState,
    selectedPermission,
    setSelectedPermission,
  } = usePermission();

  const { selectedRole } = useRoleStore();

  return (
    <CustomModal
      title={`Assign Permission to ${selectedRole?.name}`}
      open={open}
      handleClose={() => !assignPermissionState.isLoading && setOpen()}
      className="min-w-[30%]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleAssign}
      >
        {(props) => (
          <div className="flex flex-col gap-3 mt-5">
            <Select
              withLabel
              label="Permissions"
              data={transformSelectOption(permissionState?.data)}
              isLoading={permissionState.isLoading}
              value={selectedPermission}
              className={`${props.errors?.permission_id && "border-[red] "}`}
              onChangeOption={(x) => {
                props.setFieldValue("permission_id", x?.value);
                setSelectedPermission(x);
              }}
            />

            <div className="flex justify-between mt-3 gap-10">
              <Button
                variant="outline"
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                handleSubmit={() => setOpen(false)}
              />

              <Button
                className="w-[25%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Assign"
                handleSubmit={props.handleSubmit}
                isLoading={assignPermissionState.isLoading}
              />
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AssignPermissionToRole;
