import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import { useCreateGradingScaleMutation } from "Network/academics/gradeManagement/gradeScaleAcademics";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Format name is required"),
  grades: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Grade type is required"),
        scoreFrom: Yup.number()
          .required("Starting score is required")
          .min(0, "Score cannot be negative")
          .max(100, "Score cannot exceed 100"),
        scoreTo: Yup.number()
          .required("Ending score is required")
          .min(0, "Score cannot be negative")
          .max(100, "Score cannot exceed 100"),
        remark: Yup.string().required("Remark is required"),
      }),
    )
    .min(1, "At least one grade is required"),
});

const useCreateGradeScale = ({ setOpen }) => {
  const [createGradingScale, createGradingScaleState] =
    useCreateGradingScaleMutation();
  const initialValues = {
    name: "",
    grades: [
      {
        name: "",
        scoreFrom: "",
        scoreTo: "",
        remark: "",
      },
    ],
  };
  const handleSubmit = (values, actions) => {
    createGradingScale(values)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Grading scale created");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return {
    initialValues,
    handleSubmit,
    validationSchema,

    createGradingScaleState,
  };
};

export default useCreateGradeScale;
