import academicsApi from "./apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "students";

const studentAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getStudents: builder.query({
      query: ({ page, search, limit }) => {
        const url = `/${endpoint}/organisation/${getSchoolId()}`;

        const params = { limit };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        return { url, params };
      },

      providesTags: (result) =>
        result
          ? [
              ...result.students.map(({ id }) => ({
                type: tags.ACADEMICS_STUDENT,
                id,
              })),
              { type: tags.ACADEMICS_STUDENT, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_STUDENT, id: "LIST" }],
    }),

    getStudent: builder.query({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
      }),
      providesTags: (result, error, id) => [
        { type: tags.ACADEMICS_STUDENT, id },
      ],
    }),

    createStudent: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_STUDENT, id: "LIST" }],
    }),

    assignSubjectToStudent: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}/subjects`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.ACADEMICS_STUDENT, id: arg._id },
      ],
    }),

    deleteStudent: builder.mutation({
      query: (studentIds) => ({
        url: `/${endpoint}`,
        method: "DELETE",
        body: {
          studentIds,
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_STUDENT, id: "LIST" }],
    }),

    updateStudent: builder.mutation({
      query: ({ data, id }) => ({
        url: `/${endpoint}/${id}`,
        method: "PUT",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_STUDENT, id: "LIST" }],
    }),

    updateStudentStatus: builder.mutation({
      query: ({ studentIds, status }) => ({
        url: `/${endpoint}/status`,
        method: "PATCH",
        body: {
          studentIds,
          status,
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_STUDENT, id: "LIST" }],
    }),
  }),
});

export const {
  useGetStudentsQuery,
  useGetStudentQuery,
  useCreateStudentMutation,
  useAssignSubjectToStudentMutation,
  useDeleteStudentMutation,
  useUpdateStudentMutation,
  useUpdateStudentStatusMutation,
} = studentAcademics;
