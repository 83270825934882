import { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import {
  useGetAllPermissionsQuery,
  useGetAllRolePermissionsQuery,
  useAssignPermissionToRoleMutation,
  useDeletePermissionFromRoleMutation,
} from "Network/userManagement/userPermissions";
import useRoleStore from "./useRoleStore";

const validationSchema = Yup.object().shape({
  permission_id: Yup.string().required("Permission is required"),
});

const usePermission = () => {
  const [selectedPermission, setSelectedPermission] = useState({
    value: null,
    label: "Select Permission",
  });
  const {
    selectedRole,
    setOpenAssignPermissionModal,
    selectedRolePermission,
    setOpenDeleteRolePermissionModal,
  } = useRoleStore();

  const permissionState = useGetAllPermissionsQuery();
  const rolePermissionState = useGetAllRolePermissionsQuery();
  const [assignPermission, assignPermissionState] =
    useAssignPermissionToRoleMutation();

  const [deletePermission, deletePermissionState] =
    useDeletePermissionFromRoleMutation();

  const initialValues = {
    permission_id: "",
  };

  const handleAssign = (values, actions) => {
    const data = {
      role_id: selectedRole?._id,
      permission_id: values.permission_id,
    };
    assignPermission(data)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Permission assigned");
        setOpenAssignPermissionModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleDeletePermission = () => {
    const data = rolePermissionState?.data?.find(
      (item) =>
        item.role_id === selectedRole?._id &&
        item.permission_id === selectedRolePermission?._id,
    );
    deletePermission(data?._id)
      .unwrap()
      .then((_) => {
        toastify.success("Permission deleted");
        setOpenDeleteRolePermissionModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    initialValues,
    validationSchema,
    handleAssign,
    assignPermissionState,
    permissionState,
    selectedPermission,
    setSelectedPermission,

    deletePermissionState,
    handleDeletePermission,
  };
};

export default usePermission;
