import { camelCase } from "lodash";
import * as Yup from "yup";

export const applicantValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required"),
  dob: Yup.date().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string(),
  classId: Yup.string().required("Class ID is required"),
  applicationId: Yup.string().required("Application ID is required"),
  medicalInfo: Yup.string(),
  photo: Yup.string(),
  supportingDocuments: Yup.array(),
  parentOrGuardianInfo: Yup.object().shape({
    fullName: Yup.string().required("Full name of parent/guardian is required"),
    relationShip: Yup.string().required("Relationship is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  }),
});

export const transformCustomForm = ({ customForm }) => {
  return customForm?.map((form) => ({
    ...form,
    sectionKey: camelCase(form?.sectionName),
    fieldTypes: form?.fieldTypes?.map((field) => ({
      ...field,
      fieldKey: camelCase(field?.title),
      fieldName: `${camelCase(form?.sectionName)}.${camelCase(field?.title)}`,
    })),
  }));
};

/**
 * This function is used to get the custom form values
 * and merge it with the default form values
 * only if the application custom form value is not null and not empty
 *
 * Basically the function is used to reset the formik values of previous selected application
 *
 * */
export const getTransformedFormFields = ({
  formValues,
  initialValues,
  processedForm,
}) => {
  const matchingFields = Object.keys(initialValues).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (formValues.hasOwnProperty(key)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = formValues[key];
    }
    return result;
  }, {});

  const sectionsFields = processedForm?.reduce((acc, section) => {
    acc[section.sectionKey] = formValues[section.sectionKey] || null;
    return acc;
  }, {});

  return {
    ...matchingFields,
    formData:
      processedForm?.length > 0 ? JSON.stringify(sectionsFields) : undefined,
  };
};
