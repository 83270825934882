/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { useGetAddmissionTemplateQuery } from "Network/admission/configurations/template";

const useGetTemplate = () => {
  const tempQuery = useGetAddmissionTemplateQuery();
  const [selected, setSelected] = useState(Number(tempQuery?.data?.admission));

  useEffect(() => {
    setSelected(tempQuery?.data?.admission);
  }, []);

  const selectedTitle = () => {
    if (selected === Number(tempQuery?.data?.admission)) return "Active";
    return "Make Active";
  };

  const isActive = () => {
    if (selected === Number(tempQuery?.data?.admission)) return true;
    return false;
  };

  return { tempQuery, setSelected, selected, isActive, selectedTitle };
};

export default useGetTemplate;
