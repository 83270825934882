import { create } from "zustand";
import { motion } from "framer-motion";

import cn from "Utils/cn";
import { LiaTimesSolid } from "react-icons/lia";

export const ActionMenuWrapper = ({
  children,
  animate,
  selectedCount,
  handleCloseMenu,
}) => {
  const animationVariants = {
    hidden: { y: "100%", opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };
  return (
    <motion.div
      className={cn(
        "absolute border-1 rounded-md flex sm:flex-col-reverse sm:gap-3 justify-between p-2 bg-white w-[80%] sm:w-full left-0 right-0  bottom-5 sm:bottom-0 mx-auto shadow-2xl",
      )}
      variants={animationVariants}
      initial="hidden"
      animate={animate > 0 ? "visible" : "hidden"}
      exit="hidden"
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <p
        className="text-sm sm:text-xs sm:w-fit sm:self-end border-1 flex items-center cursor-pointer hover:font-medium hover:border-[black] px-2 py-1 sm:rounded-sm rounded-md gap-3"
        onClick={handleCloseMenu}
      >
        <span>{selectedCount} Selected</span> <LiaTimesSolid />
      </p>
      <div className="flex sm:flex-col gap-10 sm:gap-2 w-[70%] sm:w-full">
        {children}
      </div>
    </motion.div>
  );
};

// Generic store creator function for JavaScript
export function createSelectionStore(getItemId = (item) => item?._id) {
  return create((set) => ({
    // Core state
    selectedItems: [],
    selectAll: false,

    // Select/deselect individual item
    handleItemSelection: (check, item) =>
      set((state) => ({
        selectedItems: check
          ? [...state.selectedItems, getItemId(item)]
          : state.selectedItems?.filter((id) => id !== getItemId(item)),
      })),

    // Select/deselect all items
    handleSelectAll: (check, items) =>
      set(() => ({
        selectedItems: check ? items?.map(getItemId) || [] : [],
        selectAll: check,
      })),

    // Reset selection
    resetSelection: () => set({ selectedItems: [], selectAll: false }),

    // Utility method to get selected count
    getSelectedCount: () => {
      return this.selectedItems.length;
    },
  }));
}
