import academicsApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const endpoint = "grading-scales";

const gradeScaleAcademics = academicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getGradingScales: builder.query({
      query: () => ({
        url: `/${endpoint}/organisation/${getSchoolId()}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: tags.ACADEMICS_GRADE_SCALE,
                id,
              })),
              { type: tags.ACADEMICS_GRADE_SCALE, id: "LIST" },
            ]
          : [{ type: tags.ACADEMICS_GRADE_SCALE, id: "LIST" }],
    }),

    createGradingScale: builder.mutation({
      query: (data) => ({
        url: `/${endpoint}`,
        method: "POST",
        body: {
          ...data,
          organisationId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_GRADE_SCALE, id: "LIST" }],
    }),

    deleteGradingScale: builder.mutation({
      query: (id) => ({
        url: `/${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tags.ACADEMICS_GRADE_SCALE, id: "LIST" }],
    }),
  }),
});

export const {
  useGetGradingScalesQuery,
  useCreateGradingScaleMutation,
  useDeleteGradingScaleMutation,
} = gradeScaleAcademics;
