import { useState } from "react";
import { toast } from "react-toastify";

import {
  useGetSubjectsQuery,
  useDeleteSubjectMutation,
  useUpdateSubjectMutation,
} from "Network/academics/subjectAcademics";

const useSubject = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const subjectState = useGetSubjectsQuery();
  const [deleteSubject, deleteSubjectState] = useDeleteSubjectMutation();
  const [updateSubject, updateSubjectState] = useUpdateSubjectMutation();

  const handleDelete = () => {
    deleteSubject(selectedSubject._id)
      .unwrap()
      .then((_) => {
        toast.success("Subject deleted successfully");
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenDeleteModal(false);
      });
  };

  const handleUpdate = (value) => {
    const data = {
      name: value,
    };
    updateSubject({
      id: selectedSubject._id,
      data,
    })
      .unwrap()
      .then((_) => {
        toast.success("Subject updated successfully");
        setOpenEditModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenEditModal(false);
      });
  };
  return {
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    openCreateModal,
    setOpenCreateModal,

    subjectState,
    selectedSubject,
    setSelectedSubject,

    handleDelete,
    deleteSubjectState,

    handleUpdate,
    updateSubjectState,
  };
};

export default useSubject;
