import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
} from "Network/userManagement/userManagementRole";
import useRoleStore from "./useRoleStore";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Role name is required"),
  description: Yup.string().required("Description is required"),
});

const useCreateRole = () => {
  const {
    openCreateModal,
    setOpenCreateModal,
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    selectedRole,
    setSelectedRole,
    selectedRolePermission,
    setSelectedRolePermission,
    openDeleteRolePermissionModal,
    setOpenDeleteRolePermissionModal,
  } = useRoleStore();

  const [createRole, createRoleState] = useCreateRoleMutation();
  const [editRole, editRoleState] = useEditRoleMutation();
  const [deleteRole, deleteRoleState] = useDeleteRoleMutation();

  const initialValues = {
    name: "",
    description: "",
  };

  const handleSubmit = (values, actions) => {
    createRole(values)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Role created");
        setOpenCreateModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleUpdate = (value) => {
    editRole({
      id: selectedRole._id,
      name: value,
    })
      .unwrap()
      .then((_) => {
        toastify.success("Role updated successfully");
        setOpenEditModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenEditModal(false);
      });
  };

  const handleDelete = () => {
    deleteRole(selectedRole._id)
      .unwrap()
      .then((_) => {
        toastify.success("Role deleted successfully");
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenDeleteModal(false);
      });
  };
  return {
    initialValues,
    validationSchema,
    handleSubmit,
    createRoleState,

    openCreateModal,
    setOpenCreateModal,
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    selectedRole,
    setSelectedRole,

    selectedRolePermission,
    setSelectedRolePermission,

    openDeleteRolePermissionModal,
    setOpenDeleteRolePermissionModal,

    handleUpdate,
    editRoleState,
    handleDelete,
    deleteRoleState,
  };
};

export default useCreateRole;
