import { useEffect } from "react";
import { toast } from "react-toastify";
import { useEditSchoolProfileMutation } from "Network/userManagement/profile";

const useEditSchoolProfile = (setDisabled) => {
  const [handleEdit, editState] = useEditSchoolProfileMutation();

  useEffect(() => {
    if (editState.isSuccess) {
      toast.success("Profile Edited");
      setDisabled(true);
    }
    if (editState.error) {
      toast.error("Something went wrong");
    }
  }, [editState.isLoading]);

  return [handleEdit, editState];
};

export default useEditSchoolProfile;
