import { useState } from "react";
import { toast as toastify } from "react-toastify";
import toast from "react-hot-toast";

import CustomModal from "Component/Forms/CustomModal";
import Select from "Component/Forms/Select";
import Button from "Component/Forms/Button";
import { transformSelectOption } from "Utils/transform";

import { useGetSubjectsQuery } from "Network/academics/subjectAcademics";
import { useAssignSubjectToStudentMutation } from "Network/academics/studentAcademics";

const AssignSubjectToStudent = ({
  open,
  setOpen,
  assignedSubjects,
  studentId,
}) => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const subjectState = useGetSubjectsQuery();

  const [assignSubjectToStudent, assignSubjectToStudentState] =
    useAssignSubjectToStudentMutation();

  const getUnassignedSubjects = () => {
    const assignedSubjectIds = new Set(
      assignedSubjects.map((subject) => subject._id),
    );

    const unassignedSubjects = subjectState?.data?.filter(
      (subject) => !assignedSubjectIds.has(subject._id),
    );

    return unassignedSubjects;
  };

  const handleSubmit = () => {
    const data = {
      studentIds: [studentId],
      subjectIds: selectedSubjects.map((subject) => subject.value),
    };

    assignSubjectToStudent(data)
      .unwrap()
      .then((_) => {
        toastify.success("Subject assigned successfully");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return (
    <CustomModal
      open={open}
      handleClose={() =>
        !assignSubjectToStudentState.isLoading && setOpen(false)
      }
      className={"min-w-[30%]"}
      title="Assign Subject to Student"
    >
      <div className="flex flex-col gap-5 mt-5">
        <Select
          withLabel
          label="Subjects"
          data={transformSelectOption(getUnassignedSubjects())}
          selectMultiple
          value={selectedSubjects}
          isLoading={subjectState.isLoading}
          onChangeOption={(x) => {
            setSelectedSubjects(x);
          }}
        />

        <div className="flex justify-between mt-3 gap-10">
          <Button
            variant="outline"
            className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
            title="Cancel"
            handleSubmit={() => setOpen(false)}
          />

          <Button
            className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
            title="Assign"
            handleSubmit={handleSubmit}
            isLoading={assignSubjectToStudentState.isLoading}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default AssignSubjectToStudent;
