import parentApi from "./apiSlice";
import { tags } from "Utils/constants";

const application = parentApi.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationsByShortName: builder.query({
      query: ({ page, search, shortName }) => {
        const url = `/applications/schools/shortName/${shortName}`;

        const params = { published: true };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }
        return { url, params };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: tags.SCHOOL_APPLICATIONS,
                id,
              })),
              { type: tags.SCHOOL_APPLICATIONS, id: "LIST" },
            ]
          : [{ type: tags.SCHOOL_APPLICATIONS, id: "LIST" }],
    }),
  }),
});

export const { useGetApplicationsByShortNameQuery } = application;
