import { useState, useEffect } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import Select from "Component/Forms/Select";
import Button from "Component/Forms/Button";
import { transformSelectOption } from "Utils/transform";
import ConfirmBox from "Component/ConfirmBox";
import {
  useGetSessionsQuery,
  useSetCurrentTermAndSessionMutation,
  useGetCurrentSessionAndTermQuery,
} from "Network/academics/sessionAcademics";
import { useGetTermsQuery } from "Network/academics/term";

const SetCurrent = () => {
  const [selectedSession, setSelectedSession] = useState({
    label: "Select Session",
    value: null,
  });
  const [selectedTerm, setSelectedTerm] = useState({
    label: "Select Term",
    value: null,
  });
  const [openSessionConfirmBox, setOpenSessionConfirmBox] = useState(false);

  const sessionState = useGetSessionsQuery();
  const termState = useGetTermsQuery();

  const [setCurrentTermAndSession, setCurrentTermAndSessionState] =
    useSetCurrentTermAndSessionMutation();

  const currentSessionAndTermState = useGetCurrentSessionAndTermQuery();

  const handleSetCurrentTermAndSession = () => {
    setCurrentTermAndSession({
      currentSessionId: selectedSession.value,
      currentTermId: selectedTerm.value,
    })
      .unwrap()
      .then((_) => {
        toast.success("Session created successfully");
        setOpenSessionConfirmBox(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          const msg =
            typeof err.data?.message === "string"
              ? err.data?.message
              : err.data?.message[0];
          toast.error(msg);
        } else {
          toast.error("Something went wrong");
        }
        setOpenSessionConfirmBox(false);
      });
  };

  useEffect(() => {
    if (currentSessionAndTermState?.data) {
      setSelectedSession({
        label: currentSessionAndTermState?.data?.currentSession?.name,
        value: currentSessionAndTermState?.data?.currentSession?._id,
      });
      setSelectedTerm({
        label: currentSessionAndTermState?.data?.currentTerm?.name,
        value: currentSessionAndTermState?.data?.currentTerm?._id,
      });
    }
  }, [currentSessionAndTermState?.data]);

  return (
    <div className="text-xs flex flex-col gap-10">
      <div className="bg-amber-50 border-1 border-amber-200 rounded-md p-3">
        <div className="flex gap-3 items-center">
          <p>
            <IoWarningOutline className="text-3xl" color="orange" />
          </p>
          <div>
            <p className="text-amber-600 font-semibold">Important Note</p>
            <p className="text-amber-600">
              Changing the current academic session or term will affect the
              following:
              <p>1. Move all students to the new session or term</p>
              <p>2. Reset the academic state to new session or term</p>
              <p>3. Set new session or term as the current session or term</p>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <Select
          withLabel
          label="Select Session"
          placeholder="Select Session"
          data={transformSelectOption(sessionState?.data)}
          isLoading={
            sessionState.isLoading || currentSessionAndTermState.isLoading
          }
          value={selectedSession}
          onChangeOption={(x) => {
            setSelectedSession(x);
          }}
        />

        <Select
          withLabel
          label="Select Term"
          placeholder="Select Term"
          data={transformSelectOption(termState?.data)}
          isLoading={
            termState.isLoading || currentSessionAndTermState.isLoading
          }
          value={selectedTerm}
          onChangeOption={(x) => {
            setSelectedTerm(x);
          }}
        />

        <Button
          className="self-end h-[40px] w-full"
          title="Set as Current"
          handleSubmit={() => setOpenSessionConfirmBox(true)}
          disabled={!selectedSession.value || !selectedTerm.value}
        />
      </div>

      {openSessionConfirmBox && (
        <ConfirmBox
          title="Set Current Term and Session"
          openConfirmBox={openSessionConfirmBox}
          setOpenConfirmBox={setOpenSessionConfirmBox}
          handleConfirm={() => handleSetCurrentTermAndSession()}
          isLoading={setCurrentTermAndSessionState.isLoading}
        />
      )}
    </div>
  );
};

export default SetCurrent;
