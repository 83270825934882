import { useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import SimpleCard from "Component/Cards/SimpleCard";
import useModalStates from "../hooks/useModalStates";
import OneFieldUpdate from "Screens/AdmissionScreen/Settings/component/OneFieldUpdate";
import ConfirmDelete from "Component/ConfirmDelete";
import CreateSection from "./CreateSection";
import {
  useGetSectionsQuery,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
} from "Network/academics/sectionAcademics";

const Section = () => {
  const sectionState = useGetSectionsQuery();
  const [selectedSection, setSelectedSection] = useState(null);
  const [deleteSection, deleteSectionState] = useDeleteSectionMutation();
  const [updateSection, updateSectionState] = useUpdateSectionMutation();

  const {
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    openCreateModal,
    setOpenCreateModal,
  } = useModalStates();

  const handleDelete = () => {
    deleteSection(selectedSection._id)
      .unwrap()
      .then((_) => {
        toast.success("Section deleted successfully");
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenDeleteModal(false);
      });
  };

  const handleUpdate = (value) => {
    const data = {
      name: value,
    };
    updateSection({
      id: selectedSection._id,
      data,
    })
      .unwrap()
      .then((_) => {
        toast.success("Section updated successfully");
        setOpenEditModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
        setOpenEditModal(false);
      });
  };
  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Sections"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            Icon={IoAddCircleOutline}
            withIcon
            className="w-fit text-sm h-[41px]"
            title="Create Section"
            handleSubmit={() => setOpenCreateModal(true)}
          />
        </div>
      </BodyHeaderLayout>

      <div className="pb-10 mt-10">
        {!sectionState.isFetching ? (
          <div
            className="grid grid-cols-4 sm:grid-cols-1 gap-5 max-h-[70vh] overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            {sectionState.data.map((section, index) => (
              <SimpleCard
                title={section?.name}
                key={index}
                onClickDelete={() => {
                  setOpenDeleteModal(true);
                  setSelectedSection(section);
                }}
                onClickEdit={() => {
                  setOpenEditModal(true);
                  setSelectedSection(section);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        )}
      </div>

      {openEditModal && (
        <OneFieldUpdate
          openUpdateModal={openEditModal}
          setOpenUpdateModal={setOpenEditModal}
          title="Section"
          label="Section Name"
          value={selectedSection?.name}
          handleUpdate={(e) => handleUpdate(e)}
          isLoading={updateSectionState.isLoading}
        />
      )}

      {openDeleteModal && (
        <ConfirmDelete
          openConfirmDelete={openDeleteModal}
          setOpenConfirmDelete={setOpenDeleteModal}
          title="Section"
          isLoading={deleteSectionState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      {openCreateModal && (
        <CreateSection open={openCreateModal} setOpen={setOpenCreateModal} />
      )}
    </MainLayout>
  );
};

export default Section;
