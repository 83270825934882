import { create } from "zustand";

const usePermissionStore = create((set, get) => ({
  userPermissions: [],

  setUserPermissions: (permissions) => set({ userPermissions: permissions }),

  hasPermission: (permissionName) => {
    const { userPermissions } = get();
    return userPermissions.some(
      (permission) => permission.name === permissionName,
    );
  },

  hasAnyPermission: (permissionNames) => {
    const { userPermissions } = get();
    return permissionNames.some((name) =>
      userPermissions.some((permission) => permission.name === name),
    );
  },

  hasAllPermissions: (permissionNames) => {
    const { userPermissions } = get();
    return permissionNames.every((name) =>
      userPermissions.some((permission) => permission.name === name),
    );
  },
}));

export default usePermissionStore;
