import { useEffect } from "react";
import { useEditMyProfileMutation } from "Network/userManagement/profile";
import { toast } from "react-toastify";

const useEditProfile = (setDisabled) => {
  const [handleEdit, editState] = useEditMyProfileMutation();

  useEffect(() => {
    if (editState.isSuccess) {
      toast.success("Profile Edited");
      setDisabled(true);
    }
  }, [editState.isLoading]);

  return [handleEdit, editState];
};

export default useEditProfile;
