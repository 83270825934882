import * as Yup from "yup";

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  email: Yup.string().required("Email is required"),
  address: Yup.string().required("Address is required"),
  gender: Yup.string().required("Gender is required"),
});

export const profileInitialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  address: "",
  gender: "",
};

export const changePasswordInitialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
