import { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import { useAssignRoleToUserMutation } from "Network/userManagement/userRole";
import { useGetRolesQuery } from "Network/userManagement/userManagementRole";
import useStaffActionStore from "./useStaffActionStore";

const validationSchema = Yup.object().shape({
  roleId: Yup.string().required("Role is required"),
});

const useAssignRole = () => {
  const [selectedRole, setSelectedRole] = useState({
    value: null,
    label: "Select Role",
  });

  const rolesState = useGetRolesQuery({});
  const [assignRole, assignRoleState] = useAssignRoleToUserMutation();

  const { selectedStaff, setOpenAssignRoleModal } = useStaffActionStore();

  const initialValues = {
    roleId: "",
  };

  const handleSubmit = (values, actions) => {
    const data = {
      userId: selectedStaff?.userId,
      roleId: values.roleId,
    };
    assignRole(data)
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Role assigned");
        setOpenAssignRoleModal(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    selectedRole,
    setSelectedRole,
    assignRoleState,
    rolesState,
  };
};

export default useAssignRole;
