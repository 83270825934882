import admissionApi from "../apiSlice";
import { tags } from "Utils/constants";
import { getSchoolId } from "Network/utils";

const template = admissionApi.injectEndpoints({
  endpoints: (builder) => ({
    getAddmissionTemplate: builder.query({
      query: () => ({
        url: `/user/template/get-template/${getSchoolId()}`,
      }),
      providesTags: [tags.TEMPLATE_CONTROL_TAG],
    }),

    setTemplate: builder.mutation({
      query: (data) => ({
        url: "/user/template/set-template",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [tags.TEMPLATE_CONTROL_TAG],
    }),
  }),
});

export const { useGetAddmissionTemplateQuery, useSetTemplateMutation } =
  template;
