import { MdOutlineEdit, MdDelete } from "react-icons/md";

import AccordionDetailsCard from "Component/Cards/AccordionDetailsCard";
import Button from "Component/Forms/Button";
import useRoleStore from "./hooks/useRoleStore";

const RoleCard = ({ data }) => {
  const {
    setOpenDeleteModal,
    setOpenEditModal,
    setOpenDeleteRolePermissionModal,
    setSelectedRole,
    setSelectedRolePermission,
    setOpenAssignPermissionModal,
  } = useRoleStore();
  return (
    <AccordionDetailsCard
      title={data?.name}
      subTitle={
        <Button
          title="Add Permission"
          className="bg-[#B6EBAB] text-black"
          handleSubmit={(e) => {
            e.stopPropagation();
            setOpenAssignPermissionModal(true);
            setSelectedRole(data);
          }}
        />
      }
      countName={"Total Permissions"}
      count={data?.permissions?.length}
    >
      <div>
        {data?.permissions?.length > 0 ? (
          <div
            className=" flex flex-col gap-2 max-h-[300px] overflow-y-auto ml-2"
            style={{ scrollbarWidth: "thin" }}
          >
            {data?.permissions?.map((permission, index) => (
              <div key={index} className="flex justify-between items-center ">
                <p>{permission?.name}</p>

                <Button
                  Icon={<MdDelete />}
                  variant="icon"
                  className="text-red-500"
                  handleSubmit={() => {
                    setOpenDeleteRolePermissionModal(true);
                    setSelectedRolePermission(permission);
                    setSelectedRole(data);
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-xs">No Permissions</div>
        )}

        <div className="flex gap-2 w-full justify-end border-t-1 mt-3">
          <Button
            Icon={<MdOutlineEdit />}
            variant="icon"
            handleSubmit={() => {
              setOpenEditModal(true);
              setSelectedRole(data);
            }}
          />
          <Button
            Icon={<MdDelete />}
            variant="icon"
            className="text-red-500"
            handleSubmit={() => {
              setOpenDeleteModal(true);
              setSelectedRole(data);
            }}
          />
        </div>
      </div>
    </AccordionDetailsCard>
  );
};

export default RoleCard;
